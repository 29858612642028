import React, { useRef, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentEvent } from '../../selectors/event'
import { SvgIcon, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import HostCard from '../Page/HostCard'
import { openSignInModal } from '../../actions/modals'
import VerifiedIcon from '../../svg/CheckDecagram'
import EventSection from './EventSection'
import BottomSheet from '../common/BottomSheet'
import { useRouter } from 'next/router'
import { useIsLoggedIn } from 'utils/hooks'
import { follow, followOrganisationId, unfollow } from 'actions/user'
import { setSnackbar } from 'shared-components/redux/notifications/actions'
import BashButton, {
  BashButtonType,
} from '../../shared-components/buttons/BashButton'

const useStyles = makeStyles()((theme) => ({
  viewAll: {
    marginTop: '-6px',
  },
  hostsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'auto',
    gap: theme.spacing(1),
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    msOverflowX: 'hidden',
    scrollbarWidth: 'none',
  },
  hostItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
    '&:last-child': {
      marginBottom: theme.spacing(0),
    },
  },
  hostImage: {
    width: 40,
    height: 40,
    borderRadius: '100px',
  },
  hostName: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    marginLeft: 'auto',
    // backgroundColor: theme.palette.primary[100],
    // color: theme.palette.primary.main,
  },
  verifiedIcon: {
    marginLeft: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  modalHostsContainer: {},
}))

const HostsCard = ({ desktopMode }) => {
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const event = useSelector(getCurrentEvent)
  const { t } = useTranslation('common')
  const scrollRef = useRef(null)
  const theme = useTheme()
  const [allOpen, setAllOpen] = useState(false)
  const router = useRouter()
  const isLoggedIn = useIsLoggedIn()
  const [localFollowStatus, setLocalFollowStatus] = useState([])

  const onFollowClick = (host) => {
    if (isLoggedIn) {
      if (localFollowStatus.includes(host.model?.id)) {
        dispatch(unfollow(host.model?.id))
        setLocalFollowStatus(
          localFollowStatus.filter((id) => id !== host.model?.id),
        )
      } else {
        dispatch(followOrganisationId(host.model?.id))
        dispatch(
          setSnackbar(
            'success',
            t('shared:followingOrg', { org: host.model?.name }),
          ),
        )
        setLocalFollowStatus([...localFollowStatus, host.model?.id])
      }
    } else {
      router.replace({
        query: { ...router.query, redirectTo: `/${host.model?.username}` },
      })
      dispatch(openSignInModal(null, host.model))
    }
  }

  const onHostClicked = (host) => {
    if (host.type === 'ORGANISATION') {
      router.push(
        host.model?.username
          ? `/${host.model.username}`
          : `/pages/${host.model?.id}`,
      )
    } else {
      router.push(`/users/${host.model?.id}`)
    }
  }

  return (
    <>
      <EventSection
        title={t('shared:hostedBy')}
        onMoreClicked={() => setAllOpen(true)}
        desktopMode={desktopMode}
      >
        <div className={classes.hostsContainer} ref={scrollRef}>
          {event.hosts?.map((host) => (
            <HostCard
              key={host.id}
              host={host}
              onFollowClick={onFollowClick}
              following={localFollowStatus.includes(host.model?.id)}
              hasMultiple={event.hosts.length > 1}
              noFollow={true}
            />
          ))}
        </div>
      </EventSection>
      <BottomSheet
        alignStart
        title={t('shared:hostedBy')}
        open={allOpen}
        onClose={() => setAllOpen(false)}
      >
        <div className={classes.modalHostsContainer}>
          {event.hosts?.map((host) => (
            <div
              key={host.id}
              className={classes.hostItem}
              onClick={() => onHostClicked(host)}
            >
              <img
                className={classes.hostImage}
                src={
                  host.model?.avatarUrls?.sm ??
                  host.model?.user?.avatarUrls?.sm ??
                  host.model?.avatarUrls?.lg ??
                  host.model?.user?.avatarUrls?.lg
                }
                alt='page picture'
              />
              <Typography className={classes.hostName} variant='subtitle2'>
                {host.model?.name ?? host.model?.user?.name}
                {host.model?.verified && (
                  <SvgIcon
                    component={VerifiedIcon}
                    className={classes.verifiedIcon}
                    color='primary'
                  />
                )}
              </Typography>

              {host.type === 'ORGANISATION' && (
                <BashButton
                  className={classes.button}
                  type={BashButtonType.PRIMARY_LIGHT}
                  onClick={(e) => {
                    e.stopPropagation()
                    onFollowClick(host)
                  }}
                  height={32}
                >
                  {localFollowStatus.includes(host.model?.id) ||
                  host.model?.followStatus === 'ACTIVE'
                    ? t('following')
                    : t('follow')}
                </BashButton>
              )}
            </div>
          ))}
        </div>
      </BottomSheet>
    </>
  )
}

export default HostsCard
