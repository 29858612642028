import React, { useEffect } from 'react'
import HomeHeader from '../src/components/Index/HomeHeader'
import { wrapper } from '@redux/store'
import GeneralFooter from '../src/components/event/GeneralFooter'
import Head from 'next/head'
import Column from '../src/components/Column'
import IndexTopSection from '../src/components/Index/IndexTopSection'
import IndexHowItWorks from '../src/components/Index/IndexHowItWorks'
import IndexFAQ from '../src/components/Index/IndexFAQ'
import IndexPages from '../src/components/Index/IndexPages'

export const getStaticProps = wrapper.getStaticProps(
  // @ts-expect-error TODO
  async ({ store, locale }) => {
    const response = await fetch(
      'https://api.bash.social/organisations/featured-v2?size=20',
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
      },
    )
    const results = await response.json()

    return {
      props: {
        pages: results,
      },
      revalidate: 86400,
    }
  },
)

const Index = ({ pages }) => {
  useEffect(() => {
    if (typeof window !== 'undefined' && window.localStorage) {
      window.localStorage.setItem('hasSeenBash', 'true')
    }
  }, [])

  return (
    <Column
      sx={{
        alignItems: 'center',
        background:
          'radial-gradient(278.11% 144.33% at 0% 102.81%, rgba(255, 250, 238, 0.60) 7.38%, rgba(228, 234, 255, 0.60) 33%, rgba(242, 230, 251, 0.60) 65%, rgba(253, 237, 225, 0.60) 100%), var(--Core-white, #FFF)',
      }}
    >
      <Head>
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href={'/apple-touch-icon.png'}
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href={'/favicon-32x32.png'}
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href={'/favicon-16x16.png'}
        />
        <link rel='manifest' href='/site.webmanifest' />
        <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#fafafa' />
        <link rel='shortcut icon' href={'/favicon.ico'} />
      </Head>
      <Column
        sx={{
          width: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <HomeHeader
          isHome={true}
          sx={{
            paddingLeft: '24px !important',
            paddingRight: '24px !important',
            maxWidth: 1120,
          }}
        />

        <IndexTopSection
          sx={{
            maxWidth: 1120,
            px: 3,
          }}
        />
        <IndexHowItWorks />

        <IndexFAQ
          sx={{
            maxWidth: 1120,
            px: 3,
          }}
        />
      </Column>
      <IndexPages pages={pages} />
      <GeneralFooter maxWidth={'1120px'} />
    </Column>
  )
}

// Var used to disable some stuff applied to all pages through _app.tsx
Index.trimApp = true

export default Index
