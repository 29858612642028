import { createSelector } from 'reselect'
import { getGuestName } from 'shared-components/utils/eventDisplay'
import { initialEvent } from '@redux/eventHelpers'

export const getCurrentEvent = (state) =>
  state.event.events[state.event.currentEventCode] || initialEvent
export const getCurrentUser = (state) => state.user.user
export const getCurrentQuestionId = (state) => state.rsvp.currentQuestionId
export const getCurrentJoinOption = (state) => state.rsvp.newGuest.joinOption

export const getGuests = (state) => getCurrentEvent(state).guests || []
export const getCurrentId = (state) => getCurrentEvent(state).id
export const getCurrentImages = (state) => getCurrentEvent(state).images
export const getCurrentShowImageIndex = (state) =>
  getCurrentEvent(state).showImageIndex
export const getShowImages = (state) => getCurrentEvent(state).showImages
export const getCurrentImagesLoading = (state) =>
  getCurrentEvent(state).imagesLoading
export const getLimitNumberOfGuests = (state) =>
  getCurrentEvent(state).limitNumberOfGuests
const getExpectedNumberOfGuests = (state) =>
  getCurrentEvent(state).expectedNumberOfGuests
export const getOrganisation = (state) => getCurrentEvent(state).organisation
const getHosts = (state) => getCurrentEvent(state).hosts
const getFirstOrganiser = (state) =>
  getCurrentEvent(state).hosts && getCurrentEvent(state).hosts[0]?.model
const getCurrentGuestId = (state) => state.event.currentGuestId
export const getCurrentEventType = (state) => getCurrentEvent(state).type
export const getCurrentEventPrivacyType = (state) =>
  getCurrentEvent(state).privacyType
export const getIsExpired = (state) => getCurrentEvent(state).expired
export const getCurrentEventStartDate = (state) =>
  getCurrentEvent(state).startDate
export const getCurrentEventEndDate = (state) => getCurrentEvent(state).endDate
export const getCurrentEventIsTBA = (state) =>
  getCurrentEvent(state).dateToBeAnnounced
export const getCurrentEventGuestsCanInvite = (state) =>
  getCurrentEvent(state).guestsCanInvite
export const getExpired = (state) => getCurrentEvent(state).expired
export const getTicketOptions = (state) => getCurrentEvent(state).ticketOptions
export const getTicketLink = (state) => getCurrentEvent(state).ticketLink
export const getTicketDisplaylink = (state) =>
  getCurrentEvent(state).ticketDisplayLink
export const getMyGuest = (state) => getCurrentEvent(state).myGuest
export const getMyGuestGaveDateOptions = (state) =>
  getMyGuest(state)?.gaveDateOptions
const getFriends = (state) => state.user.friends.friends
const getAmountGoing = (state) =>
  getCurrentEvent(state).statusCount && getCurrentEvent(state).statusCount.going
export const getCurrentEventLink = (state) =>
  getCurrentEvent(state).creationType === 'EXTERNAL'
    ? (getCurrentEvent(state).externalTicketInfo?.url ??
      getCurrentEvent(state).sourceUrl)
    : `${process.env.NEXT_PUBLIC_WEBSITE}/e/${getCurrentEvent(state).code}${getCurrentUserId(state) != null ? `?u=${getCurrentUserId(state)}` : '?u=0'}`

const getCurrentUserId = (state) => state.user.user?.id

export const getNewGuest = (state) => state.rsvp.newGuest

export const getIsSuperAdmin = createSelector([getCurrentUser], (user) =>
  user.admin?.authorities?.includes('SUPER_ADMIN'),
)

export const getIsEventsAdmin = createSelector([getCurrentUser], (user) =>
  user.admin?.authorities?.includes('EVENTS_ADMIN'),
)

export const getIsEventOwner = createSelector(
  [getCurrentUser, getCurrentEvent],
  (user, event) => {
    const eventOwner = event.hosts?.find((h) => h.role === 'OWNER')
    if (!eventOwner) return !event.id || event.organisation?.manager === true
    return (
      !event.id ||
      (eventOwner?.type === 'GUEST' &&
        eventOwner?.model?.user?.id === user.id) ||
      (eventOwner?.type === 'USER' && eventOwner?.model?.id === user.id) ||
      (eventOwner?.type === 'ORGANISATION' &&
        eventOwner?.model?.manager === true)
    )
  },
)

export const isCurrentEventPublic = createSelector(
  [getCurrentEvent],
  (event) => event.privacyType === 'PUBLIC',
)

export const isCurrentEventIdea = createSelector(
  [getCurrentEvent],
  (event) => event.type === 'IDEA',
)

export const isCurrentEventPinning = createSelector(
  [getCurrentEvent],
  (event) => event.type === 'PINNING',
)

export const isCurrentEventInvite = createSelector(
  [getCurrentEvent],
  (event) => event.type === 'INVITE',
)

export const isCurrentEventMaxReached = createSelector(
  [getCurrentEvent],
  (event) =>
    event.expectedNumberOfGuests &&
    event.statusCounts.going >= event.expectedNumberOfGuests &&
    event.limitNumberOfGuests,
)

export const isHostedByPage = createSelector(
  [getOrganisation, getHosts],
  (org, hosts) =>
    Boolean(org) ||
    (hosts && hosts.some((host) => host.type === 'ORGANISATION')),
)

export const hostedByPageWithEvent = createSelector(
  (event) => event,
  (event) =>
    Boolean(event.organisation) ||
    (event.hosts && event.hosts.some((host) => host.type === 'ORGANISATION')),
)

export const hasTicketing = createSelector(
  [getTicketOptions, getTicketLink],
  // (ticketOptions, getTicketLink) => (ticketOptions && ticketOptions.length > 0) || getTicketLink != null
  (ticketOptions, getTicketLink) => ticketOptions && ticketOptions.length > 0,
)

// hasTicketing with no check for ticketLink
// export const hasTicketing = createSelector(
//   [getTicketOptions],
//   (ticketOptions) => (ticketOptions && ticketOptions.length > 0)
// )

export const getOrganiser = createSelector(
  [getOrganisation, getFirstOrganiser],
  (organisation, firstOrganiser) => organisation || firstOrganiser,
)

export const getOrganiserName = createSelector(
  [getOrganiser],
  (organiser) => organiser?.name || organiser?.user?.name || '',
)

export const getTwoGuestUsersNames = createSelector([getGuests], (guests) =>
  guests
    .filter((guest) => !!guest.user?.id)
    .slice(0, 2)
    .map((guest) => guest.user.firstName || guest.user.name),
)

export const getSocialProofUsers = createSelector(
  [getGuests, getHosts],
  (guests, hosts) => {
    if (guests.length < 1 && hosts) {
      return hosts
        .filter((host) => host.type === 'GUEST')
        .slice(0, 3)
        .map((host) => host.model?.user)
    }
    return guests
      .filter((guest) => !!guest.user?.id)
      .slice(0, 3)
      .map((guest) => guest.user)
  },
)

export const amountGaveDateOptions = createSelector(
  getGuests,
  (guests) => guests.filter((g) => g.gaveDateOptions || g.organiser).length,
)

export const isGuestLimitReached = createSelector(
  [
    getGuests,
    getLimitNumberOfGuests,
    getExpectedNumberOfGuests,
    getAmountGoing,
  ],
  (guests, limitNumberOfGuests, expectedNumberOfGuests, amountGoing) => {
    if (!guests || !limitNumberOfGuests) return false
    return amountGoing >= expectedNumberOfGuests
  },
)

export const spotsLeft = createSelector(
  [
    getGuests,
    getLimitNumberOfGuests,
    getExpectedNumberOfGuests,
    getAmountGoing,
  ],
  (guests, limitNumberOfGuests, expectedNumberOfGuests, amountGoing) => {
    if (!guests || !limitNumberOfGuests) return 0
    return expectedNumberOfGuests - amountGoing
  },
)

export const getCurrentGuest = createSelector(
  [getGuests, getCurrentGuestId],
  (guests, currentGuestId) => {
    if (guests == null || currentGuestId == null) return undefined
    const foundGuest = guests.find((g) => g?.id === currentGuestId)
    if (!foundGuest) return undefined
    return foundGuest.user ? undefined : foundGuest
  },
)

export const getActiveGuest = createSelector(
  [getMyGuest, getCurrentGuest, getNewGuest],
  (myGuest, currentGuest, newGuest) => {
    return myGuest || currentGuest || newGuest
  },
)

export const getActiveGuestWithoutNew = createSelector(
  [getMyGuest, getCurrentGuest],
  (myGuest, currentGuest) => {
    return myGuest || currentGuest
  },
)

export const getActiveGuestCode = createSelector(
  [getActiveGuest],
  (activeGuest) => activeGuest && activeGuest.code,
)

export const getActiveResponse = createSelector(
  [getActiveGuest, getCurrentEvent],
  (activeGuest, event) =>
    event.type === 'PINNING' && activeGuest.gaveDateOptions
      ? 'RESPONDED'
      : activeGuest.state === 'REQUESTED'
        ? 'REQUESTED'
        : activeGuest.status,
)

export const getHasRespondedEvent = (event) => {
  const guest = event.myGuests
  const eventType = event.type
  return (
    guest?.status === 'GOING' ||
    guest?.status === 'JOINED' ||
    guest?.status === 'MAYBE' ||
    guest?.status === 'CANT' ||
    guest?.waitingList === true ||
    (eventType === 'PINNING' && guest?.gaveDateOptions === true)
  )
}

export const getHasResponded = createSelector(
  [getActiveGuestWithoutNew, getCurrentEventType],
  (guest, eventType) =>
    guest?.status === 'GOING' ||
    guest?.status === 'JOINED' ||
    guest?.status === 'MAYBE' ||
    guest?.status === 'CANT' ||
    guest?.waitingList === true ||
    (eventType === 'PINNING' && guest?.gaveDateOptions === true),
)
export const getHasRespondedNoWaitingList = createSelector(
  [getActiveGuestWithoutNew, getCurrentEventType],
  (guest, eventType) =>
    guest?.status === 'GOING' ||
    guest?.status === 'JOINED' ||
    guest?.status === 'MAYBE' ||
    guest?.status === 'CANT' ||
    (eventType === 'PINNING' && guest?.gaveDateOptions === true),
)

export const getActiveGuestName = createSelector([getActiveGuest], (guest) =>
  getGuestName(guest),
)

export const getUserHasTicket = createSelector(
  [getMyGuest],
  (myGuest) => myGuest && myGuest.tickets && myGuest.tickets.length > 0,
)

export const getAttendingUserIds = createSelector([getGuests], (guests) => {
  const attendingUserIds = {}
  guests &&
    guests.forEach((g) => {
      if (g.user) attendingUserIds[g.user.id] = true
    })
  return attendingUserIds
})

export const getSelectedFriendsForEvent = createSelector(
  [getAttendingUserIds, getFriends],
  (attendingUserIds, friends) =>
    friends
      .filter((friend) => !attendingUserIds[friend.ofUser.id])
      .map((f) => f.ofUser),
)

export const getIsOrganiser = createSelector(
  [getHosts, getCurrentEvent, getCurrentUserId],
  (hosts, event, userId) =>
    event.host ||
    (Array.isArray(hosts) &&
      hosts.some(
        (o) => o.model?.user?.id != null && o.model?.user?.id === userId,
      )) ||
    event.organisation?.manager,
)

export const getHostType = createSelector(
  [getIsOrganiser, getActiveGuest],
  (isOrganiser, activeGuest) =>
    isOrganiser ? (activeGuest.role === 'OWNER' ? 'HOST' : 'COHOST') : null,
)

export const getActiveGuestGaveDateOptions = createSelector(
  [getActiveGuest],
  (activeGuest) => activeGuest.gaveDateOptions,
)

export const getUserState = createSelector(
  [
    getHostType,
    getActiveResponse,
    getActiveGuestGaveDateOptions,
    getCurrentEvent,
  ],
  (hostType, activeResponse, gaveDate, event) =>
    hostType ||
    (event.type === 'PINNING'
      ? gaveDate
        ? 'RESPONDED'
        : 'NONE'
      : activeResponse || 'NONE'),
)

export const getNumberOfGuests = createSelector([getCurrentEvent], (event) =>
  event.statusCounts
    ? Object.values(event.statusCounts).reduce((a, b) => a + b)
    : 0,
)

export const getCurrentQuestion = createSelector(
  [getCurrentEvent, getCurrentQuestionId],
  (event, questionId) =>
    event.questions?.find((question) => question.id === questionId),
)

export const getShouldRespondToQuestions = createSelector(
  [getCurrentJoinOption, getActiveGuest, getNewGuest],
  (ticketOption, activeGuest, newGuest) => {
    if (
      ticketOption?.ticketOption?.questions == null ||
      ticketOption?.ticketOption?.questions.length < 1
    )
      return false
    if (newGuest?.status === 'MAYBE' && newGuest?.status === 'CANT') {
      return false
    }
    const questionsCompleted =
      (activeGuest?.questionsCompleted ?? false) ||
      (newGuest?.questionsCompleted ?? false)
    return !questionsCompleted
  },
)

export const getHasPickedBashOrWidget = createSelector(
  [getActiveGuest, getNewGuest],
  (activeGuest, newGuest) => {
    return activeGuest?.pickedBashOrWidget || newGuest?.pickedBashOrWidget
  },
)
