import i18n from 'config/i18n'

export const getAmountNotifiedText = (event, t = null) => {
  const totalGuests =
    event.type === 'INVITE' || event.type === 'PUBLIC'
      ? event.statusCounts?.going +
        event.statusCounts.maybe +
        event.statusCounts.cant +
        event.statusCounts.invited -
        1
      : event.statusCounts.gaveAvailability + event.statusCounts.invited - 1
  const nonReachableCount = event.nonReachableCount || 0

  return (
    totalGuests -
    nonReachableCount +
    '/' +
    totalGuests +
    ' ' +
    (t
      ? t('common:guestsHaveBeenNotified')
      : i18n.t('common:guestsHaveBeenNotified'))
  )
}

const truncate = (str, n) => {
  return str.length > n ? str.slice(0, n - 1) + '...' : str
}

export const isValidHttpUrl = (string) => {
  let url

  try {
    url = new URL(string)
  } catch (_) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const getSummary = (message, t) => {
  const rsvpMessage = [
    'NEW_RSVP_GOING',
    'NEW_RSVP_MAYBE',
    'NEW_RSVP_CANT',
    'NEW_RSVP_DATE_OPTIONS',
  ].includes(message.content?.model?.type)
  const rsvpMessageWithText = ['NEW_RSVP_MESSAGE'].includes(
    message.content?.model?.type,
  )
  if (message.content?.type === 'TEXT') {
    return ': ”' + truncate(message.content.model.text ?? '', 25) + '”'
  } else if (message.content?.type === 'IMAGES') {
    return ': ' + '📷'
  } else if (message.content?.model?.type === 'NEW_RSVP_MESSAGE') {
    return (
      ': ”' + truncate(message.content?.model?.guest?.message ?? '', 25) + '”'
    )
  } else if (
    message.content?.type === 'EVENT_LOG' &&
    !(rsvpMessage || rsvpMessageWithText)
  ) {
    const type = message.content.model.type
    return ': ' + t(`eventLogUpdate.${type}`)
  } else if (rsvpMessage) {
    const type = message.content.model.type
    return ': ' + t(`eventLogUpdate.${type}`)
  } else {
    return ''
  }
}

export const getGoingCount = (event) => {
  if (event.type === 'PINNING') {
    return event.statusCounts?.gaveAvailability ?? 0
  } else {
    const going = event.statusCounts?.going ?? 0
    const present = event.presentUserIds?.count ?? 0
    const me = event.id === null ? 1 : 0
    return going + present + me
  }
}

export const getRsvpdCount = (event) => {
  if (event.type === 'PINNING') {
    return event.statusCounts?.gaveAvailability ?? 0 + event.extraAttendees
  } else {
    const going = event.statusCounts?.going ?? 0
    const maybe = event.statusCounts?.maybe ?? 0
    const cant = event.statusCounts?.cant ?? 0
    const present = event.presentUserIds?.count ?? 0
    const me = event.id === null ? 1 : 0
    return going + maybe + cant + present + me
  }
}

export const getRespondedCount = (event) => {
  if (event.type === 'PINNING') {
    return event.statusCounts?.gaveAvailability ?? 0 + event.extraAttendees
  } else {
    const going = event.statusCounts?.going ?? 0
    const maybe = event.statusCounts?.maybe ?? 0
    const cant = event.statusCounts?.cant ?? 0
    const present = event.presentUserIds?.count ?? 0
    const me = event.id === null ? 1 : 0
    return going + maybe + cant + present + me
  }
}

export const getFirstName = (user) => {
  return user?.firstName ?? user?.name ?? ''
}

var pow = Math.pow
var floor = Math.floor
var abs = Math.abs
var log = Math.log
var abbrev = 'KMB' // could be an array of strings: [' m', ' Mo', ' Md']

function round(n, precision) {
  var prec = Math.pow(10, precision)
  return Math.round(n * prec) / prec
}

export function formatShortNumber(n) {
  var base = floor(log(abs(n)) / log(1000))
  var suffix = abbrev[Math.min(2, base - 1)]
  base = abbrev.indexOf(suffix) + 1
  return suffix ? round(n / pow(1000, base), 2) + suffix : '' + n
}

export function formatShortNumberFixed(n) {
  var base = floor(log(abs(n)) / log(1000))
  var suffix = abbrev[Math.min(2, base - 1)]
  base = abbrev.indexOf(suffix) + 1
  if (!suffix) return '' + n

  const scaledNum = n / pow(1000, base)
  const decimals = scaledNum < 10 ? 2 : 1
  return scaledNum.toFixed(decimals) + suffix
}

export const getOgImageTagUrl = (
  event,
  usePortrait,
  user,
  asHost = true,
  inPreview = true,
) => {
  let base = `${process.env.NEXT_PUBLIC_WEBSITE}/api/eventOgImage?`
  if (event.code) base += `eventCode=${event.code}&`
  if (event.id) base += `eventId=${event.id}&`
  if (user && user.id && (!asHost || event.privacyType === 'PRIVATE'))
    base += `u=${user.id}&`
  base += `portrait=${usePortrait}&colourSchemeId=${event.theme.colourScheme?.id}&lowRes=${inPreview}`
  return base
}
