import { useMediaQuery, useTheme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import FullScreenSheet from '../FullScreenSheet'
import { useDispatch, useSelector } from 'react-redux'
import {
  closeModal,
  openModalOnPage,
  setModalPage,
} from '../../../actions/modals'
import { useTranslation } from 'react-i18next'
import GuestManagementMenu from './GuestManagementMenu'
import GuestManagementList from './GuestManagementList'
import GuestManagementRequested from './GuestManagementRequested'
import GuestManagementInvited from './GuestManagementInvited'
import GuestManagementWaitlist from './GuestManagementWaitlist'
import GuestManagementJoinOptions from './GuestManagementJoinOptions'
import { useEffect, useState } from 'react'
import GuestManagementActions from './GuestManagementActions'
import GuestManagementHype from './GuestManagementHype'
import GuestManagementSearch from './GuestManagementSearch'
import apiClient from '../../../shared-components/utils/ApiClient'
import { getCurrentEvent } from '../../../selectors/event'
import { editEvent } from '../../../actions/event'
import GuestManagementRevenue from './GuestManagementRevenue'

export const guestManagementBreakpoint = 730

const useStyles = makeStyles()((theme) => ({
  modal: {
    maxWidth: 940,
    height: '100%',
    maxHeight: 'min(730px, 90%)',
    borderRadius: 16,
    [theme.breakpoints.down(guestManagementBreakpoint)]: {
      borderRadius: 0,
      maxHeight: 'none',
    },
  },
  root: {
    flexGrow: 1,
    display: 'flex',
    overflow: 'hidden',
  },
  page: {
    flexGrow: 1,
    minWidth: 0,
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      minHeight: 0,
    },
  },
  menu: {
    borderRight: `1px solid ${theme.palette.grey[200]}`,
    flexShrink: 0,
    [theme.breakpoints.down(guestManagementBreakpoint)]: {
      borderRight: 'none',
      width: '100%',
    },
  },
}))

const GuestManagementModal = ({}) => {
  const { classes } = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(
    theme.breakpoints.down(guestManagementBreakpoint),
  )
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const open = useSelector((state) => state.modals.open.guestManagement)
  const reduxPage = useSelector((state) => state.modals.page.guestManagement)
  const event = useSelector(getCurrentEvent)
  const [query, setQuery] = useState('')
  const [sortType, setSortType] = useState('NAME')
  const page = reduxPage

  const onClose =
    page === 'menu' || !isMobile
      ? () => dispatch(closeModal('guestManagement'))
      : undefined
  const onBack =
    page !== 'menu' && isMobile
      ? () => dispatch(setModalPage('guestManagement', 'menu'))
      : undefined

  useEffect(() => {
    if (open && isMobile) {
      dispatch(openModalOnPage('guestManagement', 'menu'))
    }
  }, [open])

  const loadStatusCounts = async () => {
    const statusCounts = await apiClient.event.getStatusCounts(event.id)
    if (statusCounts) {
      dispatch(
        editEvent({
          ...event,
          statusCountsHost: statusCounts,
        }),
      )
    }
  }

  useEffect(() => {
    if (open) {
      loadStatusCounts()
    }
  }, [open])

  const getTitle = () => {
    if (!isMobile || page === 'menu') return t('guestManagement.title')
    if (page === 'search') return null
    return t(`guestManagement.${page}`)
  }

  return (
    <FullScreenSheet
      open={open}
      hideTitle={page === 'joinOptions' && isMobile}
      onClose={onClose}
      onBack={onBack}
      title={getTitle()}
      className={classes.modal}
      rightContent={
        <GuestManagementActions
          query={query}
          setQuery={setQuery}
          sortType={sortType}
          setSortType={setSortType}
        />
      }
    >
      <div className={classes.root}>
        {(page === 'menu' || !isMobile) && (
          <div className={classes.menu}>
            <GuestManagementMenu />
          </div>
        )}
        {page !== 'menu' && (
          <div className={classes.page}>
            {page === 'search' && (
              <GuestManagementSearch query={query} sortType={sortType} />
            )}
            {page === 'guestList' && (
              <GuestManagementList sortType={sortType} />
            )}
            {page === 'approvalRequested' && (
              <GuestManagementRequested sortType={sortType} />
            )}
            {page === 'invitePending' && (
              <GuestManagementInvited sortType={sortType} />
            )}
            {page === 'preRegister' && (
              <GuestManagementWaitlist sortType={sortType} />
            )}
            {page === 'hype' && <GuestManagementHype sortType={sortType} />}
            {page === 'joinOptions' && <GuestManagementJoinOptions />}
            {page === 'revenue' && <GuestManagementRevenue />}
          </div>
        )}
      </div>
    </FullScreenSheet>
  )
}

export default GuestManagementModal
