import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Dialog from '@mui/material/Dialog'
import { useMediaQuery, useTheme } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { ChevronLeft, Close } from '@mui/icons-material'
import Typography from '@mui/material/Typography'
import { SlideTransition } from '../common/BottomSheet'

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 0, 3),
    width: '100%',
    maxWidth: theme.breakpoints.values.visual,
    overflow: 'hidden',
  },
  subtitle: {
    marginTop: theme.spacing(1),
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    textAlign: 'left',
  },
  divider: {
    height: '1px',
    background: theme.palette.grey.main,
    margin: theme.spacing(1, -4, 0, -4),
  },
  scrollContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: theme.spacing(3),
    overflowY: 'auto',
  },
  alignStart: {
    justifyContent: 'flex-start',
  },
  modalHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalCloseButton: {
    marginTop: -18,
    marginLeft: theme.spacing(-2),
    width: 60,
    height: 60,
  },
  invisibleButton: {
    marginTop: -18,
    marginRight: theme.spacing(-2),
    height: 60,
    width: 60,
  },
}))

const LargeModal = ({
  title,
  subtitle,
  children,
  open,
  onClose,
  onBack,
  hideIcon,
  nonFullScreenClassName,
  scrollContainerClassName,
  startElement,
  endElement,
  alignStart,
}) => {
  const { classes, cx } = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Dialog
      classes={{
        paper: cx(classes.root, !fullScreen && nonFullScreenClassName),
      }}
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      TransitionComponent={SlideTransition}
    >
      <div className={classes.modalHeaderContainer}>
        {!hideIcon &&
          (onBack ? (
            <IconButton
              className={classes.modalCloseButton}
              edge='start'
              color='inherit'
              onClick={onBack}
              aria-label='close'
              size='large'
            >
              <ChevronLeft />
            </IconButton>
          ) : (
            <IconButton
              className={classes.modalCloseButton}
              edge='start'
              color='inherit'
              onClick={onClose}
              aria-label='close'
              size='large'
            >
              <Close />
            </IconButton>
          ))}
        <div className={classes.title}>
          <Typography variant='h6'>{title}</Typography>
          {subtitle && (
            <Typography
              className={classes.subtitle}
              color='textSecondary'
              variant='body1'
            >
              {subtitle}
            </Typography>
          )}
        </div>
        {!hideIcon && <div className={classes.invisibleButton} />}
      </div>
      {!hideIcon && <div className={classes.divider} />}
      {startElement}
      {children && (
        <div
          className={cx(
            classes.scrollContainer,
            alignStart && classes.alignStart,
            scrollContainerClassName,
          )}
        >
          {children}
        </div>
      )}
      {endElement}
    </Dialog>
  )
}

export default LargeModal
