import { TypographyOptions } from '@mui/material/styles/createTypography'

export const headerFontFamily =
  '"Helvetica Neue", "Fira Sans", "Helvetica", sans-serif'
export const gintoHeaderFontFamily =
  '"GintoBlack", "Montserrat", "Helvetica Neue", "Fira Sans", "Helvetica", sans-serif'
export const fontFamily =
  '-apple-system, "BlinkMacSystemFont", "Roboto", "Arial", sans-serif'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    h7: React.CSSProperties
    h7Black: React.CSSProperties
    body1Medium: React.CSSProperties
    body1High: React.CSSProperties
    body2Medium: React.CSSProperties
    body2High: React.CSSProperties
    captionMedium: React.CSSProperties
    captionBold: React.CSSProperties
    captionSmall: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h7: React.CSSProperties
    h7Black: React.CSSProperties
    body1Medium: React.CSSProperties
    body1High: React.CSSProperties
    body2Medium?: React.CSSProperties
    body2High: React.CSSProperties
    captionMedium: React.CSSProperties
    captionBold: React.CSSProperties
    captionSmall: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h7: true
    h7Black: true
    body1Medium: true
    body1High: true
    body2Medium: true
    body2High: true
    captionMedium: true
    captionBold: true
    captionSmall: true
  }
}

export const typography: TypographyOptions = {
  h2: {
    fontSize: '3rem',
    lineHeight: '2.7rem',
    fontWeight: 900,
    letterSpacing: '-0.06rem',
  },
  h3: {
    fontSize: '2rem',
    lineHeight: '1.8rem',
    fontWeight: 900,
    letterSpacing: '-0.04rem',
  },
  h4: {
    fontSize: '1.5rem',
    lineHeight: '1.35rem',
    fontWeight: 900,
    letterSpacing: '0.003rem',
  },
  h5: {
    // fontFamily: headerFontFamily,
    fontSize: '1.5rem',
    lineHeight: '1.5rem',
    fontWeight: 700,
  },
  h6: {
    // fontFamily: headerFontFamily,
    fontSize: '1.25rem',
    fontWeight: 700,
    lineHeight: '23.87px',
  },
  h7: {
    fontSize: '1rem',
    fontWeight: 700,
    lineHeight: '1.1875rem',
  },
  h7Black: {
    fontSize: '1rem',
    fontWeight: 900,
    lineHeight: '1.1875rem',
  },
  subtitle1: {
    // fontFamily: headerFontFamily,
    fontSize: '1rem',
    lineHeight: '1rem',
    fontWeight: 700,
  },
  subtitle2: {
    // fontFamily: headerFontFamily,
    fontSize: '0.875rem',
    lineHeight: '0.875rem',
    fontWeight: 600,
  },
  body1: {
    fontSize: '1rem',
    lineHeight: '1.195rem',
    whiteSpace: 'pre-wrap',
  },
  body1Medium: {
    fontSize: '1rem',
    lineHeight: '1.195rem',
    whiteSpace: 'pre-wrap',
    fontWeight: 500,
  },
  body1High: {
    fontSize: '1rem',
    whiteSpace: 'pre-wrap',
    lineHeight: '1.5rem',
  },
  body2: {
    fontSize: '0.875rem',
    lineHeight: '16.71px',
    letterSpacing: '0.016rem',
    whiteSpace: 'pre-wrap',
  },
  body2Medium: {
    fontSize: '0.875rem',
    lineHeight: '0.875rem',
    fontWeight: 500,
    whiteSpace: 'pre-wrap',
  },
  body2High: {
    fontSize: '0.875rem',
    lineHeight: '1.325rem',
    // fontWeight: 500,
    whiteSpace: 'pre-wrap',
  },
  caption: {
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
    letterSpacing: '0.0125rem',
    whiteSpace: 'pre-wrap',
  },
  captionMedium: {
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
    letterSpacing: '0.0125rem',
    whiteSpace: 'pre-wrap',
    fontWeight: 500,
  },
  captionBold: {
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
    letterSpacing: '0.0125rem',
    whiteSpace: 'pre-wrap',
    fontWeight: 700,
  },
  captionSmall: {
    fontSize: '0.625rem',
    lineHeight: '0.75rem',
    whiteSpace: 'pre-wrap',
  },
  button: {
    textTransform: 'none',
    fontWeight: 600,
    letterSpacing: '0.0125rem',
    fontSize: '0.875rem',
    lineHeight: '0.875rem',
  },
  overline: {
    letterSpacing: '0.094rem',
    fontSize: '0.625rem',
    lineHeight: '0.625rem',
    textTransform: 'uppercase',
    fontWeight: 500,
  },
  fontFamily: fontFamily,
}