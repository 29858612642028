import React from 'react'
import Typography from '@mui/material/Typography'
import { List, ListItem, Tooltip } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { ErrorOutline } from '@mui/icons-material'
import { makeStyles } from 'tss-react/mui'

export const useCreationDialogStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
  },
  list: {
    boxSizing: 'border-box',
    background: theme.palette.background.secondary,
    borderRadius: '8px',
    // borderTop: `1px solid ${theme.palette.divider}`,
    // borderBottom: `1px solid ${theme.palette.divider}`,
    padding: '0px',
  },
  gappedList: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    // padding: 0,
  },
  disabledList: {
    marginTop: theme.spacing(2),
    borderColor: '#E6E6E6',
  },
  listItem: {
    boxSizing: 'border-box',
    cursor: 'pointer',
    background: theme.palette.background.secondary,
    flexShrink: 0,
    borderRadius: '8px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    // alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    padding: theme.spacing(2, 2),
    // borderLeft: `1px solid ${theme.palette.divider}`,
    // borderRight: `1px solid ${theme.palette.divider}`,
    // borderBottom: `1px solid ${theme.palette.divider}`,
    border: `1px solid ${theme.palette.divider}`,
    '&:first-child:not(:last-child)': {
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px',
    },
    '&:last-of-type:not(:first-of-type)': {
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
    },
    '&:not(:last-of-type):not(:first-of-type)': {
      borderRadius: '0px',
      borderTop: 'none',
    },
    '&:not(:first-of-type)': {
      borderTop: 'none',
    },
    '&:last-of-type': {
      // borderBottom: 'none',
    },
  },
  listItemSmallPadding: {
    padding: theme.spacing(1.5, 2),
  },
  listItemText: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    marginRight: 'auto',
    overflow: 'hidden',
    flexGrow: 1,
  },
  disabledListItem: {
    backgroundColor: 'unset',
    color: '#A6A6A6',
    borderColor: '#E6E6E6',
  },
  listText: {
    fontWeight: 400,
    size: '1rem',
    lineHeight: '19.09px',
    flexGrow: 1,
    flexShrink: 0,
    fontSize: '1rem',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    wordWrap: 'break-word',
    alignItems: 'center',
    // display: 'flex',
    display: 'inline-block',
    gap: theme.spacing(1),
  },
  gappedListItem: {
    cursor: 'pointer',
    background: theme.palette.background.secondary,
    gap: theme.spacing(1),
    flexShrink: 0,
    borderRadius: '8px',
    // height: '56px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // padding: theme.spacing(1, 2),
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
  },
  saveContainer: {
    paddingTop: theme.spacing(3),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      borderTop: '1px solid ' + theme.palette.divider,
    },
  },
  saveButton: {
    borderRadius: '12px !important',
    color: 'white',
    '&.Mui-disabled': {
      color: `${theme.palette.text.secondary}50`,
    },
    // width: '100%',
    // height: 48
  },
  titleContainer: {
    padding: theme.spacing(1, 2),
    borderBottom: '1px solid ' + theme.palette.divider,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  registrationTitleContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: '1px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
  },
  closeButton: {
    minHeight: 0,
    minWidth: 0,
    width: 40,
    height: 40,
    borderRadius: 100,
    marginRight: '-8px',
  },
  placeholder: {
    color: theme.palette.text.secondary,
  },
  mainTextFullSize: {
    flexGrow: 1,
    flexShrink: 0,
  },
  subText: {
    lineHeight: '1.3125rem',
    color: theme.palette.text.tertiary,
  },
  preSelectedListItem: {
    backgroundColor: `${theme.palette.mode === 'dark' ? 'black' : theme.palette.grey[150]} !important`,
    color: theme.palette.text.primary,
    cursor: 'not-allowed',
  },
}))

export const CreationList = ({ gapped, children, className }) => {
  const { classes: dialogClasses, cx } = useCreationDialogStyles()

  return (
    <List
      className={cx(
        gapped ? dialogClasses.gappedList : dialogClasses.list,
        className,
      )}
      component='nav'
    >
      {children}
    </List>
  )
}

export const CreationListItem = ({
  disabled,
  gapped,
  title,
  icon,
  showChevron = true,
  rightContent,
  onClick,
  className,
  placeholder,
  extraText,
  limitExtraText = false,
  stopRightContentPropagation = true,
  subText = null,
  showError = false,
  tooltipTitle = '',
  preSelected = false,
  ...props
}) => {
  const { classes: dialogClasses, cx } = useCreationDialogStyles()

  return (
    <Tooltip title={tooltipTitle}>
      <ListItem
        {...props}
        disabled={disabled}
        button={onClick != null && !disabled}
        className={cx(
          gapped ? dialogClasses.gappedListItem : dialogClasses.listItem,
          className,
          preSelected && dialogClasses.preSelectedListItem,
        )}
        style={{
          ...props?.style,
          alignItems: icon != null && subText != null ? 'flex-start' : 'center',
        }}
        onClick={disabled ? undefined : onClick}
      >
        {icon}

        <div className={dialogClasses.listItemText}>
          <Typography
            variant='subtitle1'
            noWrap
            className={cx(
              dialogClasses.listText,
              !title && placeholder && dialogClasses.placeholder,
              limitExtraText && dialogClasses.mainTextFullSize,
            )}
          >
            {title || placeholder}
          </Typography>
          {subText && (
            <Typography
              color='textSecondary'
              // noWrap
              variant='body2'
              className={dialogClasses.subText}
            >
              {subText}
            </Typography>
          )}
        </div>

        {/*{subText && (*/}
        {/*  <div className={dialogClasses.listItemText}>*/}
        {/*    <Typography*/}
        {/*      variant='subtitle1'*/}
        {/*      noWrap*/}
        {/*      className={cn(*/}
        {/*        dialogClasses.listText,*/}
        {/*        !title && placeholder && dialogClasses.placeholder,*/}
        {/*        limitExtraText && dialogClasses.mainTextFullSize,*/}
        {/*      )}*/}
        {/*    >*/}
        {/*      {title || placeholder}*/}
        {/*    </Typography>*/}
        {/*    <Typography*/}
        {/*      color='textSecondary'*/}
        {/*      // noWrap*/}
        {/*      variant='body2'*/}
        {/*      className={dialogClasses.subText}*/}
        {/*    >*/}
        {/*      {subText}*/}
        {/*    </Typography>*/}
        {/*  </div>*/}
        {/*)}*/}
        {/*{!subText && (*/}
        {/*  <Typography*/}
        {/*    variant='subtitle1'*/}
        {/*    noWrap*/}
        {/*    className={cn(*/}
        {/*      dialogClasses.listText,*/}
        {/*      !title && placeholder && dialogClasses.placeholder,*/}
        {/*      limitExtraText && dialogClasses.mainTextFullSize,*/}
        {/*    )}*/}
        {/*  >*/}
        {/*    {title || placeholder}*/}
        {/*  </Typography>*/}
        {/*)}*/}

        {showError && (
          <ErrorOutline color='error' style={{ marginRight: -8 }} />
        )}

        {extraText && (
          <Typography color='textSecondary' noWrap variant='body1'>
            {extraText}
          </Typography>
        )}
        {!rightContent && showChevron && <ChevronRightIcon />}
        {rightContent && (
          <div
            onClick={
              stopRightContentPropagation
                ? (e) => {
                    e.stopPropagation()
                    e.preventDefault()
                  }
                : undefined
            }
            onMouseDown={
              stopRightContentPropagation
                ? (e) => e.stopPropagation()
                : undefined
            }
          >
            {rightContent}
          </div>
        )}
      </ListItem>
    </Tooltip>
  )
}

export default CreationList
