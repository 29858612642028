import Column from '../Column'
import { Typography } from '@mui/material'
import { gintoHeaderFontFamily } from '../../shared-components/typography'
import React, { useEffect, useState } from 'react'
import HowItWorksStep from './Steps/HowItWorksStep'
import BashButton, {
  BashButtonType,
} from '../../shared-components/buttons/BashButton'
import Link from 'next/link'
import { indexMobileBreakpoint } from '../../types/types'
import useKeyPress from '../../utils/useKeyPress'
import { useTranslation } from 'react-i18next'

const IndexHowItWorks = ({}) => {
  const { t } = useTranslation('common')
  const [activeStep, setActiveStep] = useState(0)
  const rightArrowDown = useKeyPress('ArrowRight')
  const leftArrowDown = useKeyPress('ArrowLeft')

  const onClickRight =
    activeStep > 3
      ? undefined
      : () => {
          setActiveStep((prev) => prev + 1)
        }

  const onClickLeft =
    activeStep === 0
      ? undefined
      : () => {
          setActiveStep((prev) => prev - 1)
        }

  useEffect(() => {
    if (rightArrowDown && onClickRight) {
      onClickRight()
    } else if (leftArrowDown && onClickLeft) {
      onClickLeft()
    }
  }, [rightArrowDown, leftArrowDown])

  return (
    <Column
      sx={(theme) => ({
        width: 1,
        overflowX: 'hidden',
        background: '#FFFFFF80',
        alignItems: 'center',
        pt: 9.25,
        pb: 7,
        px: 3,
        [theme.breakpoints.down(indexMobileBreakpoint)]: {
          pt: 8,
          pb: 6,
        },
      })}
    >
      <Column
        sx={[
          (theme) => ({
            alignItems: 'center',
            gap: 0,
            position: 'relative',
            width: 1,
            maxWidth: 1120,
            [theme.breakpoints.down(indexMobileBreakpoint)]: {
              alignItems: 'flex-start',
            },
          }),
        ]}
      >
        <Typography
          sx={{
            fontFamily: gintoHeaderFontFamily,
            fontSize: '2rem',
            fontWeight: 800,
            lineHeight: '1.8rem',
            letterSpacing: '-0.02em',
          }}
          variant='h3'
        >
          {t('howItWorks').toUpperCase()}
        </Typography>

        <HowItWorksStep
          activeStep={activeStep}
          onClickLeft={onClickLeft}
          onClickRight={onClickRight}
        />

        <Link href='/create' passHref prefetch={false}>
          <BashButton
            type={BashButtonType.SECONDARY}
            onClick={undefined}
            height={48}
            sx={{
              px: 4.75,
            }}
          >
            {t('index.createFirstEvent')}
          </BashButton>
        </Link>
      </Column>
    </Column>
  )
}

export default IndexHowItWorks
