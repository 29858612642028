import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Typography from '@mui/material/Typography'
import BashButton, {
  BashButtonType,
  ButtonViewAlignment,
} from '../../shared-components/buttons/BashButton'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentEventLink } from '../../selectors/event'
import { useTranslation } from 'react-i18next'
import useMixpanelTrackEvent from '../../utils/useMixpanelTrackEvent'
import { setSnackbar } from '../../shared-components/redux/notifications/actions'
import { ContentCopy } from '@mui/icons-material'
import Row from '../Row'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  copyText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: theme.spacing(0, 1),
    lineHeight: '40px',
    height: 40,
    background: theme.palette.grey[150],
    borderRadius: 8,
  },
  copyTextContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    width: '100%',
  },
  divider: {
    width: '100%',
    height: 1,
    background: theme.palette.grey[200],
  },
  previewTitle: {
    fontWeight: 500,
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
  },
}))

const EmbedEventButton = () => {
  const { classes } = useStyles()
  const { t } = useTranslation('common')
  const dispatch = useDispatch()

  const trackEvent = useMixpanelTrackEvent()
  const eventUrl = useSelector(getCurrentEventLink)
  const onTrack = () => trackEvent('Copy Embed Html Event')

  const onCopy = (e) => {
    // e.target.select()
    if (navigator.clipboard) {
      navigator.clipboard.writeText(embedHtml)
      dispatch(setSnackbar('info', t('copiedHtmlToClipboard')))
    }
    onTrack()
  }

  const imageUrl =
    'https://storage.googleapis.com/bash-email-assets/join-event-on-BASH.png'
  const width = 166

  const embedHtml = `<a href="${eventUrl}" target="_blank"><img src="${imageUrl}" width="${width}" height="56" alt='BASH' /></a>`
  return (
    <div className={classes.root}>
      <div className={classes.topContainer}>
        <Typography
          style={{
            marginLeft: 8,
          }}
          variant='body2'
        >
          {t('copyAndPasteHtmlAnywhere')}
        </Typography>

        <Row
          sx={{
            gap: 1,
          }}
          onClick={onCopy}
        >
          <Typography
            variant='body2'
            sx={(theme) => ({
              px: 1,
              py: 1.5,
              bgcolor: 'grey.100',
              border: `1px solid ${theme.palette.divider}`,
              color: 'grey.800',
              borderRadius: 1,
              flexGrow: 1,
            })}
            noWrap
          >
            {embedHtml}
          </Typography>
          <BashButton
            onClick={onCopy}
            extraView={<ContentCopy />}
            viewAlignment={ButtonViewAlignment.ALIGN_TEXT_START}
            type={BashButtonType.PRIMARY}
            height={40}
          >
            {t('copy')}
          </BashButton>
        </Row>
      </div>

      <div className={classes.divider} />

      <Typography variant='body2' className={classes.previewTitle}>
        {t('embedModal.preview')}
      </Typography>

      <img src={imageUrl} width={width} height={56} />
    </div>
  )
}

export default EmbedEventButton
