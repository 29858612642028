import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Typography } from '@mui/material'
import { AddAPhotoOutlined } from '@mui/icons-material'

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
  },
  fileInput: {
    display: 'none',
  },
  noPointerEvents: {
    pointerEvents: 'none',
  },
  overlay: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(63, 106, 212, 0.6)',
    zIndex: 6666,
  },
  addIcon: {
    color: theme.palette.primary[950],
    marginRight: theme.spacing(1),
  },
  addTitle: {
    color: theme.palette.primary[950],
  },
  addContainer: {
    display: 'flex',
  },
  fullScreen: {
    border: '3px dashed ' + theme.palette.primary[950],
    WebkitBoxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    boxSizing: 'border-box',
  },
  pasteInstructions: {
    position: 'absolute',
    bottom: theme.spacing(2),
    left: '50%',
    transform: 'translateX(-50%)',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    zIndex: 6667,
  },
}))

export default function DropZone({
  className,
  fileInputRef,
  overlayClassName,
  onNewFile,
  children,
  videoActive,
  allowVideo,
  customAccept,
  multiple,
  title,
  fullScreen,
  disabled,
  withPaste,
}) {
  const { classes, cx } = useStyles()
  const [highlighted, setHighlighted] = useState(false)

  const onDrop = (evt) => {
    setHighlighted(false)
    if (disabled) {
      return
    }
    evt.preventDefault()
    if (evt.dataTransfer && evt.dataTransfer.files) {
      if (multiple) {
        onNewFile(evt.dataTransfer.files)
      } else {
        onNewFile(evt.dataTransfer.files[0])
      }
    }
  }

  const onFileSelected = (evt) => {
    if (disabled) {
      return
    }
    if (evt.target && evt.target.files) {
      if (multiple) {
        onNewFile(evt.target.files)
      } else {
        onNewFile(evt.target.files[0])
      }
    }
  }

  const handlePaste = useCallback(
    (event) => {
      if (disabled || !withPaste) {
        return
      }
      const items = event.clipboardData.items
      for (let i = 0; i < items.length; i++) {
        if (
          items[i].type.indexOf('image') !== -1 ||
          (allowVideo && items[i].type.indexOf('video') !== -1)
        ) {
          const file = items[i].getAsFile()
          onNewFile([file])
          event.preventDefault()
          break
        }
      }
    },
    [onNewFile, allowVideo, disabled],
  )

  useEffect(() => {
    window.addEventListener('paste', handlePaste)
    return () => {
      window.removeEventListener('paste', handlePaste)
    }
  }, [handlePaste])

  return (
    <div
      className={cx(className, classes.root)}
      onDragOver={(evt) => {
        setHighlighted(true)
        evt.preventDefault()
      }}
      onDragLeave={() => setHighlighted(false)}
      onDrop={onDrop}
    >
      {children}
      {highlighted > 0 && !disabled && (
        <div
          className={cx(
            overlayClassName,
            classes.overlay,
            classes.noPointerEvents,
            classes.addContainer,
            fullScreen && classes.fullScreen,
          )}
        >
          <AddAPhotoOutlined className={classes.addIcon} color='inherit' />
          {title && (
            <Typography variant='button' className={classes.addTitle}>
              {title}
            </Typography>
          )}
        </div>
      )}
      <input
        ref={fileInputRef}
        className={classes.fileInput}
        type='file'
        multiple={multiple}
        accept={
          customAccept ||
          (allowVideo
            ? 'video/mp4,image/jpeg,image/png,image/gif,image/webp'
            : 'image/jpeg,image/png,image/gif,image/webp')
        }
        onInput={onFileSelected}
      />
    </div>
  )
}
