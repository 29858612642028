import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import { Button, Typography, useTheme } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

import { openGuestModal, openInviteGuestsModal } from 'actions/modals'
import AvatarGroup from '../avatar/AvatarGroup'
import GuestAvatarBadge from '../avatar/GuestAvatarBadge'

import dynamic from 'next/dynamic'
import {
  getCurrentEvent,
  getHasRespondedNoWaitingList,
} from '../../selectors/event'
import EventSection from '../../components/event/EventSection'
import { useIsLoggedIn } from 'utils/hooks'

const GuestModal = dynamic(
  () => import('shared-components/event/guestList/GuestModal'),
)
const InviteGuestsModal = dynamic(
  () => import('components/modals/InviteGuestsModal'),
)

const useStyles = makeStyles()((theme) => ({
  container: {
    position: 'relative',
  },
  root: {
    flexDirection: 'column',
    flexGrow: 1,
    height: 'fit-content',
    margin: 0,
    marginTop: theme.spacing(4),
  },
  topRow: {
    cursor: 'pointer',
    justifyContent: 'space-between',
    alignItems: 'start',
    marginBottom: theme.spacing(2),
  },
  stats: {
    justifyContent: 'space-around',
  },
  avatarGroup: {
    marginTop: theme.spacing(0),
  },
  goingText: {
    marginTop: theme.spacing(1),
  },
  expectedCircle: {
    alignSelf: 'center',
  },
  inviteMoreButton: {
    marginTop: theme.spacing(1),
  },
  inviteList: {
    paddingBottom: 0,
  },
  blur: {
    filter: 'blur(3px)',
  },
  lockedContainer: {
    cursor: 'pointer',

    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1),
    position: 'absolute',
    top: 0,
    left: 0,
    width: 'calc(100% + 32px)',
    height: 'calc(100% + 4px);',
    zIndex: 20,
    margin: theme.spacing(0, -2),
    background: 'rgba(255, 255, 255, 0.8)',
  },
  lockedTitle: {
    marginTop: theme.spacing(1),
  },
  lockedCaption: {
    marginTop: theme.spacing(0.5),
  },
  lockIcon: {
    width: 24,
    height: 24,
  },
  blockEvents: {
    pointerEvents: 'none',
  },
  hypeScore: {
    fontWeight: 900,
    marginBottom: theme.spacing(1),
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  infoIcon: {
    height: 14,
    width: 14,
    marginRight: theme.spacing(1),
    cursor: 'pointer',
  },

  impressions: {
    color: theme.palette.text.secondary,
    marginLeft: 4,
    cursor: 'pointer',
  },
  arrow: {
    color: '#424242',
    marginTop: theme.spacing(-0.3),
    width: 24,
    height: 24,
  },
  respondedContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  guestList: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  guestListMobile: {
    marginTop: theme.spacing(1.5),
  },
  guestListText: {
    fontWeight: 500,
  },
  emptyContainer: {
    display: 'flex',
    gap: theme.spacing(1.5),
    alignItems: 'center',
    padding: theme.spacing(1.5),
    background: theme.palette.background.secondary,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 8,
    cursor: 'default',
  },
  emptyButton: {
    color: theme.palette.primary[800],
    flexShrink: 0,
    padding: theme.spacing(1, 1.5),
  },
  text: {
    fontWeight: 400,
    '& > span': {
      fontWeight: 500,
    },
  },
  singleEventWidgetRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    marginTop: theme.spacing(-0.5),
  },
}))

const Attendees = ({ desktopMode, inSingleEventWidget = false }) => {
  const { classes, cx } = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const event = useSelector(getCurrentEvent)
  const isLoggedIn = useIsLoggedIn()
  const hasResponded = useSelector(getHasRespondedNoWaitingList)
  const guestsToUse = event.guests?.filter((e) => e?.status !== 'CANT')

  const [maxAvatars, setMaxAvatars] = useState(8)
  const containerRef = useRef(null)
  const theme = useTheme()

  useEffect(() => {
    const updateMaxAvatars = () => {
      if (!containerRef.current) return
      const width = containerRef.current.offsetWidth
      // Assuming each avatar takes ~40px (32px + gap), calculate how many can fit
      const avatarWidth = 28
      const calculatedMax = Math.floor(width / avatarWidth)
      setMaxAvatars(calculatedMax)
    }

    updateMaxAvatars()
    window.addEventListener('resize', updateMaxAvatars)
    return () => window.removeEventListener('resize', updateMaxAvatars)
  }, [])

  if (!guestsToUse) return null

  if (guestsToUse.length === 0 && !event.hasMoreGuests) return null

  const respondedCount =
    event.type === 'PINNING'
      ? event.statusCounts?.gaveAvailability
      : event.statusCounts?.joined +
        event.statusCounts?.going +
        event.statusCounts?.maybe +
        event.statusCounts?.cant

  const totalJoinedCount = Math.max(
    event.statusCounts?.joined,
    event.statusCounts?.ticketsSold,
  )

  const clickedWhileLocked = (e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    e.nativeEvent.stopPropagation()
    e.preventDefault()
    dispatch(openGuestModal())
  }

  const onMoreClicked = () => {
    dispatch(openGuestModal())
  }

  const onAddGuestsClicked = (event) => {
    event.preventDefault()
    event.stopPropagation()
    dispatch(openInviteGuestsModal())
  }

  if (event.guestsCanSeeGuests === false) return null

  const showBlocker =
    (!hasResponded &&
      (event.host ?? false) === false &&
      event.privacyType === 'PUBLIC') ||
    (!isLoggedIn && !hasResponded)

  const getText = () => {
    const firstName =
      guestsToUse[0]?.firstName ??
      guestsToUse[0]?.name?.split(' ')[0] ??
      guestsToUse[0]?.user?.firstName ??
      guestsToUse[0]?.user?.name?.split(' ')[0]
    const secondName =
      guestsToUse[1]?.firstName ??
      guestsToUse[1]?.name?.split(' ')[0] ??
      guestsToUse[1]?.user?.firstName ??
      guestsToUse[1]?.user?.name?.split(' ')[0]

    const hasMore = event.hasMoreGuests

    if (guestsToUse.length === 1 && firstName)
      return {
        stringId: hasMore
          ? 'respondedNamesLongAndMore.one'
          : 'respondedNamesLong.one',
        values: { firstName: firstName },
      }
    if (guestsToUse.length === 2 && firstName && secondName)
      return {
        stringId: hasMore
          ? 'respondedNamesLongAndMore.two'
          : 'respondedNamesLong.two',
        values: { firstName: firstName, secondName: secondName },
      }
    if (guestsToUse.length > 2 && firstName && secondName)
      return {
        stringId: 'respondedNamesLongAndMore.others',
        values: {
          firstName: firstName,
          secondName: secondName,
        },
      }

    return null
  }

  const text = getText()

  if (inSingleEventWidget && !(text || guestsToUse?.length > 0)) return null
  if (inSingleEventWidget)
    return (
      <div className={classes.singleEventWidgetRoot}>
        {guestsToUse?.length > 0 && (
          <div
            className={cx(classes.guestList)}
            onClick={showBlocker ? clickedWhileLocked : undefined}
          >
            <AvatarGroup
              max={12}
              className={
                event.type === 'OPEN'
                  ? undefined
                  : cx(classes.avatarGroup, !showBlocker && classes.blockEvents)
              }
              size='medium'
              respondedCount={respondedCount}
              smallGap
              noOverflow
            >
              {guestsToUse.map((g) => (
                <GuestAvatarBadge
                  hideBadge={true}
                  key={g.id}
                  guest={g}
                  size='small32'
                  showBlocker={showBlocker}
                />
              ))}
            </AvatarGroup>
          </div>
        )}
        {text && (
          <Typography variant='body2' className={cx(classes.text)}>
            <Trans
              t={t}
              i18nKey={text.stringId}
              values={text.values}
              components={{ 1: <span /> }}
            />
          </Typography>
        )}
      </div>
    )

  return (
    <>
      <EventSection
        title={
          t('guestList') +
          (totalJoinedCount > 0 ? ` · ${totalJoinedCount}` : '')
        }
        desktopMode={desktopMode}
        onMoreClicked={onMoreClicked}
        blocker={showBlocker}
        fullClickArea
        hasContent={true}
      >
        {guestsToUse?.length > 0 && (
          <div
            data-nosnippet
            ref={containerRef}
            className={cx(classes.guestList)}
            onClick={showBlocker ? clickedWhileLocked : undefined}
          >
            <AvatarGroup
              max={maxAvatars}
              className={
                event.type === 'OPEN'
                  ? undefined
                  : cx(classes.avatarGroup, !showBlocker && classes.blockEvents)
              }
              size='medium'
              respondedCount={respondedCount}
              smallGap
              noOverflow
            >
              {guestsToUse.map((g) => (
                <GuestAvatarBadge
                  hideBadge={true}
                  key={g.id}
                  guest={g}
                  size='small32'
                  showBlocker={showBlocker}
                />
              ))}
            </AvatarGroup>
            {text && (
              <Typography variant='body2' className={cx(classes.text)}>
                <Trans
                  t={t}
                  i18nKey={text.stringId}
                  values={text.values}
                  components={{ 1: <span /> }}
                />
              </Typography>
            )}
          </div>
        )}

        {(!guestsToUse || guestsToUse?.length === 0) && event.host && (
          <div
            className={classes.emptyContainer}
            style={{
              background: event.theme.colourScheme
                ? theme.palette.background.secondary
                : undefined,
            }}
          >
            <Typography variant='body2' style={{ lineHeight: '21px' }}>
              {t('attendeesEmptyText')}
            </Typography>

            <Button
              onClick={onAddGuestsClicked}
              className={classes.emptyButton}
              style={{
                color: event.theme.colourScheme
                  ? event.theme.darkMode
                    ? event.theme.colourScheme?.colorDarkButtonPrimary
                    : event.theme.colourScheme?.colorButtonPrimary
                  : undefined,
              }}
            >
              {t('attendeesEmptyButton')}
            </Button>
          </div>
        )}
      </EventSection>
    </>
  )
}

export default Attendees
