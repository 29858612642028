import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  SvgIcon,
  Typography,
} from '@mui/material'

import { withStyles } from 'tss-react/mui'
import BashButton, {
  BashButtonType,
} from '../../shared-components/buttons/BashButton'

const styles = (theme) => ({
  image: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing(),
    color: theme.palette.red.main,
    width: 63,
    height: 57,
  },
  title: {
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(1),
  },
  confirmRed: {
    color: theme.palette.red.main,
    borderColor: theme.palette.red.main,
    boxShadow: 'none',
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  close: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    boxShadow: 'none',
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    marginTop: theme.spacing(2),
    lineHeight: '14.32px',
  },
})

const CommConfirmDialog = ({
  classes,
  open,
  onClose,
  onConfirm,
  title,
  subtitle,
  description,
  cancelText,
  confirmText,
  icon,
  loading,
  children,
  ...otherProps
}) => {
  return (
    <Dialog
      aria-labelledby='confirm-action-dialog'
      onClose={onClose}
      open={open}
      {...otherProps}
    >
      <DialogTitle>
        <Typography className={classes.title} variant='h6'>
          {title}
        </Typography>
        <Typography variant='caption' className={classes.subtitle}>
          {subtitle}
        </Typography>
      </DialogTitle>
      {(icon || description || children) && (
        <DialogContent>
          {icon && (
            <SvgIcon className={classes.image}>
              <path d={icon} />
            </SvgIcon>
          )}
          {children}
          <DialogContentText color='textPrimary' variant='body1'>
            {description}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions
        sx={{
          pr: 2,
          pb: 2,
        }}
      >
        <BashButton
          type={BashButtonType.WHITE_PRIMARY_BORDERED}
          onClick={onClose}
        >
          {cancelText}
        </BashButton>

        <BashButton
          type={BashButtonType.WHITE_RED_BORDERED}
          onClick={onConfirm}
          loading={loading}
        >
          {confirmText}
        </BashButton>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(CommConfirmDialog, styles)
