import { Button, InputAdornment, Menu, MenuItem, SvgIcon } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import React, { useRef, useState } from 'react'
import { Cancel, Search, SearchOutlined } from '@mui/icons-material'
import TextInput from '../../../shared-components/common/TextInput'
import {
  mdiSortAlphabeticalAscending,
  mdiSortCalendarAscending,
  mdiSortCalendarDescending,
} from '@mdi/js'
import Typography from '@mui/material/Typography'
import { setModalPage } from '../../../actions/modals'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  button: {
    minWidth: 0,
    borderRadius: 100,
    height: 40,
    width: 40,
  },
  searchBar: {
    background: theme.palette.grey[150],
    height: 40,
    fontSize: '16px',
    padding: theme.spacing(0, 1),
    paddingRight: theme.spacing(5),
    borderRadius: '100px !important',
    '& *::-webkit-search-cancel-button': {
      display: 'none',
    },
  },
  searchBarContainer: {
    position: 'relative',
  },
  searchBarCancel: {
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    color: theme.palette.text.tertiary,
    width: 20,
    height: 20,
    marginRight: theme.spacing(1.5),
    cursor: 'pointer',
  },
  menuRoot: {
    width: '100%',
    maxWidth: '359px',
    [theme.breakpoints.down('md')]: {
      left: '0 !important',
    },
  },
  menuList: {
    padding: 0,
  },
  menuItem: {
    padding: theme.spacing(1.5),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}))

const GuestManagementActions = ({ query, setQuery, sortType, setSortType }) => {
  const { classes } = useStyles()
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const page = useSelector((state) => state.modals.page.guestManagement)
  const searchRef = useRef()
  const searchExpanded = page === 'search'

  const [anchorEl, setAnchorEl] = useState(null)

  const onSearchClick = () => {
    dispatch(setModalPage('guestManagement', 'search'))
    setTimeout(() => searchRef.current && searchRef.current.focus(), 30)
  }
  const onSearchCancelClick = () => {
    setQuery('')
    dispatch(setModalPage('guestManagement', 'guestList'))
  }
  const onSearchChange = (event) => {
    setQuery(event.target.value)
  }

  const onSortByClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const onSortItemClick = (type) => {
    setSortType(type)
    handleMenuClose()
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={classes.root}>
      {!searchExpanded && (
        <Button className={classes.button} onClick={onSearchClick}>
          <Search />
        </Button>
      )}
      {searchExpanded && (
        <div className={classes.searchBarContainer}>
          <TextInput
            className={classes.searchBar}
            id='search-field'
            placeholder={t('searchGuests')}
            type='search'
            size='small'
            aria-labelledby='search-field'
            aria-label='Search'
            variant='outlined'
            onChange={onSearchChange}
            value={query}
            startAdornment={
              <InputAdornment position='start'>
                <SearchOutlined className={classes.searchIcon} />
              </InputAdornment>
            }
            inputRef={searchRef}
          />

          <Cancel
            onClick={onSearchCancelClick}
            className={classes.searchBarCancel}
          />
        </div>
      )}

      {page !== 'joinOptions' && page !== 'menu' && page !== 'revenue' && (
        <Button onClick={onSortByClick} className={classes.button}>
          {sortType === 'NAME' && (
            <SvgIcon>
              <path d={mdiSortAlphabeticalAscending} />
            </SvgIcon>
          )}
          {sortType === 'DATE' && (
            <SvgIcon>
              <path d={mdiSortCalendarDescending} />
            </SvgIcon>
          )}
          {sortType === 'DATE_ASC' && (
            <SvgIcon>
              <path d={mdiSortCalendarAscending} />
            </SvgIcon>
          )}
        </Button>
      )}

      <Menu
        style={{ minHeight: '0px' }}
        classes={{
          paper: classes.menuRoot,
          list: classes.menuList,
        }}
        id='create-menu'
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleMenuClose}
        disableAutoFocusItem
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={() => onSortItemClick('NAME')}
        >
          <SvgIcon>
            <path d={mdiSortAlphabeticalAscending} />
          </SvgIcon>
          <Typography variant='body2'>{t('alphabetically')}</Typography>
        </MenuItem>

        <MenuItem
          className={classes.menuItem}
          onClick={() => onSortItemClick('DATE')}
        >
          <SvgIcon>
            <path d={mdiSortCalendarDescending} />
          </SvgIcon>
          <Typography variant='body2'>{t('dateDescending')}</Typography>
        </MenuItem>

        <MenuItem
          className={classes.menuItem}
          onClick={() => onSortItemClick('DATE_ASC')}
        >
          <SvgIcon>
            <path d={mdiSortCalendarAscending} />
          </SvgIcon>
          <Typography variant='body2'>{t('dateAscending')}</Typography>
        </MenuItem>
      </Menu>
    </div>
  )
}

export default GuestManagementActions
