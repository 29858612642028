import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Paper, SvgIcon, Typography } from '@mui/material'
import VerifiedIcon from '../../svg/CheckDecagram'
import { useTranslation } from 'react-i18next'
import { formatShortNumber } from '../../utils/eventFunctions'
import Link from 'next/link'

const useStyles = makeStyles()((theme) => ({
  paper: {
    borderRadius: theme.spacing(2),
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 153,
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down('lg')]: {
      width: 115,
    },
  },
  avatar: {
    width: 64,
    height: 64,
    borderRadius: '100px',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      width: 48,
      height: 48,
    },
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  name: {
    fontWeight: 500,
    maxWidth: '112px',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '80px',
      fontSize: '0.7rem',
    },
  },
  verified: {
    width: 16,
    height: 16,
    marginLeft: theme.spacing(0.5),
    [theme.breakpoints.down('lg')]: {
      width: 12,
      height: 12,
    },
  },
  statsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  statContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 56,
    [theme.breakpoints.down('lg')]: {
      width: 42,
    },
  },
  statNumber: {
    fontWeight: 500,
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.625rem',
    },
  },
  statCaption: {
    fontSize: '0.8125rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.6125rem',
    },
  },
}))

const PagesCard = ({ page, className }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation('common')
  if (!page) return null
  const showFollowers = page.followerCount > page.guestCount

  return (
    <Link href={`/pages/${page.id}`} target='_blank'>
      <Paper elevation={24} className={cx(classes.root, className)}>
        <img
          className={classes.avatar}
          alt='page avatar'
          src={page.avatarUrls?.lg ?? ''}
        />
        <div className={classes.nameContainer}>
          <Typography noWrap className={classes.name} variant='body2'>
            {page.name}
          </Typography>
          {page.verified && (
            <SvgIcon
              component={VerifiedIcon}
              className={classes.verified}
              color='primary'
            />
          )}
        </div>
        <div className={classes.statsContainer}>
          <div className={classes.statContainer}>
            <Typography className={classes.statNumber} variant='body2'>
              {formatShortNumber(page.eventCount)}
            </Typography>
            <Typography
              color='textSecondary'
              className={classes.statCaption}
              variant='body2'
            >
              {t('pages.hosted').toLowerCase()}
            </Typography>
          </div>
          {showFollowers && (
            <div className={classes.statContainer}>
              <Typography className={classes.statNumber} variant='body2'>
                {formatShortNumber(page.followerCount)}
              </Typography>
              <Typography
                color='textSecondary'
                className={classes.statCaption}
                variant='body2'
              >
                {t('pages.followers').toLowerCase()}
              </Typography>
            </div>
          )}
          {!showFollowers && (
            <div className={classes.statContainer}>
              <Typography className={classes.statNumber} variant='body2'>
                {formatShortNumber(page.guestCount)}
              </Typography>
              <Typography
                color='textSecondary'
                className={classes.statCaption}
                variant='body2'
              >
                {t('pages.guests').toLowerCase()}
              </Typography>
            </div>
          )}
        </div>
      </Paper>
    </Link>
  )
}

export default PagesCard
