import {
  Button,
  Menu,
  MenuItem,
  Popover,
  SvgIcon,
  Tooltip,
  useMediaQuery,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import {
  ArrowDropDown,
  EmojiEmotionsOutlined,
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  GifBoxOutlined,
  LinkOutlined,
  PhotoCameraOutlined,
} from '@mui/icons-material'
import React, { useState } from 'react'
import {
  useActive,
  useChainedCommands,
  useCommands,
  useSelectedText,
} from '@remirror/react'
import Typography from '@mui/material/Typography'
import { mdiFormatLetterCase } from '@mdi/js'
import LinkModal from './LinkModal'
import { useTranslation } from 'react-i18next'
import dynamic from 'next/dynamic'

const Picker = dynamic(
  () => {
    return import('emoji-picker-react')
  },
  { ssr: false },
)

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
    // margin: theme.spacing(-2, -2, 0, -2),
    padding: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  menuButton: {
    minWidth: 0,
    color: theme.palette.text.primary,
  },
  menuButtonActive: {
    background: theme.palette.grey[300],
  },
  menuButtonDisabled: {
    color: theme.palette.text.primary + '60 !important',
  },
  divider: {
    width: 1,
    height: '100%',
    background: theme.palette.divider,
    margin: theme.spacing(0, 0.5),
    [theme.breakpoints.up('900')]: {
      margin: theme.spacing(0, 1),
    },
  },
}))

const DescriptionMenu = ({ className, withImages, onAddImage, onAddGif }) => {
  const { classes, cx } = useStyles()
  const [menuOpen, setMenuOpen] = useState(null)
  const [linkOpen, setLinkOpen] = useState(false)
  const [linkUrl, setLinkUrl] = useState('')
  const selected = useSelectedText()

  const { t } = useTranslation('common')
  const desktopMode = useMediaQuery('(min-width:900px)')

  const chain = useChainedCommands()
  const commands = useCommands()
  const active = useActive()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const onOpenPicker = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onEmojiClicked = (emoji) => {
    commands.insertText(emoji)
  }

  const setHeading = (variant) => {
    if (variant === 'h6') {
      if (!active.heading({ level: 1 })) {
        chain.toggleHeading({ level: 1 }).focus().run()
      }
    } else if (variant === 'subtitle1') {
      if (!active.heading({ level: 3 })) {
        chain.toggleHeading({ level: 3 }).focus().run()
      }
    } else if (variant === 'body1') {
      if (active.heading()) {
        chain.toggleHeading(null).focus().run()
      }
    }

    setMenuOpen(null)
  }

  const onLinkButtonClick = () => {
    if (active.link()) {
      chain.removeLink().focus().run()
    } else {
      setLinkUrl(selected)
      setLinkOpen(true)
    }
  }

  const onLinkClose = () => {
    setLinkOpen(false)
  }

  const onLinkConfirm = (href, text) => {
    chain
      .replaceText({
        content: text,
        // keepSelection: true
      })
      .updateLink({
        href: href,
      })
      .focus()
      .run()
    onLinkClose()
  }

  const EmojiPicker = () => {
    return (
      <Picker
        previewConfig={{ showPreview: false }}
        skinTonesDisabled
        autoFocusSearch={false}
        onEmojiClick={(emoji) => {
          handleClose()
          onEmojiClicked(emoji.emoji)
        }}
      />
    )
  }

  return (
    <div className={cx(classes.root, className)}>
      <Menu
        id='user-menu'
        keepMounted
        anchorEl={menuOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={Boolean(menuOpen)}
        onClose={() => setMenuOpen(null)}
        classes={{
          list: classes.menuList,
        }}
      >
        <MenuItem className={classes.menuItem} onClick={() => setHeading('h6')}>
          <Typography variant='h6'>Title</Typography>
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => setHeading('subtitle1')}
        >
          <Typography variant='subtitle1'>Subtitle</Typography>
        </MenuItem>

        <MenuItem
          className={classes.menuItem}
          onClick={() => setHeading('body1')}
        >
          <Typography variant='body1'>Body</Typography>
        </MenuItem>
      </Menu>
      <Tooltip title={t('selectTextType')}>
        <Button
          className={cx(
            classes.menuButton,
            active.heading() && classes.menuButtonActive,
          )}
          disabled={commands.toggleHeading.enabled() === false}
          classes={{
            disabled: classes.menuButtonDisabled,
          }}
          onClick={(e) => {
            setMenuOpen(e.target)
          }}
        >
          <SvgIcon>
            <path d={mdiFormatLetterCase} />
          </SvgIcon>
          {desktopMode && <ArrowDropDown />}
        </Button>
      </Tooltip>
      {desktopMode && <div className={classes.divider} />}
      <Tooltip title={t('bold')}>
        <Button
          className={cx(
            classes.menuButton,
            active.bold() && classes.menuButtonActive,
          )}
          onClick={() => {
            chain.toggleBold().focus().run()
          }}
        >
          <FormatBold />
        </Button>
      </Tooltip>
      <Tooltip title={t('italic')}>
        <Button
          className={cx(
            classes.menuButton,
            active.italic() && classes.menuButtonActive,
          )}
          onClick={() => {
            chain.toggleItalic().focus().run()
          }}
        >
          <FormatItalic />
        </Button>
      </Tooltip>
      {/*<Button*/}
      {/*  className={cn(*/}
      {/*    classes.menuButton,*/}
      {/*    active.underline() && classes.menuButtonActive,*/}
      {/*  )}*/}
      {/*  onClick={() => {*/}
      {/*    chain.toggleUnderline().focus().run()*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <FormatUnderlined />*/}
      {/*</Button>*/}
      <Tooltip
        title={
          !selected && !active.link() ? t('selectTextForLink') : t('addLink')
        }
      >
        <span>
          <Button
            className={cx(
              classes.menuButton,
              active.link() && classes.menuButtonActive,
            )}
            onClick={onLinkButtonClick}
            disabled={!selected && !active.link()}
            classes={{
              disabled: classes.menuButtonDisabled,
            }}
          >
            <LinkOutlined />
          </Button>
        </span>
      </Tooltip>
      {desktopMode && <div className={classes.divider} />}
      <Tooltip
        title={
          commands.toggleBulletList.enabled() === false
            ? t('listAvailableBody')
            : t('bulletList')
        }
      >
        <span>
          <Button
            className={cx(
              classes.menuButton,
              active.bulletList() && classes.menuButtonActive,
            )}
            disabled={commands.toggleBulletList.enabled() === false}
            classes={{
              disabled: classes.menuButtonDisabled,
            }}
            onClick={() => {
              chain.toggleBulletList().focus().run()
            }}
          >
            <FormatListBulleted />
          </Button>
        </span>
      </Tooltip>
      <Tooltip
        title={
          commands.toggleOrderedList.enabled() === false
            ? t('listAvailableBody')
            : t('orderedList')
        }
      >
        <span>
          <Button
            className={cx(
              classes.menuButton,
              active.orderedList() && classes.menuButtonActive,
            )}
            disabled={commands.toggleOrderedList.enabled() === false}
            classes={{
              disabled: classes.menuButtonDisabled,
            }}
            onClick={() => {
              chain.toggleOrderedList().focus().run()
            }}
          >
            <FormatListNumbered />
          </Button>
        </span>
      </Tooltip>
      <div className={classes.divider} />
      {withImages && (
        <>
          <Tooltip title={t('addImage')}>
            <span>
              <Button
                className={cx(classes.menuButton)}
                onClick={() => {
                  onAddImage()
                }}
              >
                <PhotoCameraOutlined />
              </Button>
            </span>
          </Tooltip>
          <Tooltip title={t('addGif')}>
            <span>
              <Button
                className={cx(classes.menuButton)}
                onClick={() => {
                  onAddGif()
                }}
              >
                <GifBoxOutlined />
              </Button>
            </span>
          </Tooltip>
        </>
      )}
      <Tooltip title={t('addEmoji')}>
        <span>
          <Button
            className={cx(classes.menuButton)}
            onClick={(e) => {
              onOpenPicker(e)
            }}
          >
            <EmojiEmotionsOutlined />
          </Button>
        </span>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <EmojiPicker />
      </Popover>
      <LinkModal
        open={linkOpen}
        onClose={onLinkClose}
        onConfirm={onLinkConfirm}
        linkUrl={linkUrl}
        setLinkUrl={setLinkUrl}
      />
    </div>
  )
}

export default DescriptionMenu
