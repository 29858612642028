import {
  Box,
  Divider,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import {
  EmailOutlined,
  ExpandLess,
  ExpandMore,
  HowToReg,
  LocalActivity,
  OpenInNew,
  QrCode,
  Reply,
} from '@mui/icons-material'
import ChevronRight from '@mui/icons-material/ChevronRight'
import { fetchEvent, rsvpFlow } from 'actions/event'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RsvpStatus, UserGuestMinimalDto } from 'types/types'
import {
  getActiveGuestWithoutNew,
  getCurrentEvent,
} from '../../selectors/event'
import FlatButton from '../../shared-components/buttons/FlatButton'
import CantIcon from '../../svg/status/Cant'
import MaybeIcon from '../../svg/status/Maybe'
import { useIsLoggedIn } from '../../utils/hooks'
import ActionOverflow from './actions/ActionOverflow'
import EventTickets from './EventTickets'
import ExternalTicketsDesktop, {
  JoinOptionMessage,
} from './ExternalTicketsDesktop'
import { PinningBox } from './rsvp/PinningBox'
import { openModal, openSignInModal } from 'actions/modals'
import TextBadge from '../badge/TextBadge'
import AddNameSheet from '../RsvpWizard/AddNameSheet'
import {
  getEventDateFromNow,
  getGuestAvatarUrls,
} from 'shared-components/utils/eventDisplay'
import BashButton, {
  BashButtonType,
  ButtonViewAlignment,
} from '../../shared-components/buttons/BashButton'
import dayjs from 'dayjs'
import apiClient from '../../shared-components/utils/ApiClient'
import BashIconButton from '../../shared-components/buttons/BashIconButton'
import Row from '../Row'

const useStyles = makeStyles<
  void,
  'iconContainerOverlay' | 'respondedIconContainer'
>()((theme: Theme, _params, classes) => ({
  container: {
    width: '100%',
  },
  root: {
    alignItems: 'center',
    margin: theme.spacing(0, 0),
    padding: 0,
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  item: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: theme.palette.background.secondary,
    borderRadius: '8px',
    gap: '10px',
    transition: 'background ease 0.2s, transform ease 0.1s',
    border: '1px solid ' + theme.palette.divider,
    padding: theme.spacing(1),
    '&:hover': {
      [`& > .${classes.iconContainerOverlay}`]: {
        opacity: 0.04,
      },
    },
    // '&:focus': {
    //   '& > $iconContainer': {
    //     animation: '$pressDown 0.1s ease',
    //   },
    // },
    '&:active': {
      // '& > $iconContainer': {
      //   transform: 'scale(0.9)',
      //   animation: 'none',
      // },
      [`& > .${classes.iconContainerOverlay}`]: {
        opacity: '0.00 !important',
        transition: 'opacity 0.1s linear',
      },
    },
  },
  iconContainerOverlay: {
    zIndex: 2,
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    background: 'black',
    opacity: '0',
    transition: 'opacity 0.15s linear',
    borderRadius: 100,
  },
  icon: {
    height: 16,
    width: 16,
  },
  goingIcon: {
    color: theme.palette.secondary.main,
  },
  goingContainer: {
    animationDelay: '0s',
    // backgroundColor: '#F3ECFD',
  },
  maybeIcon: {
    color: theme.palette.yellow.main,
    left: -1,
    position: 'relative',
  },
  maybeContainer: {
    animationDelay: '0.4s',

    // backgroundColor: '#F2F5FD',
  },
  cantIcon: {
    color: '#EE6C4D',
  },
  cantContainer: {
    animationDelay: '0.8s',
    // background: '#FDF0ED',
  },
  respondIcon: {
    color: 'white',
    transform: 'rotate(90deg)',
  },
  respondContainer: {
    background: theme.palette.secondary.main,
  },
  respondItem: {
    gridArea: 'middle',
  },
  caption: {
    textAlign: 'center',
    textWrap: 'nowrap',
  },
  '@keyframes pressDown': {
    '0%': {
      transform: 'scale(1)',
    },
    '100%': {
      transform: 'scale(0.9)',
    },
  },
  '@keyframes shimmer': {
    '100%': {
      WebkitMaskPosition: 'left',
    },
  },
  shimmer: {},
  respondedItem: {
    display: 'flex',
    gap: 8,
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
    width: '100%',

    '&:hover': {
      [`& > .${classes.iconContainerOverlay}`]: {
        opacity: 0.04,
      },
    },
    '&:focus': {
      [`& > .${classes.respondedIconContainer}`]: {
        animation: '$pressDown 0.1s ease',
      },
    },
    '&:active': {
      [`& > .${classes.respondedIconContainer}`]: {
        transform: 'scale(0.9)',
        animation: 'none',
      },
      [`& > .${classes.iconContainerOverlay}`]: {
        opacity: '0.00 !important',
        transition: 'opacity 0.1s linear',
      },
    },
  },
  respondedIconContainer: {
    borderRadius: '100px',
    display: 'flex',
    cursor: 'pointer',
    width: 56,
    height: 56,
    transition: 'background ease 0.2s, transform ease 0.1s',
    justifyContent: 'center',
    alignItems: 'center',
  },
  respondedIcon: {
    width: 28,
    height: 28,
  },
  shareItem: {
    gridArea: 'right',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  shareContainer: {
    marginBottom: theme.spacing(2),
  },
  statusChevron: {
    height: '16px',
    width: '16px',
    transform: 'rotate(90deg)',
    position: 'absolute',
    right: 0,
  },
  respondedCaption: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: theme.spacing(0, 2),
  },
  fullText: {
    textAlign: 'center',
  },
  ticketButton: {
    color: theme.palette.grey[800],
    border: '1px solid ' + theme.palette.grey[300],
    '&:hover': {
      // backgroundColor: theme.palette.grey[300],
      // bordercolor: theme.palette.text.tertiary,
    },
    fontSize: '12px',
    lineHeight: '14.32px',
    // flexGrow: 1,
  },
  ticketLinkButton: {
    backgroundColor: theme.palette.primary[100],
    color: theme.palette.primary[800],
    borderColor: theme.palette.primary[800],
    height: 40,
    '&:hover': {
      backgroundColor: theme.palette.primary[200],
    },
    fontSize: '12px',
    lineHeight: '14.32px',
    flexGrow: 1,
  },
  lowJoinButton: {
    height: '40px',
    width: '100%',
  },
  lowJoinDisabled: {
    backgroundColor: theme.palette.grey[300] + ' !important',
  },
  lowJoinWrapper: {
    width: '100%',
    position: 'relative',
  },
  ticketBadge: {
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    top: '50%',
    left: theme.spacing(1),
    transform: 'translateY(-50%)',
    background: theme.palette.secondary[950],
    padding: theme.spacing('5px', 1),
    gap: '3px',
    color: 'white',
    borderTopLeftRadius: '100px',
    borderBottomLeftRadius: '100px',
  },
  ticketBadgeDisabled: {
    background: theme.palette.grey[600],
  },
  ticketText: {
    fontWeight: 500,
  },
  ticketDot: {
    backgroundColor: 'green',
    borderRadius: '50%',
    width: '8px',
    height: '8px',
  },
  ticketDotRed: {
    backgroundColor: theme.palette.red.main,
  },
  rsvpButtonsContainer: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  inviterContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    width: '100%',
    justifyContent: 'space-between',
  },
  inviteContainer: {
    display: 'flex',
    gap: theme.spacing(1.5),
    width: '100%',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  inviterContent: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    width: '100%',
  },
  inviterAvatar: {
    width: 24,
    height: 24,
    borderRadius: 100,
  },
  rsvpContainer: {
    background: theme.palette.grey[100],
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(1),
    border: '1px solid ' + theme.palette.divider,
    width: '100%',
  },
  scrollContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    overflow: 'auto',
    marginTop: theme.spacing(1),
    maxHeight: '300px',
    marginBottom: theme.spacing(2),
  },
  totalContainer: {
    marginTop: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '1px solid ' + theme.palette.divider,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  nextButton: {
    flex: 1,
  },
  title: {
    marginBottom: theme.spacing(0.5),
  },
  titleContainer: {
    margin: theme.spacing(2),
  },
  joinContainer: {
    margin: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  body2High: {
    lineHeight: '1.3125rem',
  },
  statusDropdown: {
    alignSelf: 'flex-end',
  },
  titleContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  phone: {
    color: theme.palette.text.secondary,
    width: 16,
    height: 16,
  },
  questionContainer: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
  },
  shareButton: {
    backgroundColor: theme.palette.primary[100],
    color: theme.palette.primary[600],
    borderColor: theme.palette.primary[600],
    height: 40,
    '&:hover': {
      backgroundColor: theme.palette.primary[200],
    },
    fontSize: '12px',
    lineHeight: '14.32px',
  },
  buttonIcon: {
    width: 16,
    height: 16,
    // color: theme.palette.text.secondary,
  },
  messageToGuestItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    padding: theme.spacing(2),
    border: '1px solid ' + theme.palette.divider,
    borderRadius: '8px',
    background: theme.palette.grey[150],
    margin: theme.spacing(2),
  },
  messageToGuestItemText: {
    lineHeight: '21px',
  },
  titleIcons: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    marginTop: -4,
    marginRight: -8,
    width: 32,
    height: 32,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  // dateoptions
  dateOptionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    gap: theme.spacing(1),
  },
  dateOptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  dateOptionClickArea: {
    cursor: 'pointer',
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 116,
  },
  dateOptionSelectRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  previewContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    cursor: 'pointer',
  },
  manageGuest: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[150],
    border: '1px solid ' + theme.palette.divider,
    borderRadius: theme.spacing(1),
    margin: theme.spacing(2, 2, 0, 2),
  },
  manageGuestTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  verifyButton: {
    backgroundColor: theme.palette.background.secondary,
    border: '1px solid ' + theme.palette.divider,
    borderRadius: theme.spacing(1),
    color: theme.palette.text.primary,
    fontSize: '14px',
    lineHeight: '21px',
    padding: theme.spacing(1),
    fontWeight: 400,
    '&:hover': {
      backgroundColor: theme.palette.divider,
    },
  },
  approvalBadge: {
    marginTop: theme.spacing(1),
  },
  avatar: {
    borderRadius: 100,
    width: 24,
    height: 24,
    border: `1px solid ${theme.palette.grey[200]}`,
    marginRight: theme.spacing(0.5),
  },
  buttonText: {
    fontSize: '12px',
    lineHeight: '14.32px',
    fontWeight: 600,
  },
  menuItem: {
    width: '120px',
  },
  menuItemContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  manageGuestTopLeft: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  divider: {
    borderColor: theme.palette.divider,
  },
}))

const TitleBox = ({ title, subtitle, noTickets = false }) => {
  const { classes } = useStyles()
  const event = useSelector(getCurrentEvent)
  const { t } = useTranslation('common')

  const joinOptions = event.joinOptions?.filter(
    (j) => j.enabled !== false && (!j.errors || j.errors.length === 0),
  )
  const hasBashTickets =
    event.hasJoinOptions || (joinOptions && joinOptions.length > 0)
  const availableTickets = joinOptions?.filter((t) => t.available)

  const selectedTicketOption = joinOptions?.find(
    (e) => e.myTickets && e.myTickets.length > 0,
  )

  const oneFreePerGuest =
    availableTickets &&
    availableTickets.length === 1 &&
    joinOptions?.length === 1 &&
    availableTickets[0]?.maxAmountPerGuest === 1 &&
    availableTickets[0]?.price === 0 &&
    !availableTickets[0]?.chooseYourOwnPrice &&
    availableTickets[0]?.hidden !== true

  const isRequestEvent = joinOptions?.some((jo) => jo.approvalRequired) ?? false

  const buyMore =
    hasBashTickets &&
    event.myGuest?.status === 'JOINED' &&
    event.myGuest?.joinOption?.type === 'TICKET' &&
    (selectedTicketOption?.quantityAvailableForYou ?? 9999999) > 0

  const actionOverflowItems = ['removeMe']
  if (buyMore) actionOverflowItems.unshift('buyMore')
  // TODO: editing questions is not implemented yet
  // if (activeGuest?.questionsCompleted)
  //   actionOverflowItems.unshift('updateQuestions')

  return (
    <div className={classes.titleContainer}>
      <div className={classes.titleContent}>
        <Typography className={classes.title} variant='subtitle1'>
          {title}
        </Typography>
        <div className={classes.titleIcons}>
          {event.myGuest && (event.myGuest?.user || event.myGuest?.name) && (
            <ActionOverflow
              location='web_event_page'
              items={actionOverflowItems}
              small
              tiny
            />
          )}
        </div>
      </div>
      <Typography variant='body2' className={classes.body2High}>
        {subtitle}
      </Typography>
      {isRequestEvent && oneFreePerGuest && (
        <TextBadge
          color='tertiary'
          bold={false}
          className={classes.approvalBadge}
        >
          <HowToReg />
          {t('approvalRequired')}
        </TextBadge>
      )}
    </div>
  )
}

export const InvitesYou = () => {
  const event = useSelector(getCurrentEvent)

  const { classes } = useStyles()
  const { t } = useTranslation('common')

  const inviterAvatar = event.invitedBy?.model?.avatarUrls?.lg
  const inviterName = event.invitedBy?.model?.name

  if (!inviterAvatar || !inviterName) return null
  return (
    <div className={classes.inviterContent}>
      <img
        src={inviterAvatar}
        alt='host avatar'
        className={classes.inviterAvatar}
      />
      <Typography variant='body2'>
        <Trans
          t={t}
          i18nKey={'nameInvitesYou'}
          values={{ name: inviterName }}
          components={{ b: <b /> }}
        />
      </Typography>
    </div>
  )
}

const InviterBox = ({}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation('common')
  const dispatch = useDispatch()

  const activeGuest: UserGuestMinimalDto = useSelector(getActiveGuestWithoutNew)

  return (
    <div className={classes.inviteContainer}>
      <div className={classes.inviterContainer}>
        <InvitesYou />
        {(activeGuest?.status === RsvpStatus.MAYBE ||
          activeGuest?.status === RsvpStatus.CANT) && (
          <div className={classes.statusDropdown}>
            <div
              className={classes.item}
              tabIndex={0}
              onClick={() =>
                dispatch(rsvpFlow(activeGuest?.status, 'web_event_page'))
              }
            >
              <div className={classes.iconContainerOverlay} />
              <ChevronRight
                style={{ transform: 'rotate(90deg)' }}
                className={cx(classes.icon)}
              />
              <Typography className={classes.caption} variant='body2'>
                {activeGuest?.status === RsvpStatus.MAYBE
                  ? t('maybe')
                  : t('cant')}
              </Typography>
            </div>
          </div>
        )}
      </div>
      {!activeGuest?.status && (
        <div className={cx(classes.rsvpButtonsContainer)}>
          <div
            className={classes.item}
            tabIndex={0}
            onClick={() =>
              dispatch(rsvpFlow(RsvpStatus.MAYBE, 'web_event_page'))
            }
          >
            <div className={classes.iconContainerOverlay} />
            <MaybeIcon className={cx(classes.maybeIcon, classes.icon)} />
            <Typography className={classes.caption} variant='body2'>
              {t('maybe')}
            </Typography>
          </div>
          <div
            className={classes.item}
            tabIndex={0}
            onClick={() =>
              dispatch(rsvpFlow(RsvpStatus.CANT, 'web_event_page'))
            }
          >
            <div className={classes.iconContainerOverlay} />
            <CantIcon className={cx(classes.cantIcon, classes.icon)} />
            <Typography className={classes.caption} variant='body2'>
              {t('cant')}
            </Typography>
          </div>
        </div>
      )}
    </div>
  )
}

const JoinBox = ({}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)

  const event = useSelector(getCurrentEvent)

  const joinOptions = event.joinOptions?.filter(
    (j) => j.enabled !== false && (!j.errors || j.errors.length === 0),
  )
  const hasBashTickets =
    event.hasJoinOptions || (joinOptions && joinOptions.length > 0)
  const hasExternalUrl =
    event.externalTicketInfo != null || event.sourceUrl != null
  const isLoggedIn = useIsLoggedIn()
  const rsvpPending = useSelector((state: any) => state.event.rsvpPending)

  const isGuestlistNoName =
    event.myGuest &&
    event.myGuest.status === 'JOINED' &&
    !event.myGuest.user &&
    !event.myGuest.name &&
    !isLoggedIn

  if (isGuestlistNoName) {
    return (
      <div className={classes.joinContainer}>
        <AddNameSheet open={open} onClose={() => setOpen(false)} />
        <FlatButton
          color='secondary'
          onClick={() => {
            setOpen(true)
          }}
          className={cx(classes.lowJoinButton)}
          fullWidth
          // startIcon={<CheckCircleOutlineIcon />}
        >
          {t('confirmAttendance')}
        </FlatButton>
      </div>
    )
  }

  if (hasBashTickets) {
    return <EventTickets />
  }

  if (hasExternalUrl && !hasBashTickets) {
    return <ExternalTicketsDesktop />
  }

  return (
    <>
      {event.joinInfoMessage && (
        <div className={classes.messageToGuestItem}>
          <Typography variant='body2'>“{event.joinInfoMessage}“</Typography>
        </div>
      )}
      <div className={classes.joinContainer}>
        <BashButton
          loading={rsvpPending}
          type={BashButtonType.SECONDARY}
          onClick={() => dispatch(rsvpFlow('GOING', 'web_event_page'))}
          className={cx(classes.lowJoinButton)}
        >
          {t('setStatusToGoing')}
        </BashButton>
        {/*<FlatButton*/}
        {/*  color='secondary'*/}
        {/*  onClick={() => dispatch(rsvpFlow('GOING', 'web_event_page'))}*/}
        {/*  className={cn(classes.lowJoinButton)}*/}
        {/*  fullWidth*/}
        {/*  // startIcon={<CheckCircleOutlineIcon />}*/}
        {/*>*/}
        {/*  {t('setStatusToGoing')}*/}
        {/*</FlatButton>*/}
      </div>
    </>
  )
}

const ManageGuestEmail = ({}) => {
  const { classes } = useStyles()
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const router = useRouter()
  const theme = useTheme()
  const [loading, setLoading] = useState(false)
  const [linked, setLinked] = useState(false)

  const event = useSelector(getCurrentEvent)
  const isLoggedIn = useIsLoggedIn()
  const activeGuest: UserGuestMinimalDto = useSelector(getActiveGuestWithoutNew)
  const unlinkedGuest: UserGuestMinimalDto | undefined = linked
    ? undefined
    : event.unlinkedGuest

  if (isLoggedIn && !unlinkedGuest) {
    return null
  }

  if ((!activeGuest || !activeGuest?.emailAddress) && !unlinkedGuest) {
    return null
  }

  if (
    activeGuest?.name &&
    activeGuest?.name.length > 0 &&
    activeGuest?.user?.hasAvatar === true &&
    !unlinkedGuest
  ) {
    return null
  }

  const linkGuest = async () => {
    setLoading(true)
    await apiClient.event.linkGuest(unlinkedGuest?.id)
    setLoading(false)
    setLinked(true)
  }

  const onVerifyClicked = async () => {
    if (unlinkedGuest) {
      await linkGuest()
      dispatch(fetchEvent(event.code))
    } else {
      router.replace(
        {
          ...router,
          query: {
            ...router.query,
            redirectTo: router.asPath,
          },
        },
        undefined,
        { shallow: true },
      )
      dispatch(openSignInModal(activeGuest.emailAddress))
    }
  }

  return (
    <div
      className={classes.manageGuest}
      style={{
        background:
          event.theme.colourScheme || event.theme.darkMode
            ? event.theme.darkMode
              ? `${event.theme.colourScheme?.colorDarkButtonSecondary}60`
              : `${event.theme.colourScheme?.colorButtonSecondary}60`
            : undefined,
        color:
          event.theme.colourScheme || event.theme.darkMode
            ? theme.palette.text.primary
            : null,
      }}
    >
      <div className={classes.manageGuestTop}>
        <div className={classes.manageGuestTopLeft}>
          <img
            className={classes.avatar}
            src={getGuestAvatarUrls(unlinkedGuest ?? activeGuest)?.lg}
            alt='Guest Avatar'
          />
          <Typography
            variant='subtitle1'
            style={{ marginRight: 4, lineHeight: '16.71px' }}
          >
            {t('finishYourProfile')}
          </Typography>
        </div>
        <BashButton
          // size='small'
          // color='white'
          type={BashButtonType.WHITE_GREY_BORDER}
          height={32}
          extraView={<EmailOutlined />}
          viewAlignment={ButtonViewAlignment.ALIGN_TEXT_START}
          className={classes.verifyButton}
          loading={loading}
          onClick={onVerifyClicked}
        >
          {t('verifyItsYou')}
        </BashButton>
      </div>
      <Typography
        variant='body2'
        className={classes.body2High}
        color={
          event.theme.colourScheme || event.theme.darkMode
            ? theme.palette.text.tertiary
            : undefined
        }
      >
        {t('verifyGuestTo')}
      </Typography>
    </div>
  )
}

const BottomBox = ({}) => {
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const router = useRouter()
  const isLoggedIn = useIsLoggedIn()
  const activeGuest: UserGuestMinimalDto = useSelector(getActiveGuestWithoutNew)
  const event = useSelector(getCurrentEvent)

  const myTicketsClicked = () => {
    if (activeGuest?.code && !isLoggedIn) {
      router.push(`/events/${event.id}/tickets?gc=${activeGuest.code}`)
    } else {
      router.push(`/events/${event.id}/tickets`)
    }
  }

  const shareClicked = () => {
    dispatch(openModal('postRsvp'))
  }

  const checkTicketLinkClicked = async () => {
    window.open(event.myGuest?.joinOption?.url, '_blank')
  }

  const orders =
    activeGuest?.completedTicketOrders?.filter((order) => order.externalUrl) ??
    []

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  return (
    <>
      <JoinOptionMessage withMargin />
      <ManageGuestEmail />
      <div
        className={classes.joinContainer}
        style={{
          flexWrap: 'wrap',
          rowGap: 12,
        }}
      >
        {event.myGuest?.joinOption?.url && (
          <FlatButton
            color='primary'
            variant='outlined'
            className={classes.ticketLinkButton}
            endIcon={<OpenInNew className={classes.buttonIcon} />}
            onClick={checkTicketLinkClicked}
          >
            {t('goToTicketLink')}
          </FlatButton>
        )}
        {event.privacyType === 'PUBLIC' &&
          [RsvpStatus.GOING, RsvpStatus.JOINED].includes(
            event.myGuest?.status,
          ) && (
            <BashButton
              extraView={<Reply sx={{ transform: 'scaleX(-1)' }} />}
              viewAlignment={ButtonViewAlignment.ALIGN_TEXT_START}
              type={BashButtonType.WHITE_GREY_BORDER}
              onClick={shareClicked}
            >
              {t('share')}
            </BashButton>
          )}
        {(activeGuest?.ticketCount ?? 0) > 0 && (
          <BashIconButton
            type={BashButtonType.WHITE_GREY_BORDER}
            onClick={myTicketsClicked}
          >
            <LocalActivity className={classes.buttonIcon} />
          </BashIconButton>
        )}
        {orders.length === 1 && (
          <BashButton
            key={orders[0].externalUrl}
            extraView={<QrCode style={{ width: 18, height: 18 }} />}
            type={BashButtonType.WHITE_GREY_BORDER}
            onClick={() => window.open(orders[0].externalUrl, '_blank')}
            viewAlignment={ButtonViewAlignment.ALIGN_TEXT_START}
          >
            <Typography className={classes.buttonText}>
              {t('downloadTickets')}
            </Typography>
          </BashButton>
        )}

        <Row
          sx={{
            gap: 1.25,
          }}
        >
          <Box
            sx={{
              width: '1px',
              alignSelf: 'stretch',
              bgcolor: 'divider',
            }}
          />

          <Typography variant='body2'>
            <Trans
              t={t}
              i18nKey={'eventStartsIn'}
              components={{
                b: <b />,
              }}
              values={{
                count: getEventDateFromNow(event),
              }}
            />
          </Typography>
        </Row>

        {orders.length > 1 && (
          <>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
            >
              {orders.map((order) => (
                <MenuItem
                  key={order.externalUrl}
                  onClick={() => window.open(order.externalUrl, '_blank')}
                >
                  <div className={classes.menuItemContainer}>
                    <div className={classes.menuItem}>
                      <Typography variant='body1'>
                        {order.joinOption?.name ?? 'Order'}
                      </Typography>
                      {order.completedAt && (
                        <Typography variant='caption'>
                          {dayjs(order.completedAt).format('D MMM YYYY')}
                        </Typography>
                      )}
                    </div>
                    <OpenInNew className={classes.buttonIcon} />
                  </div>
                </MenuItem>
              ))}
            </Menu>
            <BashButton
              key={orders[0].externalUrl}
              extraView={<QrCode style={{ width: 18, height: 18 }} />}
              type={BashButtonType.WHITE_GREY_BORDER}
              onClick={(e) => setAnchorEl(e.currentTarget as HTMLElement)}
              viewAlignment={ButtonViewAlignment.ALIGN_TEXT_START}
            >
              <Typography className={classes.buttonText}>
                {t('downloadTickets')}
              </Typography>
            </BashButton>
          </>
        )}
      </div>
    </>
  )
}

const EventRsvpBox = ({ className }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation('common')
  const event = useSelector(getCurrentEvent)
  const theme = useTheme()
  const activeGuest: UserGuestMinimalDto | null = useSelector(
    getActiveGuestWithoutNew,
  )
  const [hideJoinBox, setHideJoinBox] = React.useState(false)
  const joinOptions = event.joinOptions?.filter(
    (j) => j.enabled !== false && (!j.errors || j.errors.length === 0),
  )
  const isLoggedIn = useIsLoggedIn()
  const hasBashTickets =
    event.hasJoinOptions || (joinOptions && joinOptions.length > 0)
  const ticketsAvailable = joinOptions?.some((e) => e.available) ?? false
  const hasTicket = (activeGuest?.ticketCount ?? 0) > 0
  const isGuestlistNoName =
    activeGuest &&
    activeGuest.status === 'JOINED' &&
    !activeGuest.user &&
    !activeGuest.name &&
    !isLoggedIn
  const hasExternalLink =
    event.externalTicketInfo != null || event.sourceUrl != null

  const isDone =
    (activeGuest?.waitingList === true && !ticketsAvailable) ||
    (activeGuest?.status === RsvpStatus.JOINED && !isGuestlistNoName) ||
    activeGuest?.status === RsvpStatus.REQUESTED ||
    activeGuest?.status === RsvpStatus.REQUEST_DENIED ||
    (activeGuest?.status === RsvpStatus.GOING && !hasBashTickets) ||
    event.unlinkedGuest

  const showPreviewToggle =
    event.host && event.myGuest?.status == null && event.hasJoinOptions

  const showInviterBox =
    ticketsAvailable &&
    event.invitedBy &&
    event.privacyType !== 'PUBLIC' &&
    !activeGuest?.waitingList &&
    (!activeGuest?.status ||
      activeGuest?.status === 'NONE' ||
      activeGuest?.status === RsvpStatus.MAYBE ||
      activeGuest?.status === RsvpStatus.CANT)

  // Datepoll
  if (event.type === 'PINNING') {
    return <PinningBox />
  }

  // Event is expired
  if (event.expired && !activeGuest?.status) {
    return (
      <div className={cx(className, classes.rsvpContainer)}>
        <TitleBox
          title={t('eventExpired')}
          subtitle={t('rsvpTitles.expired')}
        />
      </div>
    )
  }

  const getTitles = () => {
    if (isGuestlistNoName) {
      return {
        title: t('rsvpTitles.youreOnTheGuestlist'),
        subtitle: t('rsvpTitles.claimYourSpot'),
      }
    }
    if (
      hasTicket ||
      (!hasBashTickets && activeGuest?.status === RsvpStatus.GOING) ||
      // External ticket or registration info
      (event.myGuest?.status === 'JOINED' &&
        (event.myGuest?.joinOption?.url ||
          event.myGuest?.joinOption?.text ||
          event.myGuest?.completedTicketOrders?.length > 0))
    ) {
      if (isGuestlistNoName) {
        return {
          title: t('rsvpTitles.youreOnTheGuestlist'),
          subtitle: t('rsvpTitles.claimYourSpot'),
        }
      } else {
        return {
          title: t('rsvpTitles.youreAllSet'),
          subtitle: t('rsvpTitles.enjoyTheEvent'),
        }
      }
    }
    if (activeGuest?.status === RsvpStatus.CANT) {
      return {
        title: t('rsvpTitles.youCantJoin'),
        subtitle: t('rsvpTitles.changeYourResponse'),
      }
    }
    if (activeGuest?.status === RsvpStatus.MAYBE) {
      return {
        title: t('rsvpTitles.youMightJoin'),
        subtitle: t('rsvpTitles.changeYourResponse'),
      }
    }
    if (activeGuest?.status === RsvpStatus.REQUESTED) {
      return {
        title: t('rsvpTitles.requestPending'),
        subtitle: t('rsvpTitles.youGetNotifiedRequest'),
      }
    }
    if (activeGuest?.status === RsvpStatus.REQUEST_DENIED) {
      return {
        title: t('rsvpTitles.requestDenied'),
        subtitle: t('rsvpTitles.youCantJoin'),
      }
    }
    if (activeGuest?.waitingList === true && !ticketsAvailable) {
      return {
        title: t('rsvpTitles.youAreOnTheWaitlist'),
        subtitle: t('rsvpTitles.youWillGetNotifiedWhenTickets'),
      }
    }
    if (event.hasJoinOptions && (!joinOptions || joinOptions.length === 0)) {
      return {
        title: t('rsvpTitles.registrationIsClosed'),
        subtitle: t('rsvpTitles.joinWaitlistToStayUpdated'),
      }
    }
    return {
      title:
        hasExternalLink && !hasBashTickets
          ? t('external')
          : t('rsvpTitles.joinThisEvent'),
      subtitle: hasBashTickets
        ? t('rsvpTitles.secureYourSpot')
        : hasExternalLink
          ? t('rsvpTitles.noBashTicketsExternal')
          : t('rsvpTitles.noBashTickets'),
    }
  }

  const showBottomBox =
    event.privacyType === 'PUBLIC' ||
    (event.myGuest?.ticketCount ?? 0) > 0 ||
    event.myGuest?.joinOption?.url ||
    event.myGuest?.joinOption?.text

  return (
    <div
      className={cx(className, classes.rsvpContainer)}
      style={{
        background: event.theme.colourScheme
          ? theme.palette.background.secondary
          : undefined,
      }}
    >
      {showPreviewToggle && (
        <>
          <div
            className={classes.previewContainer}
            onClick={() => setHideJoinBox(!hideJoinBox)}
          >
            <Typography
              variant='body2'
              className={classes.messageToGuestItemText}
            >
              {t('previewHowYourGuestsJoin')}
            </Typography>
            {!hideJoinBox ? <ExpandLess /> : <ExpandMore />}
          </div>
          {!hideJoinBox && <Divider className={classes.divider} />}
        </>
      )}
      {!(showPreviewToggle && hideJoinBox) && (
        <>
          <TitleBox
            title={getTitles().title}
            subtitle={getTitles().subtitle}
            noTickets={!hasBashTickets && !activeGuest?.status}
          />
          {showInviterBox && (
            <>
              <Divider className={classes.divider} />
              <InviterBox />
            </>
          )}
          {!isDone && (hasExternalLink || hasBashTickets) && (
            <>
              <Divider className={classes.divider} />
              <JoinBox />
            </>
          )}
          {isDone && showBottomBox && (
            <>
              <Divider className={classes.divider} />
              <BottomBox />
            </>
          )}
        </>
      )}
    </div>
  )
}

export default EventRsvpBox
