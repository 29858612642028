import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Close } from '@mui/icons-material'
import { useDrag, useDrop } from 'react-dnd'
import { getVideoUrl } from 'shared-components/utils/media'

const useImageStyles = makeStyles()((theme) => ({
  imageItem: {
    position: 'relative',
    // overflow: 'hidden',
    height: 170,
    width: 170,
    // width: '100%',
    // height: 'auto',
    // border: `1px solid ${theme.palette.grey.main}`,
    objectFit: 'fill',
    transition: 'opacity 50ms ease',
    borderRadius: 8,
    cursor: 'grab',
    aspectRatio: '1.5',
    '&:hover': {
      opacity: 0.8,
    },
  },
  mediaImage: {
    width: 170,
    height: 170,
    objectFit: 'cover',
    borderRadius: 8,
  },
  mediaVideo: {
    width: 170,
    height: 170,
    objectFit: 'cover',
    borderRadius: 8,
  },
  idText: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    color: 'white',
  },
  checkContainer: {
    cursor: 'pointer',
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    color: 'white',
    border: '1px solid white',
    borderRadius: 100,
    display: 'flex',
    backgroundColor: 'rgba(0,0,0,0.3)',
    '& *': {
      width: 20,
      height: 20,
    },
  },
  closeIcon: {
    position: 'absolute',
    top: -8,
    right: -8,
    cursor: 'pointer',
    width: 16,
    height: 16,
    padding: 2,
    background: '#A6A6A6',
    borderRadius: 100,
    zIndex: 10,
    color: 'white',
  },
}))
const DraggableMediaItem = ({
  mediaItem,
  id,
  moveMediaItem,
  findMediaItem,
  onRemove,
  // onClick,
}) => {
  const { classes } = useImageStyles()

  const originalIndex = findMediaItem(id).index
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'MEDIA',
      item: { id, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item
        const didDrop = monitor.didDrop()
        if (!didDrop) {
          moveMediaItem(droppedId, originalIndex)
        }
      },
    }),
    [id, originalIndex, moveMediaItem],
  )
  const [, drop] = useDrop(
    () => ({
      accept: 'MEDIA',
      hover({ id: draggedId }) {
        if (draggedId !== id) {
          const { index: overIndex } = findMediaItem(id)
          moveMediaItem(draggedId, overIndex)
        }
      },
    }),
    [findMediaItem, moveMediaItem, id],
  )
  const opacity = isDragging ? 0 : 1

  return (
    <div
      style={{ opacity: opacity }}
      ref={(node) => drag(drop(node))}
      className={classes.imageItem}
      // onClick={onClick}
    >
      {mediaItem.type === 'VIDEO' && (
        // <div>
        <video
          className={classes.mediaVideo}
          src={getVideoUrl(mediaItem)}
          muted
        />
        // </div>
      )}
      {mediaItem.type === 'IMAGE' && (
        <img
          className={classes.mediaImage}
          src={mediaItem.content.imageUrls.lg}
          alt='image'
        />
      )}
      <Close className={classes.closeIcon} onClick={onRemove} />
      {/*<div className={classes.checkContainer}>*/}
      {/*  <CheckCircle style={{ opacity: mediaItem.removed ? 0 : 1 }} />*/}
      {/*</div>*/}
    </div>
  )
}

export default DraggableMediaItem
