import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Typography } from '@mui/material'

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: '4px 6px',
    background: theme.palette.mode === 'dark' ? '#5C1100' : '#FDECEC',
    borderRadius: 4,
    height: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
  },
  text: {
    color: theme.palette.mode === 'dark' ? '#FDECEC' : '#5C1100',
  },
}))

const BetaBadge = ({ className, ...props }) => {
  const { classes, cx } = useStyles()

  return (
    <div className={cx(classes.root, className)} {...props}>
      <Typography className={classes.text} variant='caption'>
        Beta
      </Typography>
    </div>
  )
}

const useProStyles = makeStyles()((theme) => ({
  root: {
    padding: '4px 8px',
    background: theme.palette.mode === 'dark' ? '#600BC1' : '#F3ECFD',
    borderRadius: 4,
    height: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
  },
  text: {
    color: theme.palette.mode === 'dark' ? '#F3ECFD' : '#600BC1',
  },
}))

export const ProBadge = ({ className }) => {
  const { classes, cx } = useProStyles()

  return (
    <div className={cx(classes.root, className)}>
      <Typography className={classes.text} variant='caption'>
        Pro
      </Typography>
    </div>
  )
}

export default BetaBadge
