import React from 'react'

const AppleIcon = (props) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.9484 7.28727V18.9301H9.0677V7.28727H12.9484ZM6.7477 11.4954L8.75718 18.9908L5.00948 19.9955L3 12.4957L6.7477 11.4954ZM17.2413 4L20.989 5.00474L16.9736 20L13.2259 18.9953L17.2413 4Z'
      fill='currentColor'
    />
  </svg>
)

export default AppleIcon
