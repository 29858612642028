import Column from '../Column'
import { Button, Collapse, Typography } from '@mui/material'
import { useState } from 'react'
import { ChevronRight } from '@mui/icons-material'
import { indexMobileBreakpoint } from '../../types/types'
import Row from '../Row'

interface Props {
  question: string
  answer: string
  listItems: string
}

const IndexFaqItem = ({ question, answer, listItems }: Props) => {
  const [open, setOpen] = useState(false)

  const listSplit = listItems.endsWith('.listItems')
    ? null
    : listItems.split('---')

  return (
    <Button
      sx={(theme) => ({
        borderRadius: open ? 4 : 5,
        background: '#FFFFFF80',
        border: '1.5px solid #F7F1FE',
        py: '26px',
        px: '40px',
        alignItems: 'flex-start',
        transition: 'border-radius 0.2s ease-in-out',
        [theme.breakpoints.down(indexMobileBreakpoint)]: {
          borderRadius: 1.75,
          py: 2.25,
          px: 1.75,
        },
      })}
      onClick={() => setOpen(!open)}
    >
      <Column
        sx={{
          alignItems: 'flex-start',
          flexGrow: 1,
          textAlign: 'left',
        }}
      >
        <Row
          sx={{
            width: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={(theme) => ({
              fontWeight: 500,
              [theme.breakpoints.down(indexMobileBreakpoint)]: {
                fontSize: '1rem',
                lineHeight: '1.1875rem',
              },
            })}
            variant='h6'
          >
            {question}
          </Typography>

          <ChevronRight
            sx={{
              color: 'secondary.800',
              transform: open ? 'rotate(-90deg)' : 'rotate(90deg)',
              transition: 'transform 0.2s ease-in-out',
            }}
          />
        </Row>
        <Collapse in={open}>
          <Column
            sx={{
              gap: 1,
            }}
          >
            <Typography
              sx={(theme) => ({
                pt: 1.5,
                fontSize: '1rem',
                lineHeight: '1.5rem',
                [theme.breakpoints.down(indexMobileBreakpoint)]: {
                  fontSize: '1rem',
                  lineHeight: '1.3rem',
                },
              })}
              variant='body1'
            >
              {answer}
            </Typography>

            {listSplit != null && (
              <Column
                sx={{
                  px: 1,
                }}
              >
                {listSplit.map((l) => (
                  <Typography
                    component='li'
                    sx={(theme) => ({
                      pt: 0,
                      fontSize: '1rem',
                      lineHeight: '1.5rem',
                      [theme.breakpoints.down(indexMobileBreakpoint)]: {
                        fontSize: '1rem',
                        lineHeight: '1.1875rem',
                      },
                    })}
                    variant='body1'
                  >
                    {l}
                  </Typography>
                ))}
              </Column>
            )}
          </Column>
        </Collapse>
      </Column>
    </Button>
  )
}

export default IndexFaqItem
