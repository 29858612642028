import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import BottomSheet from '../common/BottomSheet'
import ClickableAvatar from 'shared-components/avatar/ClickableAvatar'
import {
  CircularProgress,
  ListItemIcon,
  MenuItem,
  MenuList,
  SvgIcon,
  Tooltip,
  Typography,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import {
  Clear,
  Create,
  LocalActivity,
  Person,
  PersonAdd,
} from '@mui/icons-material'
import { mdiFormatListChecks } from '@mdi/js'
// import ManageAccounts from '@mui/icons-material/ManageAccounts'
import {
  getGuestName,
  guestHasName,
} from 'shared-components/utils/eventDisplay'
import {
  getCurrentEvent,
  getCurrentEventType,
  getIsOrganiser,
  getIsSuperAdmin,
  isHostedByPage,
} from 'selectors/event'
import apiClient from 'shared-components/utils/ApiClient'
import HostBadge from 'components/badge/HostBadge'
import { useRouter } from 'next/router'
import CommConfirmDialog from 'components/RsvpFlow/CommConfirmDialog'
import GuestAnswersSheet from './GuestAnswersSheet'
import GuestManagementChangeStatus, {
  updateStatusCounts,
} from './guestManagement/GuestManagementChangeStatus'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
  },
  topRow: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    '& > :not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  greyBoxTitle: {
    marginBottom: 4,
  },
  menuList: {
    margin: theme.spacing(1, -3, -3, -3),
    '& > li, * li': {
      whiteSpace: 'normal',
      fontWeight: 500,
      minHeight: 48,
      display: 'flex',
    },
  },
  grey: {
    color: theme.palette.text.disabled,
  },
  cohostContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    opacity: '1 !important',
    justifyContent: 'center',
  },
  cohostTitle: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  cohostSubtitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  spinner: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  colorTextPrimary: {
    color: theme.palette.text.primary,
  },
}))

const getCopyTransKey = (isOrganiser, isUser) => {
  if (isUser) return 'user'
  if (!isOrganiser) return 'guestNoOrganiser'
  return 'guestWithCommunication'
}

const GuestInfoSheet = ({ activeInfoGuest, open, setGuest, onClose }) => {
  const { classes } = useStyles()
  const dispatch = useDispatch()
  // const activeInfoGuest = useSelector((state) => state.modals.activeInfoGuest)
  // const open = useSelector((state) => state.modals.open.guestInfo)
  const patchGuestPending = useSelector(
    (state) => state.event.patchGuestPending,
  )
  const deletingGuestPending = useSelector(
    (state) => !!state.event.deletingGuestId,
  )
  const currentEventResponseType = useSelector(getCurrentEvent).responseType
  const currentEventType = useSelector(getCurrentEventType)
  const isSuperAdmin = useSelector(getIsSuperAdmin)
  const _isOrganiser = useSelector(getIsOrganiser)
  const isOrganiser = isSuperAdmin || _isOrganiser
  const hostedByPage = useSelector(isHostedByPage)
  const { t } = useTranslation('common')
  const router = useRouter()
  const event = useSelector(getCurrentEvent)

  const [friendRequestLoading, setFriendRequestLoading] = useState(false)
  const [newFollowStatus, setNewFollowStatus] = useState(null)
  const [cancelOpen, setCancelOpen] = useState(false)
  const [loadingCancel, setLoadingCancel] = useState(false)
  const [answersOpen, setAnswersOpen] = useState(false)
  const [localOwner, setLocalOwner] = useState(null)
  const [changeStatusOpen, setChangeStatusOpen] = useState(false)
  const [removeFromEventOpen, setRemoveFromEventOpen] = useState(false)
  const [loadingRemove, setLoadingRemove] = useState(false)

  if (activeInfoGuest == null) return null

  const isEventix = activeInfoGuest.joinOption?.idInfo?.source === 'EVENTIX'

  const isUser = !!activeInfoGuest.user

  const name = getGuestName(activeInfoGuest) ?? activeInfoGuest?.emailAddress
  const guestIsOrganiser =
    localOwner ??
    (activeInfoGuest.role === 'ADMIN' || activeInfoGuest.role === 'OWNER')
  const loading =
    patchGuestPending || deletingGuestPending || friendRequestLoading

  const onClickChangeStatus = (event) => {
    setChangeStatusOpen(true)
  }

  const addFriend = async () => {
    setFriendRequestLoading(true)
    try {
      // await apiClient.user.addFriends([{ ofUser: { id: activeInfoGuest.user.id } }])
      await apiClient.user.addFriend(activeInfoGuest.user.id)
      setNewFollowStatus('REQUEST_SENT')
    } catch (e) {
      console.log(e)
    }
    setFriendRequestLoading(false)
  }

  const getFollowString = () => {
    if (newFollowStatus !== null) {
      return newFollowStatus === 'REQUEST_SENT' ? t('requested') : t('friends')
    } else {
      switch (activeInfoGuest.user.friendStatus) {
        case null:
        case 'NONE':
          return t('addFriend')
        case 'REQUEST_SENT':
          return t('requested')
        case 'FRIEND':
          return t('friends')
        default:
          return t('addFriend')
      }
    }
  }

  const onCancelTicketConfirm = async () => {
    setLoadingCancel(true)
    try {
      const result = await apiClient.tickets.revokeTicket(
        activeInfoGuest.tickets?.map((e) => e.id),
      )
      setCancelOpen(false)
      window.location.reload()
    } catch (e) {
      dispatch(setSnackbar('error', t('somethingWentWrong')))
    }
  }

  const onRemoveFromEventConfirm = async () => {
    setLoadingRemove(true)
    await apiClient.guest.delete(activeInfoGuest.id)
    // dispatch(
    //   deleteGuest(activeInfoGuest.id, activeInfoGuest.status),
    // )
    setRemoveFromEventOpen(false)
    setLoadingRemove(false)
    updateStatusCounts(
      event,
      dispatch,
      activeInfoGuest,
      activeInfoGuest.status,
      null,
    )
    setGuest({
      ...activeInfoGuest,
      status: null,
    })
    onClose()
  }

  const content = (
    <>
      <div className={classes.topRow}>
        <ClickableAvatar disableClick userOrGuest={activeInfoGuest} size={48} />
        <Typography variant='subtitle1'>{name}</Typography>
        {guestIsOrganiser && <HostBadge />}
        {loading && <CircularProgress size={32} className={classes.spinner} />}
      </div>
      {isOrganiser && (
        <MenuList className={classes.menuList}>
          {/*<Divider />*/}
          {currentEventType !== 'PINNING' &&
            activeInfoGuest.status !== 'REQUESTED' &&
            guestHasName(activeInfoGuest) && (
              <>
                <MenuItem
                  disabled={patchGuestPending}
                  onClick={onClickChangeStatus}
                >
                  <ListItemIcon>
                    <Create className={classes.colorTextPrimary} />
                  </ListItemIcon>
                  {t('guestInfoSheet.buttons.changeStatus')}
                </MenuItem>
                {/*<Divider />*/}
              </>
            )}
          {isUser && (
            <>
              <MenuItem
                onClick={() => router.push(`/users/${activeInfoGuest.user.id}`)}
              >
                <ListItemIcon>
                  <Person className={classes.colorTextPrimary} />
                </ListItemIcon>
                {t('guestInfoSheet.buttons.viewProfile')}
              </MenuItem>
              {/*<Divider />*/}
              <MenuItem
                onClick={addFriend}
                disabled={
                  activeInfoGuest.user.friendStatus !== 'NONE' ||
                  newFollowStatus !== null
                }
              >
                <ListItemIcon>
                  <PersonAdd className={classes.colorTextPrimary} />
                </ListItemIcon>
                {getFollowString()}
                {/* {t('guestInfoSheet.buttons.addFriend')} */}
              </MenuItem>
              {/*<Divider />*/}
            </>
          )}

          {activeInfoGuest.questionsCompleted && (
            <>
              <MenuItem onClick={() => setAnswersOpen(true)}>
                <ListItemIcon>
                  <SvgIcon className={classes.colorTextPrimary}>
                    <path d={mdiFormatListChecks} />
                  </SvgIcon>
                </ListItemIcon>
                {t('questions.seeAnswers')}
              </MenuItem>
              <GuestAnswersSheet
                onClose={() => setAnswersOpen(false)}
                guest={activeInfoGuest}
                setGuest={setGuest}
                open={answersOpen}
              />
              {/*<Divider />*/}
            </>
          )}

          {(!activeInfoGuest.totalTicketCount ||
            activeInfoGuest.totalTicketCount === 0) &&
            activeInfoGuest.status !== 'REQUESTED' && (
              <>
                <MenuItem onClick={() => setRemoveFromEventOpen(true)}>
                  <ListItemIcon>
                    <Clear color='error' />
                  </ListItemIcon>
                  {t('guestInfoSheet.buttons.removeFromEvent')}
                </MenuItem>
                <CommConfirmDialog
                  open={removeFromEventOpen}
                  onClose={() => setRemoveFromEventOpen(false)}
                  onConfirm={onRemoveFromEventConfirm}
                  loading={loadingRemove}
                  title={t('removeFromEventTitle')}
                  subtitle={t('removeFromEventText')}
                  cancelText={t('cancelTicket.no')}
                  confirmText={t('removeFromEventButton')}
                />
              </>
            )}

          {!!activeInfoGuest.totalTicketCount &&
            activeInfoGuest.totalTicketCount > 0 && (
              <div>
                <Tooltip
                  title={
                    isEventix
                      ? 'Cancel tickets through the Eventix dashboard'
                      : ''
                  }
                >
                  <span>
                    <MenuItem
                      disabled={isEventix}
                      onClick={() => setCancelOpen(true)}
                    >
                      <ListItemIcon>
                        <LocalActivity color='error' />
                      </ListItemIcon>
                      {t('cancelTicket.cancelGuestTickets') +
                        ` (${activeInfoGuest.totalTicketCount})`}
                    </MenuItem>
                  </span>
                </Tooltip>
                <CommConfirmDialog
                  open={cancelOpen}
                  onClose={() => setCancelOpen(false)}
                  onConfirm={() => {
                    onCancelTicketConfirm()
                  }}
                  loading={loadingCancel}
                  title={t('cancelTicket.doYouWantToCancel')}
                  subtitle={t('cancelTicket.cancelWillRemove')}
                  cancelText={t('cancelTicket.no')}
                  confirmText={
                    t('cancelTicket.cancelTickets') +
                    ` (${activeInfoGuest.totalTicketCount})`
                  }
                />
              </div>
            )}
        </MenuList>
      )}
    </>
  )

  return (
    <BottomSheet
      className={classes.root}
      open={open}
      title={changeStatusOpen ? getGuestName(activeInfoGuest) : null}
      onClose={changeStatusOpen ? undefined : onClose}
      onBack={changeStatusOpen ? () => setChangeStatusOpen(false) : undefined}
    >
      {changeStatusOpen && (
        <GuestManagementChangeStatus
          onBack={() => setChangeStatusOpen(false)}
          guest={activeInfoGuest}
          setGuest={setGuest}
        />
      )}
      {!changeStatusOpen && content}
    </BottomSheet>
  )
}

export default GuestInfoSheet
