import React, { useRef, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentEvent } from '../../selectors/event'
import { SvgIcon, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@mui/material/useMediaQuery'
import FlatButton from '../../shared-components/buttons/FlatButton'
import { openSignInModal } from '../../actions/modals'
import VerifiedIcon from '../../svg/CheckDecagram'
import EventSection from './EventSection'
import BottomSheet from '../common/BottomSheet'
import { useRouter } from 'next/router'
import HostCard from 'components/Page/HostCard'
import { useIsLoggedIn } from 'utils/hooks'
import { followOrganisationId, unfollow } from 'actions/user'
import { setSnackbar } from 'shared-components/redux/notifications/actions'

const useStyles = makeStyles()((theme) => ({
  viewAll: {
    marginTop: '-6px',
  },
  hostsContainer: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'auto',
    gap: theme.spacing(1),
    ' -ms-overflow-style': 'none' /* IE and Edge */,
    scrollbarWidth: 'none',
    '& :-webkit-scrollbar': {
      display: 'none',
    },
  },
  hostItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    gap: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  hostImage: {
    width: 40,
    height: 40,
    borderRadius: '100px',
  },
  hostName: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  modalHostsContainer: {
    overflowY: 'auto',
  },
  root: {
    border: '1px solid ' + theme.palette.grey[200],
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    cursor: 'pointer',
    gap: theme.spacing(1),
    padding: theme.spacing(1, '10px'),
    height: '42px',
  },
  avatar: {
    width: '32px',
    height: '32px',
    borderRadius: '100px',
  },
  name: {
    fontWeight: 500,
    maxWidth: '170px',
    lineHeight: '1rem',
  },
  verifiedIcon: {
    width: 16,
    height: 16,
  },
  button: {
    marginTop: theme.spacing(2),
  },
}))

const EventLineUpCard = ({ desktopMode }) => {
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const event = useSelector(getCurrentEvent)
  const router = useRouter()
  const { t } = useTranslation('common')
  const scrollRef = useRef(null)
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const [allOpen, setAllOpen] = useState(false)

  const isLoggedIn = useIsLoggedIn()
  const [localFollowStatus, setLocalFollowStatus] = useState([])

  if (!event.artists || event.artists.length === 0) return null

  const onFollowClick = (host) => {
    if (isLoggedIn) {
      if (localFollowStatus.includes(host.model?.id)) {
        dispatch(unfollow(host.model?.id))
        setLocalFollowStatus(
          localFollowStatus.filter((id) => id !== host.model?.id),
        )
      } else {
        dispatch(followOrganisationId(host.model?.id))
        dispatch(
          setSnackbar(
            'success',
            t('shared:followingOrg', { org: host.model?.name }),
          ),
        )
        setLocalFollowStatus([...localFollowStatus, host.model?.id])
      }
    } else {
      router.replace({
        query: { ...router.query, redirectTo: `/${host.model?.username}` },
      })
      dispatch(openSignInModal(null, host.model))
    }
  }

  const onArtistClick = (artist) => {
    router.push(`/pages/${artist.id}`)
  }

  return (
    <>
      <EventSection
        title={t('shared:lineup')}
        onMoreClicked={(e) => {
          setAllOpen(true)
        }}
        desktopMode={desktopMode}
      >
        <div className={classes.hostsContainer} ref={scrollRef}>
          {event.artists
            ?.filter((e) => !e.hidden)
            .map((artist) => (
              <HostCard
                key={artist.id}
                host={{
                  type: 'ORGANISATION',
                  model: artist,
                }}
                onFollowClick={onFollowClick}
                following={localFollowStatus.includes(artist.id)}
                hasMultiple={event.artists.length > 1}
              />
            ))}
        </div>
      </EventSection>
      <BottomSheet
        alignStart
        title={t('shared:lineup')}
        open={allOpen}
        onClose={() => setAllOpen(false)}
      >
        <div className={classes.modalHostsContainer}>
          {event.artists
            ?.filter((e) => !e.hidden)
            .map((host) => (
              <div
                onClick={() => onArtistClick(host)}
                key={host.id}
                className={classes.hostItem}
              >
                {host.avatarUrls || host.user?.avatarUrls ? (
                  <img
                    className={classes.hostImage}
                    src={
                      host.avatarUrls?.sm ??
                      host.user?.avatarUrls?.sm ??
                      host.avatarUrls?.lg ??
                      host.user?.avatarUrls?.lg
                    }
                  />
                ) : (
                  <div className={classes.hostImage} />
                )}
                <Typography className={classes.hostName} variant='subtitle2'>
                  {host.name ?? host.user?.name}
                  {host.verified && (
                    <SvgIcon
                      component={VerifiedIcon}
                      className={classes.verifiedIcon}
                      color='primary'
                    />
                  )}
                </Typography>

                {host.type === 'ORGANISATION' && (
                  <FlatButton
                    className={classes.button}
                    size='small'
                    color='grey'
                    onClick={onFollowClick}
                  >
                    {host.followStatus !== 'ACTIVE' && t('follow')}
                    {host.followStatus === 'ACTIVE' && t('following')}
                  </FlatButton>
                )}
              </div>
            ))}
        </div>
      </BottomSheet>
    </>
  )
}

export default EventLineUpCard
