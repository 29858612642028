import React from 'react'
import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import TextInput from 'shared-components/common/TextInput'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputContainer: {
    margin: theme.spacing(1, 0),
  },
  input: {
    borderRadius: 6,
    height: 40,
  },
  full: {
    flex: 1,
  },
  title: {
    fontWeight: 500,
  },
  helperText: {
    color: theme.palette.red.main,
  },
}))

const WizardTextField = ({
  title,
  helperText,
  onSubmit,
  className,
  noMargin,
  onClick,
  full,
  ...other
}) => {
  const { classes, cx } = useStyles()
  return (
    <div
      className={cx(classes.root, className, { [classes.full]: full })}
      onClick={onClick}
    >
      {title && (
        <Typography className={classes.title} variant='body2'>
          {title}
        </Typography>
      )}
      <TextInput
        customClass={!noMargin && classes.inputContainer}
        className={classes.input}
        onKeyPress={(event) => {
          if (event.key === 'Enter' && onSubmit) onSubmit()
        }}
        fullWidth
        {...other}
      />
      <Typography variant='caption' className={classes.helperText}>
        {helperText}
      </Typography>
    </div>
  )
}

export default WizardTextField
