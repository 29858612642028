import Column from '../Column'
import TextLogo from '../../svg/TextLogo'
import Typography from '@mui/material/Typography'
import { gintoHeaderFontFamily } from '../../shared-components/typography'
import { Box, SxProps } from '@mui/material'
import Row from '../Row'
import BashButton, {
  BashButtonType,
  ButtonViewAlignment,
} from '../../shared-components/buttons/BashButton'
import { Add } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import { Theme } from '@mui/material/styles'
import Link from 'next/link'
import { indexMobileBreakpoint } from '../../types/types'
import { useTranslation } from 'react-i18next'

const Lottie = dynamic(() => import('react-lottie'), { ssr: false })

interface Props {
  sx?: SxProps<Theme> | undefined
}

const IndexTopSection = ({ sx = [] }: Props) => {
  const { t } = useTranslation('common')
  const [animationData, setAnimationData] = useState(null)

  const getAnimationData = async () => {
    const result = await fetch(
      'https://cdn.lottielab.com/l/8gA832WaeEaFqc.json',
    )
    const data = await result.json()
    setAnimationData(data)
  }

  useEffect(() => {
    getAnimationData()
  }, [])

  return (
    // Wrapping row
    <Row
      sx={[
        {
          width: 1,
          alignItems: 'flex-start',
          minHeight: 'calc(100vh - 152px)', // Full height minus header
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Row
        sx={(theme) => ({
          alignItems: 'center',
          justifyContent: 'space-between',
          // gap: 10,
          mt: '12%',
          flexWrap: 'wrap-reverse',
          width: 1,
          gap: 8,
          rowGap: 0,
          pb: 3,
          [theme.breakpoints.down(indexMobileBreakpoint)]: {
            justifyContent: 'center',
            mt: 0,
          },
        })}
      >
        <Column
          sx={{
            gap: 3,
            maxWidth: 480,
            flexGrow: 1,
            flexBasis: 300,
          }}
        >
          <TextLogo />
          <Typography
            sx={(theme) => ({
              fontFamily: gintoHeaderFontFamily,
              fontSize: '3rem',
              fontWeight: 800,
              lineHeight: '2.7rem',
              letterSpacing: '-2%',
              [theme.breakpoints.down(indexMobileBreakpoint)]: {
                fontSize: '2rem',
                lineHeight: '1.8rem',
              },
            })}
            variant='h1'
          >
            WHERE{' '}
            <Box
              sx={{
                color: 'secondary.main',
              }}
              component='span'
            >
              HYPE
            </Box>{' '}
            STARTS
          </Typography>

          <Typography
            sx={(theme) => ({
              fontWeight: 400,
              fontSize: '1.125rem',
              lineHeight: '1.6rem',
              letterSpacing: '0.5px',
              [theme.breakpoints.down(indexMobileBreakpoint)]: {
                fontSize: '1rem',
                lineHeight: '1.5rem',
              },
            })}
            variant='h6'
          >
            {t('index.hero')}
          </Typography>

          <Row
            sx={(theme) => ({
              alignItems: 'center',
              gap: 2,
              mt: 0.875,
              flexShrink: 0,
              [theme.breakpoints.down(indexMobileBreakpoint)]: {
                flexWrap: 'wrap',
              },
            })}
          >
            <Link href='/create' passHref prefetch={false}>
              <BashButton
                sx={(theme) => ({
                  whiteSpace: 'nowrap',
                  [theme.breakpoints.down(indexMobileBreakpoint)]: {
                    px: 1.5,
                    height: 40,
                    '& > span': {
                      fontSize: '0.75rem',
                    },
                  },
                })}
                height={48}
                extraView={
                  <Add
                    sx={(theme) => ({
                      [theme.breakpoints.down(indexMobileBreakpoint)]: {
                        display: 'none',
                      },
                    })}
                  />
                }
                viewAlignment={ButtonViewAlignment.ALIGN_TEXT_START}
                type={BashButtonType.SECONDARY}
                onClick={undefined}
              >
                {t('index.createFirstEvent')}
              </BashButton>
            </Link>
          </Row>
        </Column>

        <Box
          sx={(theme) => ({
            minWidth: 318,
            maxWidth: 556,
            flexBasis: 318,
            flexGrow: 1,
            [theme.breakpoints.down(indexMobileBreakpoint)]: {
              maxWidth: 1,
              flexBasis: 500,
            },
            // flexShrink: 0,
            // maxWidth: 700,
            '& > div > svg >  g:last-of-type > g:not(:first-of-type):last-of-type > g:first-of-type:last-of-type':
              {
                display: 'none !important',
              },
          })}
        >
          {animationData && (
            <Lottie
              // @ts-expect-error wrong props because of dynamic import
              isClickToPauseDisabled={true}
              options={{
                animationData: animationData,
                // animationData: 'https://cdn.lottielab.com/l/8gA832WaeEaFqc.json',
                autoplay: true,
                loop: false,
              }}
            />
          )}
        </Box>
      </Row>
    </Row>
  )
}

export default IndexTopSection
