import {
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Theme,
  Typography,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { ExpandLess, ExpandMore, MoreVert, PushPin } from '@mui/icons-material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  openDateOptionResponses,
  openPinDateModal,
} from '../../../actions/modals'
import {
  closeAndResetRsvpModal,
  openRsvpOnStep,
  rsvp,
} from '../../../actions/rsvp'
import {
  getActiveGuestWithoutNew,
  getCurrentEvent,
} from '../../../selectors/event'
import FlatButton from '../../../shared-components/buttons/FlatButton'
import { UserGuestMinimalDto } from '../../../types/types'
import { InvitesYou } from '../EventRsvpBox'
import { DateOption, DateOptionResponses } from './DateOption'

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    alignItems: 'center',
    margin: theme.spacing(0, 0),
    padding: 0,
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  iconContainerOverlay: {
    zIndex: 2,
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    background: 'black',
    opacity: '0',
    transition: 'opacity 0.15s linear',
    borderRadius: 100,
  },
  shimmer: {},
  inviterContainer: {
    padding: theme.spacing(2),
  },
  rsvpContainer: {
    background: theme.palette.grey[100],
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(1),
    border: '1px solid ' + theme.palette.grey[200],
    width: '100%',
  },
  nextButton: {
    flex: 1,
  },
  messageToGuestItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    padding: theme.spacing(2),
    border: '1px solid ' + theme.palette.grey[200],
    borderRadius: '8px',
    background: theme.palette.grey[150],
    margin: theme.spacing(2),
  },
  messageToGuestItemText: {
    lineHeight: '21px',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2),
    justifyContent: 'space-between',
  },
  headerTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  // dateoptions
  dateOptionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    gap: theme.spacing(1),
  },
  dateOptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  buttonContainer: {
    display: 'flex',
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  overflowButton: {
    marginTop: theme.spacing(-1),
    marginRight: theme.spacing(-1),
  },
  showAllContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    cursor: 'pointer',
  },
}))

export const PinningBox = ({}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const event = useSelector(getCurrentEvent)
  const activeGuest: UserGuestMinimalDto = useSelector(getActiveGuestWithoutNew)
  const userId = useSelector((state) => state.user.user.id)
  const isLoggedIn = !!userId

  const [showAll, setShowAll] = React.useState(false)
  const [editingResponse, setEditingResponse] = React.useState(false)

  const [overFlowMenuAnchor, setOverFlowMenuAnchor] =
    React.useState<null | HTMLElement>(null)

  const showResponses = !editingResponse && activeGuest?.gaveDateOptions!

  const rsvpPending = useSelector((state) => state.event.rsvpPending)

  const dateOptions = event?.dateOptions ?? []
  const showSeeAll = dateOptions.length > 5
  const limit = showAll ? dateOptions.length : showSeeAll ? 4 : 5

  const respondPinningClicked = async () => {
    // trackEvent('RSVP')
    if (event.myGuest != null || isLoggedIn) {
      await dispatch(
        rsvp(isLoggedIn ? null : event.myGuest?.code, 'web_event_page'),
      )
      setEditingResponse(false)
      dispatch(closeAndResetRsvpModal())
    } else {
      dispatch(openRsvpOnStep('info'))
    }
  }

  return (
    <div className={cx(classes.rsvpContainer)}>
      {/* <div className={cn(classes.root)}> */}
      <div className={classes.headerContainer}>
        <div className={classes.headerTextContainer}>
          <Typography variant='subtitle1'>
            <b>
              {showResponses ? t('dateOptions') : t('giveYourAvailability')}
            </b>
          </Typography>
          <Typography variant='body2'>
            {t('youWillBeNotifiedAboutAnyUpdates')}
          </Typography>
        </div>
        <IconButton
          className={classes.overflowButton}
          onClick={(e) => {
            setOverFlowMenuAnchor(e.currentTarget)
          }}
          size='large'
        >
          <MoreVert />
        </IconButton>
        <Menu
          id='more-menu'
          anchorEl={overFlowMenuAnchor}
          open={!!overFlowMenuAnchor}
          onClose={() => setOverFlowMenuAnchor(null)}
          disableAutoFocusItem
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <MenuItem
            onClick={() => {
              setEditingResponse(true)
              setOverFlowMenuAnchor(null)
            }}
          >
            <Typography variant='body2'>{t('editAvailability')}</Typography>
          </MenuItem>
        </Menu>
      </div>
      <Divider />
      {!showResponses && event.invitedBy != null && (
        <>
          <div className={classes.inviterContainer}>
            <InvitesYou />
          </div>
          <Divider />
        </>
      )}
      <div className={classes.dateOptionsContainer}>
        {dateOptions
          .slice(0, limit)
          .map((dateOption) =>
            showResponses ? (
              <DateOptionResponses
                onClick={() => dispatch(openDateOptionResponses(dateOption.id))}
                key={dateOption.id || dateOption.localId}
                dateOption={dateOption}
              />
            ) : (
              <DateOption
                key={dateOption.id || dateOption.localId}
                dateOption={dateOption}
              />
            ),
          )}
        {showSeeAll && (
          <div
            className={classes.showAllContainer}
            onClick={() => setShowAll(!showAll)}
          >
            <Typography variant='button'>
              {showAll ? t('showLess') : t('showAll')}
            </Typography>
            {showAll ? <ExpandLess /> : <ExpandMore />}
          </div>
        )}
      </div>
      {!showResponses && (
        <div className={classes.buttonContainer}>
          <FlatButton
            className={classes.nextButton}
            onClick={respondPinningClicked}
            disabled={rsvpPending}
            fullWidth
            color='secondary'
          >
            {/* {t('send')} */}
            {t(activeGuest?.gaveDateOptions ? 'update' : 'send')}
          </FlatButton>
        </div>
      )}
      {showResponses && event.host && (
        <div className={classes.buttonContainer}>
          <FlatButton
            className={classes.nextButton}
            onClick={() => dispatch(openPinDateModal())}
            disabled={rsvpPending}
            fullWidth
            color='primaryLight'
          >
            <PushPin />
            {t('pinDate')}
          </FlatButton>
        </div>
      )}
    </div>
  )
}
