import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import mixpanel from 'shared-components/utils/mixpanel'

import dayjs from 'dayjs'
import 'dayjs/locale/nl'
import updateLocale from 'dayjs/plugin/updateLocale'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import localeData from 'dayjs/plugin/localeData'
import isBetween from 'dayjs/plugin/isBetween'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'

import commonNl from 'translations/nl/common.json'
import commonEn from 'translations/en/common.json'
import userNl from 'translations/nl/user.json'
import userEn from 'translations/en/user.json'
import eventEn from 'translations/en/event.json'
import eventNl from 'translations/nl/event.json'
import siteEn from 'translations/en/site.json'
import siteNl from 'translations/nl/site.json'
import sharedNl from 'shared-components/translations/nl/shared.json'
import sharedEn from 'shared-components/translations/en/shared.json'

dayjs.extend(updateLocale)
dayjs.extend(localizedFormat)
dayjs.extend(localeData)
dayjs.extend(isBetween)
dayjs.extend(relativeTime)
dayjs.extend(utc)

dayjs.updateLocale('en', {
  // Sunday = 0, Monday = 1.
  weekStart: 1,
})

const getDefaultLanguage = () => {
  let lang = 'en'
  // eslint-disable-next-line
  let rest

  if (typeof navigator !== 'undefined') {
    if (navigator.languages && navigator.languages.length) {
      // eslint-disable-next-line
      ;[lang, ...rest] = navigator.languages
    } else if (navigator.userLanguage) {
      lang = navigator.userLanguage
    } else if (navigator.language) {
      lang = navigator.language
    }
  }

  lang = lang.substr(0, 2).toLowerCase()
  if (['en', 'nl'].indexOf(lang) > -1) {
    return lang
  }

  return 'en'
}

export const defLang = getDefaultLanguage()

i18n.use(initReactI18next).init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: defLang, // language to use
  resources: {
    en: {
      common: commonEn,
      user: userEn,
      shared: sharedEn,
      event: eventEn,
      site: siteEn,
    },
    nl: {
      common: commonNl,
      user: userNl,
      shared: sharedNl,
      event: eventNl,
      site: siteNl,
    },
  },
})

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '%ds',
    ss: '%ds',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1mo',
    MM: '%dmo',
    y: 'y',
    yy: '%dy',
  },
})

dayjs.updateLocale('nl', {
  relativeTime: {
    future: 'over %s',
    past: '%s geleden',
    s: '%ds',
    ss: '%ds',
    m: '1m',
    mm: '%dm',
    h: '1u',
    hh: '%du',
    d: '1d',
    dd: '%dd',
    M: '1ma',
    MM: '%dma',
    y: 'j',
    yy: '%dj',
  },
})

dayjs.locale(defLang)

// mixpanel.register({ 'Language Served': defLang })
if (typeof document !== 'undefined') {
  document.changeLanguage = (lan) => {
    i18n.changeLanguage(lan)
    dayjs.locale(lan)
    mixpanel.register({ 'Language Served': lan })
    mixpanel.track('Change Language')
  }
}

export default i18n
