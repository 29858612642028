import mixpanel from 'mixpanel-browser'
import { createSelector } from 'reselect'

const eventProperties = createSelector(
  (event) => event,
  (event) => {
    let properties = {
      'Bash ID': event.id,
      'Bash Event Type': event.type,
      'Bash Number of Guests': event.statusCounts
        ? Object.values(event.statusCounts).reduce((a, b) => a + b)
        : 0,
      'BASH TBA': !!event.toBeAnnounced,
      'Guests Can See Guests': event.guestsCanSeeGuests,
      'Guests Can Invite Guests': event.guestsCanInvite,
    }
    if (event.organisation) {
      properties = {
        ...properties,
        'Bash Organisation ID': event.organisation.id,
      }
    } else {
      properties = {
        ...properties,
        'Bash Organiser ID': event.hosts
          ? event.hosts[0].model.user.id
          : event.organisers[0].id,
      }
    }
    return properties
  },
)

const organisationProperties = (organisation) => ({
  'Organisation ID': organisation.id,
  'Org Event Count': organisation.eventCount,
  'Org Follower Count': organisation.followerCount,
  'Org Guest Count': organisation.guestCount,
  'Org Approval Status': organisation.verified,
  'Org Private Profile': organisation.privateProfile,
})

let temp = true
if (typeof window !== 'undefined') {
  if (window.localStorage && window.localStorage.getItem('cookies') === 'true')
    temp = false
}

mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN, {
  debug: false,
  api_host: 'https://analytics.bash.social',
  opt_out_tracking_by_default: temp,
})

mixpanel.identifyUserId = (userId) => mixpanel.identify(userId)

mixpanel.addGuestToList = (guestId) => mixpanel.people.union('guests', guestId)

mixpanel.trackEvent = (name, event, extraProperties = {}) => {
  let properties
  if (event) {
    properties = eventProperties(event)
  }
  mixpanel.track(name, { ...properties, ...extraProperties })
}

mixpanel.trackOrg = (name, organisation, extraProperties = {}) => {
  let properties
  if (organisation) {
    properties = organisationProperties(organisation)
  }
  mixpanel.track(name, { ...properties, ...extraProperties })
}

export default mixpanel
