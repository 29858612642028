import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Box, Typography, useTheme } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import PagesCard from '../Pages/PagesCard'
import useMediaQuery from '@mui/material/useMediaQuery'
import { gintoHeaderFontFamily } from '../../shared-components/typography'
import Row from '../Row'
import Column from '../Column'
import BashButton, {
  BashButtonType,
} from '../../shared-components/buttons/BashButton'
import Link from 'next/link'
import { indexMobileBreakpoint } from '../../types/types'

const useStyles = makeStyles()((theme) => ({
  twoPages: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      gap: theme.spacing(1.5),
    },
  },
  marginTop3: {
    marginTop: theme.spacing(3),
  },
  stat: {
    width: '100%',
    maxWidth: 265,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(indexMobileBreakpoint)]: {
      width: '30%',
    },
  },
  statText: {
    fontSize: '12px',
    lineHeight: '14.32px',
    fontWeight: 800,
    letterSpacing: '0.15px',
    [theme.breakpoints.down(indexMobileBreakpoint)]: {
      fontSize: '10.08px',
      lineHeight: '12.03px',
      fontWeight: 700,
      letterSpacing: '0.13px',
    },
  },
  statNumber: {
    fontSize: '1.875rem',
    lineHeight: '2.25rem',
    fontWeight: 700,
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
      lineHeight: '1.75rem',
    },
  },
}))

const IndexPages = ({ pages }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation('common')
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

  if (!pages || pages.length === 0) return null

  const stats = (
    <Row
      sx={(theme) => ({
        alignItems: 'center',
        justifyContent: 'center',
        width: 1,
        mb: 6,
        py: 3,
        background: '#FFFFFF80',
        [theme.breakpoints.down(indexMobileBreakpoint)]: {
          flexWrap: 'wrap',
          gap: 4,
          width: 'auto',
        },
      })}
    >
      <div className={classes.stat}>
        <Typography className={classes.statText} variant='caption'>
          {t('activeUsers').toUpperCase()}
        </Typography>
        <Typography variant='h6' className={classes.statNumber}>
          130K+
        </Typography>
      </div>

      <div className={classes.stat}>
        <Typography className={classes.statText} variant='caption'>
          {t('weeklyEvents').toUpperCase()}
        </Typography>
        <Typography variant='h6' className={classes.statNumber}>
          3000+
        </Typography>
      </div>
      <div className={classes.stat}>
        <Typography className={classes.statText} variant='caption'>
          {t('organisers').toUpperCase()}
        </Typography>
        <Typography variant='h6' className={classes.statNumber}>
          1400+
        </Typography>
      </div>
      <div className={classes.stat}>
        <Typography className={classes.statText} variant='caption'>
          {t('ticketsSold').toUpperCase()}
        </Typography>
        <Typography variant='h6' className={classes.statNumber}>
          30K+
        </Typography>
      </div>
    </Row>
  )

  return (
    <Column
      sx={(theme) => ({
        gap: 12.5,
        overflow: 'hidden',
        width: 1,
        justifyContent: 'center',
        pb: 3,
        pt: 20,
        [theme.breakpoints.down(indexMobileBreakpoint)]: {
          gap: 10,
          pt: 7,
        },
      })}
    >
      <Column
        sx={{
          gap: 6,
          alignItems: 'center',
        }}
      >
        <Typography
          variant='h2'
          sx={(theme) => ({
            fontSize: '2rem',
            lineHeight: '1.8rem',
            letterSpacing: '-0.5px',
            fontFamily: gintoHeaderFontFamily,
            fontWeight: 800,
            textAlign: 'center',
            textTransform: 'uppercase',
            px: 3,
            [theme.breakpoints.down(indexMobileBreakpoint)]: {
              fontSize: '1.5rem',
              lineHeight: '1.5rem',
            },
          })}
        >
          <Trans
            t={t}
            i18nKey={'index.joinTheFuture'}
            components={{
              b: (
                <Box
                  component={'span'}
                  sx={{
                    color: 'secondary.main',
                  }}
                />
              ),
            }}
          />
        </Typography>

        <Link href='/create' passHref prefetch={false}>
          <BashButton
            height={48}
            type={BashButtonType.SECONDARY}
            onClick={undefined}
          >
            {t('index.createFirstEvent')}
          </BashButton>
        </Link>
      </Column>
      {stats}
      <Row
        sx={(theme) => ({
          alignSelf: 'center',
          display: 'flex',
          gap: 2,
          mb: 6,
          [theme.breakpoints.down('lg')]: {
            gap: 1.5,
          },
          [theme.breakpoints.down('md')]: {
            transform: 'translateX(70px)',
          },
        })}
      >
        <div className={cx(classes.twoPages, classes.marginTop3)}>
          <PagesCard page={pages[0]} />
          <PagesCard page={pages[1]} />
        </div>
        <div className={classes.twoPages}>
          <PagesCard page={pages[2]} />
          <PagesCard page={pages[3]} />
        </div>
        <div className={cx(classes.twoPages, classes.marginTop3)}>
          <PagesCard page={pages[4]} />
          <PagesCard page={pages[5]} />
        </div>
        <div className={classes.twoPages}>
          <PagesCard page={pages[6]} />
          <PagesCard page={pages[7]} />
        </div>
        <div className={cx(classes.twoPages, classes.marginTop3)}>
          <PagesCard page={pages[8]} />
          <PagesCard page={pages[9]} />
        </div>
        {pages.length > 11 && (
          <div className={classes.twoPages}>
            <PagesCard page={pages[10]} />
            <PagesCard page={pages[11]} />
          </div>
        )}
        {pages.length > 13 && (
          <div className={cx(classes.twoPages, classes.marginTop3)}>
            <PagesCard page={pages[12]} />
            <PagesCard page={pages[13]} />
          </div>
        )}
        {pages.length > 15 && (
          <div className={classes.twoPages}>
            <PagesCard page={pages[14]} />
            <PagesCard page={pages[15]} />
          </div>
        )}
        {pages.length > 17 && (
          <div className={cx(classes.twoPages, classes.marginTop3)}>
            <PagesCard page={pages[16]} />
            <PagesCard page={pages[17]} />
          </div>
        )}
        {pages.length > 19 && (
          <div className={classes.twoPages}>
            <PagesCard page={pages[18]} />
            <PagesCard page={pages[19]} />
          </div>
        )}
        {pages.length > 21 && (
          <div className={cx(classes.twoPages, classes.marginTop3)}>
            <PagesCard page={pages[20]} />
            <PagesCard page={pages[21]} />
          </div>
        )}
      </Row>
    </Column>
  )
}

export default IndexPages
