import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Trans, useTranslation } from 'react-i18next'
import { Divider, Typography } from '@mui/material'
import FlatButton from 'shared-components/buttons/FlatButton'
import BottomSheet from '../common/BottomSheet'
import Image from 'next/image'
import { InfoOutlined, LocalActivity } from '@mui/icons-material'
import BashButton, {
  BashButtonType,
} from '../../shared-components/buttons/BashButton'

const useStyles = makeStyles()((theme) => ({
  root: {},
  divider: {
    marginRight: -24,
    marginLeft: -24,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  gotIt: {
    borderRadius: theme.spacing(1),
  },
  topRow: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  subtitle: {
    lineHeight: '21px',
  },
  bottomRow: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  clickable: {
    cursor: 'pointer',
  },
  icon: {
    width: 20,
    height: 20,
  },
  middleRow: {
    display: 'flex',
    gap: theme.spacing(1.5),
    padding: theme.spacing(2),
  },
  middleRowBg: {
    backgroundColor: theme.palette.grey[150],
    borderRadius: theme.spacing(1),
    display: 'flex',
    gap: theme.spacing(1.5),
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  aTag: {
    color: '#2E60DC',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  body1High: {
    lineHeight: '24px',
  },
  menuItemPro: {
    backgroundColor: theme.palette.secondary[100],
    color: theme.palette.secondary[800],
    padding: theme.spacing(0.5, 0.75),
    borderRadius: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
  },
  secondaryButton: {
    backgroundColor: theme.palette.secondary[800],
    borderRadius: theme.spacing(1),
  },
  buttonsRow: {
    display: 'flex',
    gap: theme.spacing(2),
  },
}))

const JoiningInfoModal = ({ open, onClose }) => {
  const { classes } = useStyles()
  const { t } = useTranslation('common')

  return (
    <BottomSheet className={classes.root} open={open} onClose={onClose}>
      <div className={classes.topRow}>
        <Image src='/images/check_circle.png' width={40} height={40} />
        <Typography className={classes.title} variant='subtitle1'>
          {t('joiningOnBash')}
        </Typography>
      </div>
      <div className={classes.middleRow}>
        <LocalActivity className={classes.icon} />
        <Typography className={classes.subtitle} variant='body2'>
          {t('joiningOnBashSub1')}
        </Typography>
      </div>
      <div className={classes.middleRowBg}>
        <Image src='/images/ticket_slash.png' width={20} height={20} />
        <Typography className={classes.subtitle} variant='body2'>
          {t('joiningOnBashSub2')}
        </Typography>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.bottomRow}>
        <FlatButton fullWidth className={classes.gotIt} onClick={onClose}>
          {t('okGotIt')}
        </FlatButton>
      </div>
    </BottomSheet>
  )
}

export const StripeModal = ({ open, onClose, org, onSetExternal }) => {
  const { classes } = useStyles()
  const { t } = useTranslation('common')

  const onConnect = () => {
    window.open(`/pages/${org.id}/settings/payments`, '_blank')
  }

  return (
    <BottomSheet className={classes.root} open={open} onClose={onClose}>
      <Typography
        style={{ marginBottom: 12, color: '#4D4D4D' }}
        variant='subtitle1'
      >
        {t('stripeModal.ticketSales')}
      </Typography>
      <Typography
        style={{ color: '#4D4D4D' }}
        variant='body1'
        className={classes.body1High}
      >
        {!org
          ? t('newCreation.ticketsOnlyOnOrgs')
          : t('stripeModal.ticketSalesSub')}
      </Typography>
      {!org && (
        <FlatButton
          fullWidth
          style={{
            marginTop: 16,
          }}
          className={classes.gotIt}
          onClick={onClose}
        >
          {t('okGotIt')}
        </FlatButton>
      )}
      {org && (
        <>
          <FlatButton
            fullWidth
            className={classes.gotIt}
            style={{ marginTop: 24 }}
            onClick={() => {
              onConnect()
              onClose()
            }}
          >
            {t('stripeModal.setUpStripe')}
          </FlatButton>
          {onSetExternal && (
            <Typography
              style={{ marginTop: 24, color: '#4D4D4D' }}
              variant='body1'
              className={classes.body1High}
            >
              {t('stripeModal.externalEntry')}{' '}
              <span className={classes.aTag} onClick={onSetExternal}>
                {t('stripeModal.here')}
              </span>
              {'.'}
            </Typography>
          )}
        </>
      )}
    </BottomSheet>
  )
}

export const ExternalProModal = ({ open, onClose }) => {
  const { classes } = useStyles()
  const { t } = useTranslation('common')

  return (
    <BottomSheet className={classes.root} open={open} onClose={onClose}>
      <div
        className={classes.titleRow}
        style={{ marginBottom: 12, color: '#4D4D4D' }}
      >
        <Typography variant='subtitle1'>
          {t('externalProModal.title')}
        </Typography>
        <div className={classes.menuItemPro}>
          <Typography variant='caption'>Pro</Typography>
        </div>
      </div>
      <Typography
        style={{ marginBottom: 24, color: '#4D4D4D' }}
        variant='body1'
        className={classes.body1High}
      >
        {t('externalProModal.subtitle')}
      </Typography>
      <FlatButton
        fullWidth
        className={classes.secondaryButton}
        href='mailto:organisers@bash.social'
        target='_top'
      >
        {t('externalProModal.contactUs')}
      </FlatButton>
    </BottomSheet>
  )
}

const OutlinedInfoIcon = () => (
  <span>
    <InfoOutlined
      style={{
        width: 18,
        height: 18,
        marginRight: 4,
        marginBottom: -2,
      }}
    />
  </span>
)

export const ProcessingFeeModal = ({ open, onClose }) => {
  const { classes } = useStyles()
  const { t } = useTranslation('common')

  return (
    <BottomSheet className={classes.root} open={open} onClose={onClose}>
      <Typography variant='subtitle1'>
        {t('processingFeeModal.title')}
      </Typography>
      <Typography
        style={{ marginBottom: 24, color: '#4D4D4D', marginTop: 12 }}
        variant='body1'
        className={classes.body1High}
      >
        <Trans
          t={t}
          i18nKey={'processingFeeModal.body'}
          components={{
            b: <b />,
            a: (
              <a
                href='mailto:organisers@bash.social'
                target='_top'
                style={{
                  textDecoration: 'underline',
                  color: '#2E60DC',
                }}
              />
            ),
            icon: <OutlinedInfoIcon />,
          }}
        />
      </Typography>
      <BashButton onClick={onClose}>{t('okGotIt')}</BashButton>
    </BottomSheet>
  )
}

export const TicketProviderProModal = ({ open, onClose }) => {
  const { classes } = useStyles()
  const { t } = useTranslation('common')

  return (
    <BottomSheet className={classes.root} open={open} onClose={onClose}>
      <div
        className={classes.titleRow}
        style={{ marginBottom: 12, color: '#4D4D4D' }}
      >
        <Typography variant='subtitle1'>
          {t('ticketProviderProModal.title')}
        </Typography>
        <div className={classes.menuItemPro}>
          <Typography variant='caption'>Pro</Typography>
        </div>
      </div>
      <Typography
        style={{ marginBottom: 24, color: '#4D4D4D' }}
        variant='body1'
        className={classes.body1High}
      >
        {t('ticketProviderProModal.subtitle')}
      </Typography>
      <FlatButton
        fullWidth
        className={classes.secondaryButton}
        href='mailto:organisers@bash.social'
        target='_top'
      >
        {t('ticketProviderProModal.contactUs')}
      </FlatButton>
    </BottomSheet>
  )
}

export const DeleteAlreadySoldModal = ({ open, onClose }) => {
  const { classes } = useStyles()
  const { t } = useTranslation('common')

  return (
    <BottomSheet className={classes.root} open={open} onClose={onClose}>
      <div className={classes.topRow}>
        <Typography className={classes.title} variant='subtitle1'>
          {t('removeType')}
        </Typography>
        <Typography className={classes.subtitle} variant='body2'>
          {t('removeTypeSub')}
        </Typography>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.bottomRow}>
        <FlatButton fullWidth className={classes.gotIt} onClick={onClose}>
          {t('okGotIt')}
        </FlatButton>
      </div>
    </BottomSheet>
  )
}

export const PrivatePageInfo = ({ open, onClose }) => {
  const { classes } = useStyles()
  const { t } = useTranslation('common')

  return (
    <BottomSheet className={classes.root} open={open} onClose={onClose}>
      <div className={classes.topRow}>
        <Typography className={classes.title} variant='subtitle1'>
          {t('makeProfilePrivate')}
        </Typography>
        <Typography className={classes.subtitle} variant='body2'>
          {t('privateProfileExplanation')}
        </Typography>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.bottomRow}>
        <FlatButton fullWidth className={classes.gotIt} onClick={onClose}>
          {t('okGotIt')}
        </FlatButton>
      </div>
    </BottomSheet>
  )
}

export const ExternalUrlInfo = ({ open, onClose }) => {
  const { classes } = useStyles()
  const { t } = useTranslation('common')

  return (
    <BottomSheet className={classes.root} open={open} onClose={onClose}>
      <div className={classes.topRow}>
        <Typography className={classes.title} variant='subtitle1'>
          {t('externalUrlInfo.title')}
        </Typography>
        <Typography className={classes.subtitle} variant='body2'>
          {t('externalUrlInfo.subtitle')}
        </Typography>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.bottomRow}>
        <FlatButton fullWidth className={classes.gotIt} onClick={onClose}>
          {t('okGotIt')}
        </FlatButton>
      </div>
    </BottomSheet>
  )
}

export const AdditionalTransactionFeesInfo = ({ open, onClose }) => {
  const { classes } = useStyles()
  const { t } = useTranslation('common')

  return (
    <BottomSheet className={classes.root} open={open} onClose={onClose}>
      <div className={classes.topRow}>
        <Typography className={classes.title} variant='subtitle1'>
          {t('additionalTransactionFees')}
        </Typography>
        <Typography className={classes.subtitle} variant='body2'>
          {t('additionalTransactionFeesCaption')}
        </Typography>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.bottomRow}>
        <FlatButton fullWidth className={classes.gotIt} onClick={onClose}>
          {t('okGotIt')}
        </FlatButton>
      </div>
    </BottomSheet>
  )
}

export const ExternalMessageInfo = ({ open, onClose }) => {
  const { classes } = useStyles()
  const { t } = useTranslation('common')

  return (
    <BottomSheet className={classes.root} open={open} onClose={onClose}>
      <div className={classes.topRow}>
        <Typography className={classes.title} variant='subtitle1'>
          {t('externalMessageInfo.title')}
        </Typography>
        <Typography className={classes.subtitle} variant='body2'>
          {t('externalMessageInfo.subtitle')}
        </Typography>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.bottomRow}>
        <FlatButton fullWidth className={classes.gotIt} onClick={onClose}>
          {t('okGotIt')}
        </FlatButton>
      </div>
    </BottomSheet>
  )
}

export const InvitesInfo = ({ maxInvites, open, onClose }) => {
  const { classes } = useStyles()
  const { t } = useTranslation('common')

  return (
    <BottomSheet className={classes.root} open={open} onClose={onClose}>
      <div className={classes.topRow}>
        <Typography className={classes.title} variant='subtitle1'>
          {t('maxXInvites', {
            x: maxInvites,
          })}
        </Typography>
        <Typography className={classes.subtitle} variant='body2'>
          <Trans
            i18nKey='maxInviteExplanation'
            t={t}
            components={{
              1: (
                <a
                  style={{ color: '#2E60DC' }}
                  href='mailto:organisers@bash.social'
                />
              ),
            }}
          />
        </Typography>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.bottomRow}>
        <FlatButton fullWidth className={classes.gotIt} onClick={onClose}>
          {t('okGotIt')}
        </FlatButton>
      </div>
    </BottomSheet>
  )
}

export const NoEntryChangeModal = ({ open, onClose, onDisable }) => {
  const { classes } = useStyles()
  const { t } = useTranslation('common')

  return (
    <BottomSheet className={classes.root} open={open} onClose={onClose}>
      <div className={classes.titleRow} style={{ marginBottom: 12 }}>
        <Typography variant='subtitle1'>
          {t('noEntryChangeModal.title')}
        </Typography>
      </div>
      <Typography
        style={{ marginBottom: 24 }}
        variant='body1'
        className={classes.body1High}
      >
        {t('noEntryChangeModal.subtitle')}
      </Typography>
      <div className={classes.buttonsRow}>
        <BashButton
          style={{
            borderRadius: 8,
            height: 56,
            padding: '0px 16px',
          }}
          type={BashButtonType.GREY}
          onClick={onDisable}
        >
          {t('ticketsCreation.visibility.title')}
        </BashButton>
        <BashButton
          style={{
            flexGrow: 1,
            borderRadius: 8,
            height: 56,
          }}
          type={BashButtonType.PRIMARY}
          onClick={onClose}
        >
          {t('okGotIt')}
        </BashButton>
      </div>
    </BottomSheet>
  )
}

export default JoiningInfoModal
