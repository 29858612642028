import typeToReducer from 'type-to-reducer'
import { HYDRATE } from 'next-redux-wrapper'
import dayjs from 'dayjs'

import {
  ADD_GUESTS_BY_STATUS,
  ADD_IMAGES,
  ADD_MESSAGE,
  CHANGE_DRAFT,
  CHANGE_EVENT,
  CHANGE_MESSAGE,
  DELETE_GUEST_FROM_INFO_SHEET,
  DELETE_MESSAGE,
  DISLIKE_EVENT,
  EDIT_EVENT,
  FETCH_CHAT_DIGEST,
  FETCH_EVENT,
  FETCH_EVENT_CHAT,
  FETCH_EVENT_IMAGES,
  FETCH_MESSAGES,
  FETCH_THEME_IMAGES,
  FETCH_COLOUR_SCHEMES,
  FETCH_USER_GUEST_QUESTION_RESPONSES,
  HYPE_EVENT,
  INVITE_USER,
  LIKE_EMOJI_MESSAGE,
  LIKE_EVENT,
  PATCH_EVENT,
  PATCH_GUEST_FROM_INFO_SHEET,
  PIN_DATE,
  POST_MESSAGE,
  REMOVE_ALL_MESSAGES,
  REMOVE_EVENT,
  REMOVE_LAST_GUEST_MESSAGE,
  REMOVE_LOCAL_GUEST,
  REMOVE_PENDING_ORDER,
  REMOVE_RSVP,
  REMOVE_RSVP_ERROR,
  REQUEST_JOIN,
  RSVP_EVENT,
  SET_ACTIVE_IMAGE_INDEX,
  SET_CURRENT_EVENT,
  SET_CURRENT_GUEST,
  SET_CURRENT_SELECTED_USER,
  SET_CURRENT_TICKET_CODE,
  SET_DATE_OPTIONS,
  SET_DRAFT,
  SET_IMAGES,
  SET_LOCAL_GUEST,
  SET_LOCATION_BEFORE_EDIT,
  SET_MY_GUEST,
  SET_OPEN_BASH_FROM,
  SET_RSVP_PENDING,
  SET_SCRAPE_DRAFT,
  SET_SHOW_IMAGES,
  SET_STATUS,
  SET_USERS_WITH_EVENTS,
  UPDATE_ERROR,
  WAITLIST_EVENT,
  FETCH_FONTS,
  UPDATE_HYPE_SCORE,
  ADD_GUEST_TO_EVENT,
  UPDATE_JOIN_OPTION_AVAILABLE,
} from '../actions/event'

import {
  FETCH_EVENTS,
  FETCH_ORG_EVENTS,
  FETCH_ORG_PAST_EVENTS,
  FETCH_PAST_EVENTS,
  RESET_ORG_EVENTS,
} from 'actions/organisation'

import { initialEvent, initialEventDraft } from '@redux/eventHelpers'
import {
  FETCH_ACTIVE_EVENTS,
  FETCH_CALENDAR_EVENTS,
  FETCH_FUTURE_EVENTS,
  FETCH_HOME_EVENTS,
  FETCH_HOSTED_EVENTS,
  FETCH_HOSTED_PAST_EVENTS,
  FETCH_INVITE_EVENTS,
  FETCH_OPEN_EVENTS,
  FETCH_TBA_EVENTS,
  FETCH_TODAY_EVENTS,
  RESET_HOSTED_EVENTS,
} from '../actions/user'

const initialState = {
  openBashFrom: null,
  locationBeforeEdit: '/home',
  currentGuestId: -1,
  isFulfilled: false,
  isRejected: false,
  error: null,
  rsvpPending: false,
  rsvpError: false,
  messagePending: false,
  messageFulfilled: false,
  messageError: null,
  pinDatePending: false,
  pinDateError: null,
  pinDateFulfilled: false,
  patchGuestPending: false,
  patchingGuest: null,
  deletingGuest: null,
  currentEventCode: null,
  currentSelectedUser: { id: 0 },
  myEvents: {
    0: {
      list: [],
    },
  },
  myPastEvents: {
    0: {
      list: [],
    },
  },
  eventSections: {
    calendar: {
      infinite: [],
    },
    today: {
      count: 0,
      list: [],
      infinite: [],
    },
    invite: {
      count: 0,
      list: [],
      infinite: [],
    },
    open: {
      count: 0,
      list: [],
      infinite: [],
    },
    active: {
      count: 0,
      list: [],
      infinite: [],
    },
    recent: {
      count: 0,
      list: [],
      infinite: [],
    },
    tba: {
      count: 0,
      list: [],
      infinite: [],
    },
    future: {
      list: [],
      infinite: [],
    },
    past: {
      list: [],
      infinite: [],
    },
    orgEvents: {
      list: [],
      infinite: [],
    },
    orgPastEvents: {
      list: [],
      infinite: [],
    },
  },
  themeImages: {
    list: [],
    rejected: false,
    pending: false,
    error: null,
  },
  events: {
    [null]: initialEvent,
    draft: initialEventDraft,
  },
}

const objectMap = (obj, fn) =>
  Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]))

const patchInGuestsByStatus = (guestsByStatus, guest) => {
  if (!guestsByStatus || !guestsByStatus[guest.status]) return guestsByStatus
  let found = false
  guestsByStatus = guestsByStatus[guest.status].map((g) => {
    if (g.id !== guest.id) return g
    found = true
    return guest
  })
  if (!found) {
    return {
      ...guestsByStatus,
      [guest.status]: [guest, ...guestsByStatus[guest.status]],
    }
  }
  return guestsByStatus
}

const addInGuestsByStatus = (guestsByStatus, guest) => {
  if (!guestsByStatus || !guestsByStatus[guest.status]) return guestsByStatus
  return {
    ...guestsByStatus,
    [guest.status]: [guest, ...guestsByStatus[guest.status]],
  }
}

const removeInGuestsByStatus = (guestsByStatus, guest) => {
  if (!guestsByStatus || !guestsByStatus[guest.status]) return guestsByStatus
  return {
    ...guestsByStatus,
    [guest.status]: guestsByStatus[guest.status].filter(
      (g) => g.id !== guest.id,
    ),
  }
}

const addEventToReducer = (events, event) => {
  return {
    ...events,
    [event.code]: events[event.code]
      ? {
          ...events[event.code],
          ...event,
          fetchedAt: dayjs().utc().format(),
          byCode: true,
          messages: events[event.code].messages,
          myGuest: event.myGuest,
        }
      : {
          ...initialEvent,
          ...event,
          fetchedAt: dayjs().utc().format(),
          messages: [],
        },
  }
}

export const myEventReducer = (isPast) => ({
  PENDING: (state, action) => ({
    ...state,
    [isPast ? 'myPastEvents' : 'myEvents']: {
      ...(isPast ? state.myPastEvents : state.myEvents),
      fetchingKey: action.payload,
      [action.payload]: {
        ...(isPast
          ? state.myPastEvents[action.payload]
          : state.myEvents[action.payload]),
        isFulfilled: false,
        isRejected: false,
        pending: true,
        error: null,
      },
    },
  }),
  FULFILLED: (state, action) => ({
    ...state,
    [isPast ? 'myPastEvents' : 'myEvents']: {
      ...(isPast ? state.myPastEvents : state.myEvents),
      fetchingKey: null,
      [isPast ? state.myPastEvents.fetchingKey : state.myEvents.fetchingKey]: {
        ...(isPast
          ? state.myPastEvents[state.myPastEvents.fetchingKey]
          : state.myEvents[state.myEvents.fetchingKey]),
        list: [
          ...(isPast
            ? (state.myPastEvents[state.myPastEvents.fetchingKey]?.list ?? [])
            : (state.myEvents[state.myEvents.fetchingKey]?.list ?? [])),
          ...action.payload.map((event) => event.code),
        ],
        isFulfilled: true,
        isRejected: false,
        pending: false,
        loadedAll: !action.payload || action.payload.length === 0,
      },
    },
    events: {
      ...state.events,
      ...newEventsFromList(state, action.payload || []),
    },
  }),
  REJECTED: (state, action) => ({
    ...state,
    [isPast ? 'myPastEvents' : 'myEvents']: {
      ...(isPast ? state.myPastEvents : state.myEvents),
      fetchingKey: null,
      [isPast ? state.myPastEvents.fetchingKey : state.myEvents.fetchingKey]: {
        ...(isPast
          ? state.myPastEvents[state.myPastEvents.fetchingKey]
          : state.myEvents[state.myEvents.fetchingKey]),
        isFulfilled: false,
        isRejected: true,
        pending: false,
        error: action.payload,
      },
    },
  }),
})

export const eventSectionReducer = (key) => ({
  PENDING: (state, action) => ({
    ...state,
    eventSections: {
      ...state.eventSections,
      [key]: {
        ...state.eventSections[key],
        isFulfilled: false,
        isRejected: false,
        pending: true,
        error: null,
      },
    },
  }),
  FULFILLED: (state, action) => ({
    ...state,
    eventSections: {
      ...state.eventSections,
      [key]: {
        ...state.eventSections[key],
        infinite: [
          ...state.eventSections[key].infinite,
          ...action.payload.map((event) => event.code),
        ],
        isFulfilled: true,
        isRejected: false,
        pending: false,
        loadedAll: !action.payload || action.payload.length === 0,
      },
    },
    events: {
      ...state.events,
      ...newEventsFromList(state, action.payload || []),
    },
  }),
  REJECTED: (state, action) => ({
    ...state,
    eventSections: {
      ...state.eventSections,
      [key]: {
        ...state.eventSections[key],
        isFulfilled: false,
        isRejected: true,
        pending: false,
        error: action.payload,
      },
    },
  }),
})

const addListOfEventsToReducer = (state, action) => {
  const newEvents = Object.fromEntries(
    action.payload.map((event) => [
      event.code,
      state.events[event.code]
        ? state.events[event.code].byCode
          ? { ...state.events[event.code] }
          : {
              ...state.events[event.code],
              ...event,
              imageUrls: {
                ...state.events[event.code].imageUrls,
                ...event.imageUrls,
              },
            }
        : {
            ...initialEvent,
            ...event,
          },
    ]),
  )
  return {
    ...state,
    events: {
      ...state.events,
      ...newEvents,
    },
  }
}

const newEventsFromList = (state, events) => {
  const newEvents = Object.fromEntries(
    events.map((event) => [
      event.code,
      state.events[event.code]
        ? state.events[event.code].byCode
          ? { ...state.events[event.code] }
          : {
              ...state.events[event.code],
              ...event,
              imageUrls: {
                ...state.events[event.code].imageUrls,
                ...event.imageUrls,
              },
            }
        : {
            ...initialEvent,
            ...event,
          },
    ]),
  )
  return newEvents
}

export default typeToReducer(
  {
    [HYDRATE]: (state, action) => ({
      // Only hydrate the store with fields used in SSR
      ...state,
      isFulfilled: action.payload.event.isFulfilled,
      isRejected: action.payload.event.isRejected,
      error: action.payload.event.error,
      currentEventCode:
        action.payload.event.currentEventCode != null
          ? action.payload.event.currentEventCode
          : state.currentEventCode,
      currentTicketCode:
        action.payload.event.currentTicketCode != null
          ? action.payload.event.currentTicketCode
          : state.currentTicketCode,
      currentGuestId:
        action.payload.event.currentGuestId != null
          ? action.payload.event.currentGuestId
          : state.currentGuestId,
      events: {
        ...state.events,
        ...action.payload.event.events,
      },
    }),
    FETCH_PUBLIC_EVENTS_FULFILLED: addListOfEventsToReducer,
    FETCH_USER_EVENTS_FULFILLED: addListOfEventsToReducer,
    FETCH_PAST_USER_EVENTS_FULFILLED: addListOfEventsToReducer,
    [`${FETCH_EVENTS}_FULFILLED`]: addListOfEventsToReducer,
    [`${FETCH_PAST_EVENTS}_FULFILLED`]: addListOfEventsToReducer,
    [SET_OPEN_BASH_FROM]: (state, action) => ({
      ...state,
      openBashFrom: action.payload,
    }),
    [SET_RSVP_PENDING]: (state, action) => ({
      ...state,
      rsvpPending: action.payload,
    }),
    [SET_CURRENT_EVENT]: (state, action) => ({
      ...state,
      currentEventCode: action.payload,
    }),
    [SET_CURRENT_TICKET_CODE]: (state, action) => ({
      ...state,
      currentTicketCode: action.payload,
    }),
    [SET_CURRENT_SELECTED_USER]: (state, action) => ({
      ...state,
      currentSelectedUser: action.payload,
    }),
    [UPDATE_ERROR]: (state, action) => ({
      ...state,
      events: {
        ...state.events,
        [state.currentEventCode]: {
          ...state.events[state.currentEventCode],
          updateError: action.payload,
        },
      },
    }),
    [REMOVE_ALL_MESSAGES]: (state, action) => ({
      ...state,
      events: {
        ...state.events,
        [state.currentEventCode]: {
          ...state.events[state.currentEventCode],
          messages: [],
        },
      },
    }),
    [SET_DATE_OPTIONS]: (state, action) => ({
      ...state,
      event: {
        ...state.event,
        dateOptions: action.payload,
      },
    }),
    [FETCH_CHAT_DIGEST]: (state, action) => ({
      ...state,
      events: {
        ...state.events,
        [state.currentEventCode]: {
          ...state.events[state.currentEventCode],
          chatDigest: action.payload,
        },
      },
    }),
    [FETCH_EVENT_CHAT]: {
      PENDING: (state, action) => ({
        ...state,
        events: {
          ...state.events,
          [state.currentEventCode]: {
            ...state.events[state.currentEventCode],
            chatLoading: true,
            chatRejected: false,
          },
        },
      }),
      FULFILLED: (state, action) => {
        return {
          ...state,
          events: {
            ...state.events,
            [state.currentEventCode]: {
              ...state.events[state.currentEventCode],
              chatLoading: false,
              chatGroup:
                action.payload != null
                  ? action.payload
                  : state.events[state.currentEventCode].chatGroup,
              chatRejected: false,
              chatFulfilled: true,
            },
          },
        }
      },
      REJECTED: (state, action) => ({
        ...state,
        events: {
          ...state.events,
          [state.currentEventCode]: {
            ...state.events[state.currentEventCode],
            chatLoading: false,
            chatRejected: true,
          },
        },
      }),
    },
    [FETCH_EVENT_IMAGES]: {
      PENDING: (state, action) => ({
        ...state,
        events: {
          ...state.events,
          [state.currentEventCode]: {
            ...state.events[state.currentEventCode],
            imagesLoading: true,
            imagesRejected: false,
          },
        },
      }),
      FULFILLED: (state, action) => {
        return {
          ...state,
          events: {
            ...state.events,
            [state.currentEventCode]: {
              ...state.events[state.currentEventCode],
              imagesLoading: false,
              images: action.payload,
              imagesRejected: false,
              chatFulfilled: true,
            },
          },
        }
      },
      REJECTED: (state, action) => ({
        ...state,
        events: {
          ...state.events,
          [state.currentEventCode]: {
            ...state.events[state.currentEventCode],
            imagesLoading: false,
            imagesRejected: true,
          },
        },
      }),
    },
    [ADD_IMAGES]: (state, action) => ({
      ...state,
      events: {
        ...state.events,
        [state.currentEventCode]: {
          ...state.events[state.currentEventCode],
          images: [
            ...action.payload,
            ...(state.events[state.currentEventCode].images ?? []),
          ],
        },
      },
    }),
    [SET_IMAGES]: (state, action) => ({
      ...state,
      events: {
        ...state.events,
        [state.currentEventCode]: {
          ...state.events[state.currentEventCode],
          images: action.payload,
        },
      },
    }),
    [SET_ACTIVE_IMAGE_INDEX]: (state, action) => ({
      ...state,
      events: {
        ...state.events,
        [state.currentEventCode]: {
          ...state.events[state.currentEventCode],
          showImageIndex: action.payload,
        },
      },
    }),
    [SET_SHOW_IMAGES]: (state, action) => ({
      ...state,
      events: {
        ...state.events,
        [state.currentEventCode]: {
          ...state.events[state.currentEventCode],
          showImages: action.payload,
        },
      },
    }),
    [SET_USERS_WITH_EVENTS]: (state, action) => ({
      ...state,
      events: {
        ...state.events,
        [state.currentEventCode]: {
          ...state.events[state.currentEventCode],
          usersWithEvents: action.payload,
        },
      },
    }),
    [FETCH_EVENT]: {
      PENDING: (state, action) => ({
        ...state,
        isFulfilled: false,
        event: initialState.event,
      }),
      FULFILLED: (state, action) => {
        return {
          ...state,
          isFulfilled: true,
          currentGuestId: action.payload?.myGuest
            ? action.payload?.myGuest?.id
            : state.currentGuestId,
          events: addEventToReducer(state.events, action.payload),
        }
      },
      REJECTED: (state, action) => ({
        ...state,
        isRejected: true,
        error: action.payload,
      }),
    },
    [REQUEST_JOIN]: {
      PENDING: (state, action) => ({
        ...state,
      }),
      FULFILLED: (state, action) => {
        return {
          ...state,
          events: {
            ...state.events,
            [state.currentEventCode]: {
              ...state.events[state.currentEventCode],
              ...action.payload,
            },
          },
        }
      },
      REJECTED: (state, action) => ({
        ...state,
      }),
    },
    [CHANGE_EVENT]: (state, action) => ({
      ...state,
      events: {
        ...state.events,
        [state.currentEventCode]: {
          ...state.events[state.currentEventCode],
          [action.payload.field]: action.payload.value,
          madeChange: true,
          madeNotifyableChange: [
            'location',
            'description',
            'startDate',
            'questions',
            'ticketLink',
            'ticketOptions',
          ].includes(action.payload.field)
            ? true
            : state.events[state.currentEventCode].madeNotifyableChange,
        },
      },
    }),
    [LIKE_EVENT]: (state, action) => ({
      ...state,
      events: {
        ...state.events,
        [state.currentEventCode]: {
          ...state.events[state.currentEventCode],
          liked: true,
          statusCounts: {
            ...state.events[state.currentEventCode].statusCounts,
            likes: state.events[state.currentEventCode].statusCounts.likes + 1,
          },
        },
      },
    }),
    [DISLIKE_EVENT]: (state, action) => ({
      ...state,
      events: {
        ...state.events,
        [state.currentEventCode]: {
          ...state.events[state.currentEventCode],
          liked: false,
          statusCounts: {
            ...state.events[state.currentEventCode].statusCounts,
            likes: state.events[state.currentEventCode].statusCounts.likes - 1,
          },
        },
      },
    }),
    [CHANGE_DRAFT]: (state, action) => ({
      ...state,
      events: {
        ...state.events,
        draft: {
          ...state.events.draft,
          madeChange: true,
          madeNotifyableChange: [
            'location',
            'description',
            'startDate',
            'questions',
            'ticketLink',
            'ticketOptions',
          ].includes(action.payload.field)
            ? true
            : state.events.draft.madeNotifyableChange,
          [action.payload.field]: action.payload.value,
        },
      },
    }),
    [SET_CURRENT_GUEST]: (state, action) => ({
      ...state,
      currentGuestId: action.payload,
    }),
    [SET_STATUS]: (state, action) => ({
      ...state,
      status: action.payload,
    }),
    [SET_LOCAL_GUEST]: (state, action) => ({
      ...state,
      currentGuestId: action.payload,
      foundGuestLocally: true,
    }),
    [REMOVE_LOCAL_GUEST]: (state) => ({
      ...state,
      currentGuestId: -1,
      foundGuestLocally: false,
      events: {
        ...state.events,
        [state.currentEventCode]: {
          ...state.events[state.currentEventCode],
          myGuest: null,
        },
      },
    }),
    [SET_MY_GUEST]: (state, action) => ({
      ...state,
      events: {
        ...state.events,
        [state.currentEventCode]: {
          ...state.events[state.currentEventCode],
          myGuest: action,
        },
      },
    }),
    [REMOVE_RSVP_ERROR]: (state, action) => ({
      ...state,
      rsvpError: null,
    }),
    [RSVP_EVENT]: {
      PENDING: (state, action) => ({
        ...state,
        rsvpPending: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        rsvpPending: false,
        rsvpError: false,
        currentGuestId: action.payload.myGuest && action.payload.myGuest.id,
        events: addEventToReducer(state.events, action.payload),
      }),
      REJECTED: (state, action) => {
        return {
          ...state,
          rsvpPending: false,
          rsvpError: action.payload,
        }
      },
    },
    [WAITLIST_EVENT]: {
      PENDING: (state, action) => ({
        ...state,
        rsvpPending: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        rsvpPending: false,
        rsvpError: false,
        currentGuestId: action.payload.myGuest && action.payload.myGuest.id,
        events: addEventToReducer(state.events, action.payload),
      }),
      REJECTED: (state, action) => {
        return {
          ...state,
          rsvpPending: false,
          rsvpError: action.payload,
        }
      },
    },
    [HYPE_EVENT]: {
      PENDING: (state, action) => ({
        ...state,
        rsvpPending: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        rsvpPending: false,
        rsvpError: false,
        currentGuestId: action.payload.myGuest && action.payload.myGuest.id,
        events: addEventToReducer(state.events, action.payload),
      }),
      REJECTED: (state, action) => {
        return {
          ...state,
          rsvpPending: false,
          rsvpError: action.payload,
        }
      },
    },
    [POST_MESSAGE]: {
      PENDING: (state, action) => ({
        ...state,
        messagePending: true,
        messageFulfilled: false,
        messageError: null,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        messagePending: false,
        messageFulfilled: true,
        // events: {
        //   ...state.events,
        //   [state.currentEventCode]: state.events[state.currentEventCode] ?
        //     {
        //       ...state.events[state.currentEventCode],
        //       messages: Array.isArray(state.events[state.currentEventCode].messages) ?
        //         [action.payload, ...state.events[state.currentEventCode].messages] :
        //         [action.payload]
        //     } : null
        // }
      }),
      REJECTED: (state, action) => ({
        ...state,
        messagePending: false,
        messageError: action.payload,
      }),
    },
    [ADD_MESSAGE]: (state, action) => ({
      ...state,
      events: {
        ...state.events,
        [state.currentEventCode]: {
          ...state.events[state.currentEventCode],
          chatDigest: [
            action.payload,
            ...state.events[state.currentEventCode].chatDigest,
          ],
        },
      },
    }),
    [DELETE_MESSAGE]: (state, action) => ({
      ...state,
      events: {
        ...state.events,
        [state.currentEventCode]: {
          ...state.events[state.currentEventCode],
          messages: state.events[state.currentEventCode].messages.filter(
            (m) => m.id !== action.payload,
          ),
        },
      },
    }),
    [REMOVE_LAST_GUEST_MESSAGE]: (state, action) => ({
      ...state,
      events: {
        ...state.events,
        [state.currentEventCode]: {
          ...state.events[state.currentEventCode],
          messages: state.events[state.currentEventCode].messages.filter(
            (m) => m.id !== -1,
          ),
        },
      },
    }),
    [CHANGE_MESSAGE]: (state, action) => ({
      ...state,
      events: {
        ...state.events,
        [state.currentEventCode]: {
          ...state.events[state.currentEventCode],
          messages: state.events[state.currentEventCode].messages.map((m) =>
            m.id === action.payload.id ? action.payload : m,
          ),
        },
      },
    }),
    [LIKE_EMOJI_MESSAGE]: {
      PENDING: (state, action) => ({
        ...state,
        likeEmojiPending: true,
        likeEmojiFulfilled: false,
        likeEmojiError: null,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        likeEmojiPending: false,
        likeEmojiFulfilled: true,
        events: {
          ...state.events,
          [state.currentEventCode]: state.events[state.currentEventCode]
            ? {
                ...state.events[state.currentEventCode],
                messages: state.events[state.currentEventCode].messages.map(
                  (msg) =>
                    msg.id === action.payload.id ? action.payload : msg,
                ),
              }
            : null,
        },
      }),
      REJECTED: (state, action) => ({
        ...state,
        likeEmojiPending: false,
        likeEmojiError: action.payload,
      }),
    },
    [INVITE_USER]: {
      PENDING: (state, action) => ({
        ...state,
        invitePending: true,
        inviteFulfilled: false,
        inviteError: null,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        invitePending: false,
        inviteFulfilled: true,
        events: {
          ...state.events,
          [action.payload.code]: {
            ...state.events[action.payload.code],
            ...action.payload,
          },
        },
      }),
      REJECTED: (state, action) => ({
        ...state,
        invitePending: false,
        inviteError: action.payload,
      }),
    },
    [SET_DRAFT]: (state, action) => ({
      ...state,
      events: {
        ...state.events,
        draft: {
          // ...state.events.draft,
          imageUrl: '',
          ...action.payload,
          themeImageKey: action.payload.themeImageKey,
          categoryId: action.payload.categoryId,
          ticketLink: action.payload.ticketDisplayLink,
        },
      },
    }),
    [SET_SCRAPE_DRAFT]: (state, action) => ({
      ...state,
      currentEventCode: 'scrapeDraft',
      events: {
        ...state.events,
        scrapeDraft: action.payload,
      },
    }),
    [REMOVE_PENDING_ORDER]: {
      PENDING: (state, action) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        patchEventPending: false,
        patchEventError: false,
        events: {
          ...state.events,
          [state.currentEventCode]: {
            ...state.events[state.currentEventCode],
            myGuest: {
              ...state.events[state.currentEventCode].myGuest,
              pendingTicketOrder: null,
            },
          },
        },
      }),
      REJECTED: (state, action) => ({
        ...state,
      }),
    },
    [EDIT_EVENT]: (state, action) => ({
      ...state,
      events: {
        ...state.events,
        [action.payload.code]: {
          ...state.events[action.payload.code],
          ...action.payload,
        },
      },
    }),
    [UPDATE_HYPE_SCORE]: (state, action) => {
      const entry = Object.entries(state.events).find(([code, event]) => event.id === action.payload.eventId)
      if (!entry) return state
      const [code, event] = entry
      return {
        ...state,
        events: {
          ...state.events,
          [code]: {
            ...event,
            hype: {
              ...action.payload.hype,
              hypeScore: action.payload.score,
            },
          },
        },
      }
    },
    [ADD_GUEST_TO_EVENT]: (state, action) => {
      const entry = Object.entries(state.events).find(([code, event]) => event.id === action.payload.eventId)
      if (!entry) return state
      const [code, event] = entry
      const guests = event.guests || []
      const index = guests.findIndex((guest) => guest.id === action.payload.guest.id)
      const newGuests = index === -1 ? [...guests, action.payload.guest] : [...guests.slice(0, index), action.payload.guest, ...guests.slice(index + 1)]
      return {
        ...state,
        events: {
          ...state.events,
          [code]: {
            ...event,
            guests: newGuests,
          },
        },
      }
    },
    [UPDATE_JOIN_OPTION_AVAILABLE]: (state, action) => {
      const entry = Object.entries(state.events).find(([code, event]) => event.id === action.payload.eventId)
      if (!entry) return state
      const [code, event] = entry
      const joinOptions = [...event.joinOptions]
      const index = joinOptions.findIndex((jo) => jo.id === action.payload.joinOptionId)
      const oldQuantityAvailable = joinOptions[index].quantityAvailable
      const quantityAvailable = joinOptions[index].quantityAvailable + action.payload.amount
      const myTickets = joinOptions[index].myTickets.length
      const quantityAvailableForYou = joinOptions[index].maxAmountPerGuest == null ? quantityAvailable : Math.min(quantityAvailable, joinOptions[index].maxAmountPerGuest - myTickets)
      joinOptions[index] = {
        ...joinOptions[index],
        quantityAvailable,
        quantityAvailableForYou,
        available: oldQuantityAvailable < 1 && quantityAvailable > 0 ? true : joinOptions[index].available,
      }
      return {
        ...state,
        events: {
          ...state.events,
          [code]: {
            ...event,
            joinOptions,
          },
        },
      }
    },
    [PATCH_EVENT]: {
      PENDING: (state, action) => ({
        ...state,
        patchEventPending: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        patchEventPending: false,
        patchEventError: false,
        events: {
          ...state.events,
          [action.payload.code]: {
            ...state.events[action.payload.code],
            ...action.payload,
          },
        },
      }),
      REJECTED: (state, action) => ({
        ...state,
        patchEventPending: false,
        patchEventError: action.payload,
      }),
    },
    [FETCH_MESSAGES]: (state, action) => ({
      ...state,
      events: {
        ...state.events,
        [action.payload.eventCode]: {
          ...state.events[action.payload.eventCode],
          hasMoreMessages: action.payload.hasMore,
          lastMessageFetched:
            action.payload.page === 0
              ? dayjs().utc().format()
              : state.events[action.payload.eventCode].lastMessageFetched,
          messages:
            action.payload.page === 0
              ? [...action.payload.messages]
              : [
                  ...state.events[action.payload.eventCode].messages,
                  ...action.payload.messages,
                ],
        },
      },
    }),
    [PIN_DATE]: {
      PENDING: (state, action) => ({
        ...state,
        pinDatePending: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        pinDatePending: false,
        pinDateFulfilled: true,
        events: {
          ...state.events,
          [action.payload.code]: {
            ...state.events[action.payload.code],
            ...action.payload,
            guests: action.payload.guests || [],
          },
        },
      }),
      REJECTED: (state, action) => ({
        ...state,
        pinDatePending: false,
        pinDateError: action.payload,
      }),
    },
    [PATCH_GUEST_FROM_INFO_SHEET]: {
      PENDING: (state, action) => ({
        ...state,
        patchGuestPending: true,
        patchingGuest: action.payload,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        patchGuestPending: false,
        patchingGuest: null,
        events: {
          ...state.events,
          [state.currentEventCode]: {
            ...state.events[state.currentEventCode],
            guests: (state.events[state.currentEventCode].guests || []).map(
              (guest) =>
                guest.id === action.payload.id ? action.payload : guest,
            ),
            guestsByStatus: addInGuestsByStatus(
              removeInGuestsByStatus(
                state.events[state.currentEventCode].guestsByStatus,
                state.patchingGuest,
              ),
              action.payload,
            ),
          },
        },
      }),
      REJECTED: (state, action) => ({
        ...state,
        patchingGuest: null,
        patchGuestPending: false,
        patchGuestError: action.payload,
      }),
    },
    [DELETE_GUEST_FROM_INFO_SHEET]: {
      PENDING: (state, action) => ({
        ...state,
        deletingGuest: action.payload,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        deletingGuest: null,
        events: {
          ...state.events,
          [state.currentEventCode]: {
            ...state.events[state.currentEventCode],
            guests: (state.events[state.currentEventCode].guests || []).filter(
              (guest) => guest.id !== state.deletingGuest.id,
            ),
            guestsByStatus: removeInGuestsByStatus(
              state.events[state.currentEventCode].guestsByStatus,
              state.deletingGuest,
            ),
          },
        },
      }),
      REJECTED: (state, action) => ({
        ...state,
        deletingGuest: null,
        deleteGuestError: action.payload,
      }),
    },
    [ADD_GUESTS_BY_STATUS]: (state, action) => ({
      ...state,
      events: {
        ...state.events,
        [action.payload.eventCode]: {
          ...state.events[action.payload.eventCode],
          guestsByStatus: {
            ...(state.events[action.payload.eventCode].guestsByStatus || {}),
            [action.payload.status]: Array.isArray(
              state.events[action.payload.eventCode].guestsByStatus &&
                state.events[action.payload.eventCode].guestsByStatus[
                  action.payload.status
                ],
            )
              ? [
                  ...state.events[action.payload.eventCode].guestsByStatus[
                    action.payload.status
                  ],
                  ...action.payload.guests,
                ]
              : action.payload.guests,
          },
        },
      },
    }),
    [REMOVE_EVENT]: {
      PENDING: (state, action) => ({
        ...state,
        eventSections: objectMap(state.eventSections, (section) => {
          if (!section) return null
          if (!section.list) return section

          return {
            ...section,
            list:
              section.list?.filter((item) => item !== action.payload) ??
              undefined,
          }
        }),
        events: {
          ...state.events,
          [action.payload]: undefined,
        },
      }),
      FULFILLED: (state, action) => ({
        ...state,
      }),
    },
    [REMOVE_RSVP]: {
      FULFILLED: (state, action) => ({
        ...state,
        events: {
          ...state.events,
          [action.payload.eventCode]: {
            ...state.events[action.payload.eventCode],
            guests: state.events[action.payload.eventCode].guests.filter(
              (guest) => guest.user?.id !== action.payload.userId,
            ),
            myGuest: undefined,
          },
        },
      }),
    },
    [FETCH_HOME_EVENTS]: {
      PENDING: (state, action) => ({
        ...state,
        eventSections: {
          ...state.eventSections,
          isFulfilled: false,
          isRejected: false,
          pending: true,
          error: null,
        },
      }),
      FULFILLED: (state, action) => ({
        ...state,
        eventSections: {
          ...state.eventSections,
          active: {
            ...state.eventSections.active,
            count: action.payload.activeCount,
            list:
              (action.payload.active &&
                action.payload.active.map((item) => item.code)) ||
              [],
          },
          today: {
            ...state.eventSections.today,
            count: action.payload.todayCount,
            list:
              (action.payload.today &&
                action.payload.today.map((item) => item.code)) ||
              [],
          },
          invite: {
            ...state.eventSections.invite,
            count: action.payload.inviteCount,
            list:
              (action.payload.invites &&
                action.payload.invites.map((item) => item.code)) ||
              [],
          },
          open: {
            ...state.eventSections.open,
            count: action.payload.openCount,
            list:
              (action.payload.open &&
                action.payload.open.map((item) => item.code)) ||
              [],
          },
          tba: {
            ...state.eventSections.tba,
            count: action.payload.tbaCount,
            list:
              (action.payload.tba &&
                action.payload.tba.map((item) => item.code)) ||
              [],
          },
          recent: {
            ...state.eventSections.recent,
            count: action.payload.recentCount,
            list:
              (action.payload.recent &&
                action.payload.recent.map((item) => item.code)) ||
              [],
          },
          isFulfilled: true,
          isRejected: false,
          pending: false,
        },
        events: {
          ...state.events,
          ...newEventsFromList(
            state,
            (action.payload.active || []).concat(
              action.payload.today || [],
              action.payload.invites || [],
              action.payload.open || [],
              action.payload.tba || [],
              action.payload.recent || [],
            ),
          ),
        },
      }),
      REJECTED: (state, action) => ({
        ...state,
        eventSections: {
          ...state.eventSections,
          isFulfilled: false,
          isRejected: true,
          pending: false,
          error: action.payload,
        },
      }),
    },
    [SET_LOCATION_BEFORE_EDIT]: (state, action) => ({
      ...state,
      locationBeforeEdit: action.payload,
    }),
    [FETCH_THEME_IMAGES]: {
      PENDING: (state, action) => ({
        ...state,
        themeImages: {
          ...state.themeImages,
          rejected: false,
          pending: true,
          error: null,
        },
      }),
      FULFILLED: (state, action) => ({
        ...state,
        themeImages: {
          ...state.themeImages,
          rejected: false,
          pending: false,
          error: null,
          list: action.payload,
        },
      }),
      REJECTED: (state, action) => ({
        ...state,
        themeImages: {
          ...state.themeImages,
          rejected: true,
          pending: false,
          error: action.payload,
        },
      }),
    },
    [FETCH_COLOUR_SCHEMES]: {
      PENDING: (state, action) => ({
        ...state,
        colourSchemes: {
          ...state.colourSchemes,
          rejected: false,
          pending: true,
          error: null,
        },
      }),
      FULFILLED: (state, action) => ({
        ...state,
        colourSchemes: {
          ...state.colourSchemes,
          rejected: false,
          pending: false,
          error: null,
          list: action.payload,
        },
      }),
      REJECTED: (state, action) => ({
        ...state,
        colourSchemes: {
          ...state.colourSchemes,
          rejected: true,
          pending: false,
          error: action.payload,
        },
      }),
    },
    [FETCH_FONTS]: {
      PENDING: (state, action) => ({
        ...state,
        fonts: {
          ...state.fonts,
          rejected: false,
          pending: true,
          error: null,
        },
      }),
      FULFILLED: (state, action) => ({
        ...state,
        fonts: {
          ...state.fonts,
          rejected: false,
          pending: false,
          error: null,
          list: action.payload,
        },
      }),
      REJECTED: (state, action) => ({
        ...state,
        fonts: {
          ...state.fonts,
          rejected: true,
          pending: false,
          error: action.payload,
        },
      }),
    },
    [FETCH_FUTURE_EVENTS]: eventSectionReducer('future'),
    [FETCH_PAST_EVENTS]: eventSectionReducer('past'),
    [FETCH_TBA_EVENTS]: eventSectionReducer('tba'),
    [FETCH_TODAY_EVENTS]: eventSectionReducer('today'),
    [FETCH_INVITE_EVENTS]: eventSectionReducer('invite'),
    [FETCH_OPEN_EVENTS]: eventSectionReducer('open'),
    [FETCH_ACTIVE_EVENTS]: eventSectionReducer('active'),
    [FETCH_ORG_EVENTS]: eventSectionReducer('orgEvents'),
    [FETCH_ORG_PAST_EVENTS]: eventSectionReducer('orgPastEvents'),
    [FETCH_CALENDAR_EVENTS]: eventSectionReducer('calendar'),
    [FETCH_HOSTED_EVENTS]: myEventReducer(false),
    [FETCH_HOSTED_PAST_EVENTS]: myEventReducer(true),
    [RESET_HOSTED_EVENTS]: (state, action) => ({
      ...state,
      myEvents: {
        ...state.myEvents,
        [action.payload]: {
          list: [],
          isFulfilled: false,
          isRejected: false,
          pending: false,
          loadedAll: false,
          error: null,
        },
      },
      myPastEvents: {
        ...state.myPastEvents,
        [action.payload]: {
          list: [],
          isFulfilled: false,
          isRejected: false,
          pending: false,
          loadedAll: false,
          error: null,
        },
      },
    }),
    [FETCH_USER_GUEST_QUESTION_RESPONSES]: (state, action) => ({
      ...state,
      events: {
        ...state.events,
        [action.payload.eventCode]: {
          ...state.events[action.payload.eventCode],
          myGuest: {
            ...state.events[action.payload.eventCode].myGuest,
            questionResponses: action.payload.questionResponses,
            questionsCompleted:
              action.payload.questionsCompleted ??
              state.events[action.payload.eventCode].myGuest
                ?.questionsCompleted,
          },
        },
      },
    }),
    [RESET_ORG_EVENTS]: (state, action) => ({
      ...state,
      eventSections: {
        ...state.eventSections,
        orgEvents: {
          ...state.eventSections.orgEvents,
          list: [],
          infinite: [],
          isFulfilled: false,
          loadedAll: false,
        },
        orgPastEvents: {
          ...state.eventSections.orgPastEvents,
          list: [],
          infinite: [],
          isFulfilled: false,
          loadedAll: false,
        },
      },
    }),
  },
  initialState,
)
