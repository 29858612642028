import React from 'react'

import { withTranslation } from 'react-i18next'
import { useRouter } from 'next/router'

import { Typography } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(2),
    flexGrow: 1,
    marginRight: 'auto',
    marginLeft: 'auto',
    maxWidth: 420,
    overflowX: 'hidden',
    height: '100%',
  },
  contentContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mailField: {
    margin: theme.spacing(2),
    width: '100%',
    border: '2px solid #212121',
    borderRadius: 50,
  },
  invalid: {
    borderColor: 'red',
  },
  mailInputBase: {
    width: '100%',
    margin: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  submitButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    marginTop: theme.spacing(2),
  },
  text: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  text2: {
    textAlign: 'center',
    marginTop: theme.spacing(5),
  },
  logo: {
    width: 200,
    height: 200,
    margin: theme.spacing(10),
    cursor: 'pointer',
  },
}))

const PageNotFound = ({ t }) => {
  const { classes } = useStyles()
  const router = useRouter()

  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>
        <Typography variant='h4' color='inherit' className={classes.title}>
          404!
        </Typography>

        <img
          src='/images/logo_undertext.png'
          onClick={() => router.push('/')}
          className={classes.logo}
          alt='logo'
        />

        <Typography
          variant='subtitle2'
          color='inherit'
          className={classes.text}
        >
          {t('pageNotFound')}
        </Typography>
      </div>
    </div>
  )
}

export default withTranslation('common')(PageNotFound)
