import React from 'react'
import { Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { countStatus } from 'shared-components/utils/guests'

const StatusCounter = ({
  classes,
  guests = [],
  showInvited = true,
  pinning = false,
}) => {
  const { t } = useTranslation('common')
  const counts = countStatus(guests)
  return (
    <Typography variant='caption' color='textSecondary'>
      {counts.GOING > 0 && (
        <Trans t={t} i18nKey={'rsvpCounts.available'} count={counts.GOING}>
          <b>{{ count: counts.GOING }}</b> going
        </Trans>
      )}
      {counts.GOING > 0 &&
        (counts.MAYBE > 0 || counts.CANT > 0 || counts.NONE > 0) &&
        ' · '}
      {counts.MAYBE > 0 && (
        <Trans t={t} i18nKey='rsvpCounts.maybe' count={counts.MAYBE}>
          <b>{{ count: counts.MAYBE }}</b> maybe
        </Trans>
      )}
      {counts.MAYBE > 0 && (counts.CANT > 0 || counts.NONE > 0) && ' · '}
      {counts.CANT > 0 && (
        <Trans t={t} i18nKey={'rsvpCounts.unavailable'} count={counts.CANT}>
          <b>{{ count: counts.CANT }}</b> can't go
        </Trans>
      )}
      {counts.CANT > 0 && counts.NONE > 0 && ' · '}
      {showInvited && counts.NONE > 0 && (
        <Trans
          t={t}
          i18nKey={`rsvpCounts.${counts.NONE > 1 ? 'none_plural' : 'none'}`}
          count={counts.NONE}
        >
          <b>{{ count: counts.NONE }}</b> are invited
        </Trans>
      )}
      {(counts.GOING > 0 ||
        counts.MAYBE > 0 ||
        counts.CANT > 0 ||
        counts.NONE > 0) &&
        '.'}
    </Typography>
  )
}

export default StatusCounter
