import Row from '../../Row'
import Column from '../../Column'
import { Box, Typography } from '@mui/material'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import HowItWorksVisualAnimator from './HowItWorksVisualAnimator'
import AnimatedStateContent from '../../AnimatedStateContent'
import { indexMobileBreakpoint } from '../../../types/types'
import EventixIcon from '../../../svg/EventixIcon'
import { useTranslation } from 'react-i18next'

interface Props {
  activeStep: number
  onClickLeft: (() => void) | undefined
  onClickRight: (() => void) | undefined
}

const HowItWorksStep = ({ onClickLeft, onClickRight, activeStep }: Props) => {
  const { t } = useTranslation('common')

  return (
    <Row
      sx={{
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        // gap: '110px',
        columnGap: 7,
        width: 1,
        flexWrap: 'wrap-reverse',
      }}
    >
      <Column
        sx={(theme) => ({
          maxWidth: 481,
          gap: 6.25,
          flexGrow: 1,
          flexShrink: 1,
          flexBasis: 200,
          pt: 10,
          [theme.breakpoints.down(indexMobileBreakpoint)]: {
            gap: 2,
            pt: 3,
          },
        })}
      >
        <Row
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            color: 'secondary.800',
            pr: 4.25,
            position: 'relative',
          }}
        >
          <AnimatedStateContent
            getContent={(activeStep) => (
              <>
                <Typography
                  sx={{
                    fontWeight: 500,
                    textTransform: 'uppercase',
                    flexShrink: 0,
                    whiteSpace: 'nowrap',
                  }}
                  variant='h6'
                >
                  {t(`index.step${activeStep + 1}.stepTitle`)}
                </Typography>
              </>
            )}
            state={activeStep}
          />

          <Row
            sx={{
              gap: '9.33px',
            }}
          >
            <ChevronLeft
              onClick={onClickLeft}
              sx={{
                cursor: onClickLeft ? 'pointer' : 'default',
                width: 32,
                height: 32,
                transition: 'color 0.2s ease-in-out',
                color: onClickLeft ? 'secondary.800' : 'secondary.200',
              }}
            />
            <ChevronRight
              onClick={onClickRight}
              sx={{
                cursor: onClickRight ? 'pointer' : 'default',
                width: 32,
                height: 32,
                transition: 'color 0.2s ease-in-out',
                color: onClickRight ? 'secondary.800' : 'secondary.200',
              }}
            />
          </Row>
        </Row>

        <AnimatedStateContent
          getContent={(activeStep) => (
            <>
              <Column
                sx={{
                  gap: 1.5,
                }}
              >
                <Typography
                  sx={(theme) => ({
                    fontSize: '1.75rem',
                    fontWeight: 800,
                    lineHeight: '2.0625rem',
                    [theme.breakpoints.down(indexMobileBreakpoint)]: {
                      fontSize: '1.25rem',
                      lineHeight: '1.5rem',
                    },
                  })}
                  variant='h4'
                >
                  {t(`index.step${activeStep + 1}.title`)}
                </Typography>

                <Typography
                  sx={(theme) => ({
                    fontWeight: 400,
                    fontSize: '1.125rem',
                    lineHeight: '1.6rem',
                    letterSpacing: '0.5px',
                    pb: 4,
                    [theme.breakpoints.down(indexMobileBreakpoint)]: {
                      fontSize: '1rem',
                      lineHeight: '1.5rem',
                    },
                  })}
                  variant='h6'
                >
                  {t(`index.step${activeStep + 1}.description`)}
                  {t(`index.step${activeStep + 1}.italic`).endsWith(
                    '.italic',
                  ) ? (
                    ''
                  ) : (
                    <Box
                      component='i'
                      style={{
                        fontSize: '0.875rem',
                      }}
                    >{`\n\n${t(`index.step${activeStep + 1}.italic`)}`}</Box>
                  )}

                  {activeStep === 0 && (
                    <Row
                      sx={{
                        gap: 0.5,
                        alignItems: 'center',
                      }}
                    >
                      <EventixIcon
                        style={{
                          width: 16,
                          height: 16,
                          color: '#2E60DC',
                        }}
                      />
                      <Typography
                        sx={(theme) => ({
                          fontWeight: 400,
                          fontSize: '1.125rem',
                          lineHeight: '1.6rem',
                          letterSpacing: '0.5px',
                          [theme.breakpoints.down(indexMobileBreakpoint)]: {
                            fontSize: '1rem',
                            lineHeight: '1.5rem',
                          },
                        })}
                        variant='h6'
                      >
                        Eventix
                      </Typography>
                    </Row>
                  )}
                </Typography>
              </Column>
            </>
          )}
          state={activeStep}
        />
      </Column>

      <HowItWorksVisualAnimator activeStep={activeStep} />
    </Row>
  )
}

export default HowItWorksStep
