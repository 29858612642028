import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button, SvgIcon, Tooltip, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { ChevronRight } from '@mui/icons-material'
import { setSnackbar } from '../../shared-components/redux/notifications/actions'
import { mdiLock } from '@mdi/js'
import { decapitalizeFirstLetter } from '../../shared-components/utils/copy'
import {
  ApprovalBadge,
  AvailableFromBadge,
  AvailableUntilBadge,
  HiddenOptionBadge,
} from '../EventCards/Badges'
import dayjs from 'dayjs'

const useStyles = makeStyles()((theme) => ({
  ticketTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  ticketOption: {
    flexShrink: 0,
    width: '100%',
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: 8,
    overflow: 'hidden',
    textAlign: 'start',
  },
  ticketTop: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'start',
  },
  ticketTopContainer: {
    width: '100%',
    padding: theme.spacing(2),
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  ticketTopContainerLabel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
  },
  soldOut: {
    opacity: 0.5,
  },
  ticketLeft: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    justifyContent: 'center',
  },
  ticketRight: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(0.5),
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      color: theme.palette.text.tertiary,
    },
  },
  ticketBottom: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
  },
  ticketBottomCaption: {
    marginLeft: theme.spacing(0.5),
  },
  ticketLinkContainer: {
    flex: 1,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  ticketLink: {
    padding: theme.spacing(1.5, 2),
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: 8,
    background: theme.palette.grey[150],
  },
  unlockedIcon: {
    width: 16,
    height: 16,
  },
  badges: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  copyButton: {
    height: 40,
    borderRadius: 100,
    color: theme.palette.primary[800],
    background: theme.palette.primary[100],
    '&:hover': {
      background: theme.palette.primary[200],
    },
  },
  questions: {
    borderRadius: 0,
    width: '100%',
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2, 2, 2),
    gap: theme.spacing(1),
  },
  questionsLabel: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  seeAnswers: {
    marginLeft: 'auto',
  },
}))

const TicketStatsItem = ({
  ticketOption,
  onQuestionsClicked,
  onGuestListClicked,
}) => {
  const { t } = useTranslation('common')
  const { classes, cx } = useStyles()
  const dispatch = useDispatch()
  const showAvailableFrom =
    ticketOption.name !== '' &&
    ticketOption.availableFrom &&
    dayjs(ticketOption.availableFrom).isAfter(dayjs())
  const showAvailableTo = ticketOption.availableTo

  const isEventix = ticketOption.idInfo?.source === 'EVENTIX'

  const onCopyClicked = (ticket) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(ticket.hiddenLink)
      dispatch(setSnackbar('info', t('shared:linkCopiedToClipboard')))
    } else {
      dispatch(setSnackbar('error', 'Failed to copy'))
    }
  }

  return (
    <Tooltip title={isEventix ? 'Manage on Eventix dashboard' : ''}>
      <div
        className={cx(classes.ticketOption)}
        style={{
          cursor: isEventix ? 'not-allowed' : 'default',
        }}
      >
        <Button
          style={{
            pointerEvents: isEventix ? 'none' : 'auto',
          }}
          className={cx(
            classes.ticketTopContainer,
            classes.ticketTopContainerLabel,
          )}
          onClick={onGuestListClicked}
        >
          <div className={classes.ticketTop}>
            <div className={classes.ticketLeft}>
              <div className={classes.ticketTitle}>
                <Typography style={{ fontWeight: 500 }} variant='body1'>
                  {ticketOption.name === ''
                    ? t('guestListTicket')
                    : ticketOption.name}
                </Typography>
                {ticketOption.hidden && (
                  <SvgIcon className={classes.unlockedIcon}>
                    <path d={mdiLock} />
                  </SvgIcon>
                )}
              </div>
            </div>
            <div className={classes.ticketRight}>
              {ticketOption.amountSold != null &&
                ticketOption.amountSold > 0 && (
                  <>
                    <Typography variant='body2'>
                      {`${ticketOption.amountSold}${ticketOption.capacity ? ` / ${ticketOption.capacity}` : ''} ${decapitalizeFirstLetter(t('joined'))}`}
                    </Typography>
                    {ticketOption.amountSold > 0 && !isEventix && (
                      <ChevronRight />
                    )}
                  </>
                )}
            </div>
          </div>

          {(ticketOption.approvalRequired ||
            ticketOption.hidden ||
            showAvailableFrom ||
            showAvailableTo) && (
            <div className={classes.badges}>
              {ticketOption.hidden && <HiddenOptionBadge />}
              {ticketOption.approvalRequired && <ApprovalBadge />}
              {showAvailableFrom && (
                <AvailableFromBadge joinOption={ticketOption} />
              )}
              {showAvailableTo && (
                <AvailableUntilBadge joinOption={ticketOption} />
              )}
            </div>
          )}
        </Button>

        {ticketOption.hidden && (
          <div className={classes.ticketBottom}>
            <Typography variant='body2' className={classes.ticketBottomCaption}>
              {t('shareTicketLink')}
            </Typography>
            <div className={classes.ticketLinkContainer}>
              <Typography noWrap variant='body2' className={classes.ticketLink}>
                {ticketOption.hiddenLink}
              </Typography>

              <Button
                onClick={() => onCopyClicked(ticketOption)}
                className={classes.copyButton}
              >
                {t('copy')}
              </Button>
            </div>
          </div>
        )}
        {ticketOption.questions?.length > 0 && (
          <Button
            className={cx(classes.questions, classes.questionsLabel)}
            style={{
              pointerEvents: isEventix ? 'none' : 'auto',
            }}
            onClick={onQuestionsClicked}
          >
            <Typography style={{ fontWeight: 500 }} variant='body2'>
              {t(
                ticketOption.questions.length === 1
                  ? 'newCreation.xQuestions'
                  : 'newCreation.xQuestions_plural',
                { count: ticketOption.questions.length },
              )}
            </Typography>

            <Typography
              style={{
                opacity: isEventix ? 0 : 1,
              }}
              className={classes.seeAnswers}
              variant='caption'
            >
              {t('questions.seeAnswers')}
            </Typography>

            <ChevronRight
              style={{
                width: 16,
                height: 16,
                opacity: isEventix ? 0 : 1,
              }}
            />
          </Button>
        )}
      </div>
    </Tooltip>
  )
}

export default TicketStatsItem
