import { makeStyles } from 'tss-react/mui'
import {
  ChevronLeft,
  ChevronRight,
  Close,
  CloudUpload,
} from '@mui/icons-material'
import { getVideoUrl } from '../../../shared-components/utils/media'
import Typography from '@mui/material/Typography'
import { Button } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'

const mediaSize = 48

const useStyles = makeStyles()((theme) => ({
  mediaContainerContainer: {
    position: 'relative',
    marginTop: theme.spacing(0.5),
  },
  mediaContainerContainerVertical: {
    position: 'absolute',
    right: 0,
    height: '100%',
  },
  mediaContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginTop: theme.spacing(0),
    width: '100%',
    overflowX: 'auto',
    position: 'relative',
    ' -ms-overflow-style': 'none' /* IE and Edge */,
    scrollbarWidth: 'none',
    '& :-webkit-scrollbar': {
      display: 'none',
    },
  },
  mediaContainerVertical: {
    overflowY: 'auto',
    height: '100%',
    overflowX: 'hidden',
  },
  mediaItemContainer: {
    position: 'relative',
  },
  removeButton: {
    position: 'absolute',
    top: 4,
    right: -6,
    cursor: 'pointer',
    width: 16,
    height: 16,
    padding: 2,
    background: theme.palette.grey[600],
    borderRadius: 100,
    zIndex: 10,
    color: 'white',
  },
  mediaItem: {
    flexShrink: 0,
    cursor: 'pointer',
    width: mediaSize,
    height: mediaSize,
    objectFit: 'cover',
    // borderRadius: 8,
    overflow: 'hidden',
    '& img': {
      width: mediaSize,
      height: mediaSize,
      objectFit: 'cover',
      borderRadius: '8px',
      border: '1px solid ' + theme.palette.divider,
    },
    '& video': {
      width: mediaSize,
      height: mediaSize,
      objectFit: 'cover',
      borderRadius: '8px',
      border: '1px solid ' + theme.palette.divider,
    },
    '& span': {
      width: mediaSize,
      height: mediaSize,
    },
  },
  videoContainer: {
    position: 'relative',
    display: 'flex',
  },
  videoTagSmall: {
    padding: 4,
    background: 'rgba(255,255,255,0.8)',
    fontSize: '0.625rem',
    top: 5,
    left: 5,
    position: 'absolute',
    zIndex: 10,
    borderRadius: 100,
  },
  scrollButton: {
    position: 'absolute',
    minWidth: 0,
    background: 'white',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 100,
    width: 24,
    height: 24,
    '&:hover': {
      backgroundColor: theme.palette.divider,
    },
  },
  scrollButtonVertical: {
    position: 'absolute',
    minWidth: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: 100,
    width: 24,
    height: 24,
    '&:hover': {
      backgroundColor: 'black',
    },
  },
  scrollRight: {
    right: theme.spacing(1),
    transform: 'translateY(-50%)',
    top: '50%',
  },
  scrollRightVertical: {
    right: 'none',
    left: '50%',
    transform: 'translateX(-50%) rotate(90deg)',
    bottom: theme.spacing(1),
  },
  scrollLeft: {
    left: theme.spacing(1),
    transform: 'translateY(-50%)',
    top: '50%',
  },
  scrollLeftVertical: {
    right: 'none',
    left: '50%',
    transform: 'translateX(-50%) rotate(90deg)',
    top: theme.spacing(1),
  },
  uploadMoreContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: theme.spacing(0.25),
  },
  uploadMoreIcon: {
    color: theme.palette.grey[500],
    width: 24,
    height: 24,
  },
  uploadMoreText: {
    fontSize: '0.6rem',
    width: 'auto !important',
    height: 'auto !important',
  },
  uploadMoreButton: {
    border: `dashed 1px ${theme.palette.grey[500]}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.grey[150],
    },
    marginLeft: theme.spacing(0.5),
  },
}))

const MultimediaViewerScroller = ({
  media,
  vertical,
  onRemoveImageClicked,
  setActiveImageIdx,
  onSetImageClicked,
  onMediaItemClick,
  getDurationFormat,
}) => {
  const { classes, cx } = useStyles()
  const scrollRef = useRef(null)

  const [showScrollLeft, setShowScrollLeft] = useState(false)
  const [showScrollRight, setShowScrollRight] = useState(false)

  const checkScroll = () => {
    const left = vertical
      ? scrollRef.current?.scrollTop
      : scrollRef.current?.scrollLeft
    const width = vertical
      ? scrollRef.current?.clientHeight
      : scrollRef.current?.clientWidth
    const scrollWidth = vertical
      ? scrollRef.current?.scrollHeight
      : scrollRef.current?.scrollWidth
    setShowScrollLeft(left > 0)
    setShowScrollRight(scrollWidth > width && left < scrollWidth - width)
  }

  const scrollRight = () => {
    scrollRef.current?.scrollBy({
      left: vertical ? 0 : mediaSize,
      top: vertical ? 60 : 0,
      behavior: 'smooth',
    })
  }
  const scrollLeft = () => {
    scrollRef.current?.scrollBy({
      left: vertical ? 0 : -mediaSize,
      top: vertical ? -60 : 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    checkScroll()
  }, [scrollRef])

  return (
    <div
      className={cx(
        classes.mediaContainerContainer,
        vertical && classes.mediaContainerContainerVertical,
      )}
    >
      <div
        className={cx(
          classes.mediaContainer,
          vertical && classes.mediaContainerVertical,
        )}
        style={{
          flexDirection: vertical ? 'column' : 'row',
        }}
        ref={scrollRef}
        onScroll={checkScroll}
      >
        {media.map((m, index) => (
          <div key={m.id} className={classes.mediaItemContainer}>
            {onRemoveImageClicked && (
              <Close
                className={classes.removeButton}
                onClick={() => {
                  onRemoveImageClicked(m)
                  setActiveImageIdx(0)
                }}
              />
            )}
            <div
              className={classes.mediaItem}
              onClick={() => onMediaItemClick(index)}
              style={{
                width: vertical ? 60 : mediaSize,
                height: vertical ? 60 : mediaSize,
              }}
            >
              {m.type === 'VIDEO' && (
                <div className={classes.videoContainer}>
                  {m.content.thumbnail && (
                    <img
                      style={{
                        width: vertical ? 60 : mediaSize,
                        height: vertical ? 60 : mediaSize,
                      }}
                      src={m.content.thumbnail.url}
                      draggable={false}
                    />
                  )}
                  {!m.content.thumbnail && <video src={getVideoUrl(m)} muted />}
                  {onSetImageClicked && (
                    <div className={classes.videoTagSmall}>
                      <Typography variant='caption'>
                        {getDurationFormat(index)}
                      </Typography>
                    </div>
                  )}
                </div>
              )}
              {m.type === 'IMAGE' && (
                <img
                  style={{
                    width: vertical ? 60 : mediaSize,
                    height: vertical ? 60 : mediaSize,
                  }}
                  src={m.content.imageUrls.lg}
                  alt='image'
                />
              )}
            </div>
          </div>
        ))}
        {media?.length > 0 && onSetImageClicked && (
          <div className={classes.mediaItemContainer}>
            <div
              className={cx(classes.mediaItem, classes.uploadMoreButton)}
              onClick={() => onSetImageClicked()}
            >
              <div className={classes.uploadMoreContainer}>
                <CloudUpload className={classes.uploadMoreIcon} />
              </div>
            </div>
          </div>
        )}
      </div>
      {showScrollRight && (
        <Button
          className={cx(
            vertical ? classes.scrollButtonVertical : classes.scrollButton,
            vertical ? classes.scrollRightVertical : classes.scrollRight,
          )}
          onClick={scrollRight}
        >
          <ChevronRight />
        </Button>
      )}
      {showScrollLeft && (
        <Button
          className={cx(
            vertical ? classes.scrollButtonVertical : classes.scrollButton,
            vertical ? classes.scrollLeftVertical : classes.scrollLeft,
          )}
          onClick={scrollLeft}
        >
          <ChevronLeft />
        </Button>
      )}
    </div>
  )
}

export default MultimediaViewerScroller
