import { Tab, Tabs } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getCurrentEvent } from '../../../selectors/event'
import React, { useEffect, useState } from 'react'
import { formatShortNumber } from '../../../utils/eventFunctions'
import GuestManagementScroller from './GuestManagementScroller'
import GuestManagementEmpty from './GuestManagementEmpty'
import { usePrevious } from '../../../shared-components/utils/hooks'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  tab: {
    minWidth: 120,
    '&.Mui-selected': {
      color: theme.palette.text.primary,
    },
  },
  divider: {
    width: '100%',
    height: 1,
    background: theme.palette.grey[200],
  },
  indicator: {
    backgroundColor: theme.palette.text.primary,
  },
}))

const GuestManagementList = ({ sortType }) => {
  const { classes } = useStyles()
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const event = useSelector(getCurrentEvent)
  const refreshList = useSelector((state) => state.modals.refreshList)
  const isDatepoll = event.type === 'PINNING'
  const goingCount = event.hasJoinOptions
    ? event.statusCountsHost?.totalGoing
    : event.statusCountsHost?.going

  const hasJoinOptions = !isDatepoll && event.hasJoinOptions
  const fullyEmpty =
    event.statusCountsHost == null ||
    (isDatepoll
      ? event.statusCountsHost?.gaveAvailability === 0
      : (hasJoinOptions
          ? event.statusCountsHost?.joined
          : event.statusCountsHost?.going) +
          event.statusCountsHost?.maybe +
          event.statusCountsHost?.cant ===
        0)
  const [tab, setTab] = useState(
    fullyEmpty ? '' : hasJoinOptions ? 'JOINED' : 'GOING',
  )

  const prevStatusCounts = usePrevious(event.statusCountsHost)

  useEffect(() => {
    if (
      tab === '' &&
      (refreshList.name === 'JOINED' || refreshList.name === 'GOING')
    ) {
      setTab(hasJoinOptions ? 'JOINED' : 'GOING')
    }
  }, [refreshList.counter, refreshList.name])

  useEffect(() => {
    if (!prevStatusCounts && event.statusCountsHost && !fullyEmpty) {
      setTab(hasJoinOptions ? 'JOINED' : 'GOING')
    }
  }, [event.statusCountsHost, prevStatusCounts, fullyEmpty])

  return (
    <div className={classes.root}>
      {!isDatepoll && (
        <>
          <Tabs
            classes={{
              indicator: classes.indicator,
            }}
            variant='fullWidth'
            value={tab}
            onChange={(e, newTab) => setTab(newTab)}
            aria-label='attendees-tabs'
            textColor='inherit'
            indicatorColor='inherit'
          >
            <Tab
              key={hasJoinOptions ? 'JOINED' : 'GOING'}
              value={hasJoinOptions ? 'JOINED' : 'GOING'}
              className={classes.tab}
              disabled={fullyEmpty}
              label={`${t(
                'shared:rsvp.going',
              )} ${goingCount ? `(${formatShortNumber(goingCount)})` : ''}`}
            />
            <Tab
              key='MAYBE'
              value='MAYBE'
              className={classes.tab}
              disabled={fullyEmpty}
              label={`${t(
                'shared:rsvp.maybe',
              )} ${event.statusCountsHost?.maybe ? `(${formatShortNumber(event.statusCountsHost?.maybe)})` : ''}`}
            />
            <Tab
              key='CANT'
              value='CANT'
              className={classes.tab}
              disabled={fullyEmpty}
              label={`${t('shared:rsvp.cant')} ${
                event.statusCountsHost?.cant
                  ? `(${formatShortNumber(event.statusCountsHost?.cant)})`
                  : ''
              }`}
            />
          </Tabs>
          <div className={classes.divider} />
        </>
      )}

      {fullyEmpty && <GuestManagementEmpty status={'GOING'} />}

      <GuestManagementScroller
        event={event}
        status={event.hasJoinOptions ? 'JOINED' : 'GOING'}
        tab={tab}
        sortType={sortType}
      />

      <GuestManagementScroller
        event={event}
        status={'MAYBE'}
        tab={tab}
        sortType={sortType}
      />
      <GuestManagementScroller
        event={event}
        status={'CANT'}
        tab={tab}
        sortType={sortType}
      />
    </div>
  )
}

export default GuestManagementList
